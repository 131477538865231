import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { navigate, PageProps } from "gatsby";
import { globalHistory } from "@reach/router";
import { isIE } from "react-device-detect"

import { useHeaderQuery, useGlobalLanguage } from "@query";
import { colors, DEFAULT_REGION, SMALL_LAPTOP_BREAKPOINT } from "@util/constants";
import { Container } from "@util/standard";
import { IconButton, Image, Link, Button, MenuDrawer, RegionSelector } from "@global";
import Menu from "./menu";
import { MobileContainer } from "./shared.styles";
import GetStarted from "./getStarted";
import { getCountryCode, setRegion, setSlug } from "@util/helper";
import { useStore } from "@state/store";
import { useRegionChanged, useCheckScreenWidth } from "@util/hooks";
interface Props extends PageProps {
  path: string;
  pageRegion: string;
  template?: string;
  pageContext: {
    hideHero?: boolean;
    hideHeader?: boolean;
  };
}

const HeadWrapper = styled.div<{ hasScrolled: boolean }>`
  width: 100%;
  position: fixed;
  background-color: ${colors.transparent};
  z-index: 21;

  @keyframes fade-in {
    100% {
      background-color: ${colors.white};
    }
  }
  @keyframes fade-out {
    100% {
      background-color: ${colors.transparent};
    }
  }

  ${(props) =>
    props.hasScrolled
      ? `animation: fade-in 0.5s forwards;`
      : `animation: fade-out 0.5s forwards;`}
`;

const HeaderContainer = styled(Container) <{ hasScrolled: boolean }>`
  margin: ${(props) => (props.hasScrolled ? "0 30px" : "0 130px")};
  padding: 18px 0;
  justify-content: space-between;
  align-items: center;
  transition: all ease 0.5s;

  @media only screen and (max-width: ${SMALL_LAPTOP_BREAKPOINT}px) {
    margin: 0 30px;
  }
`;

const LinkContainer = styled(Container)`
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  justify-content: center;
`;

const Header = (props: Props) => {
  const { region, language } = useStore();
  const { path, pageRegion, template, pageResources, pageContext } = props;
  const {
    header,
    allSanityRegion: { nodes: regionNodes },
  } = useHeaderQuery(region.iban);
  const globalLanguage = useGlobalLanguage();
  const { hideHeader, hideHero } = pageContext;

  if (hideHeader === true) return null;

  if (header?.node == null) {
    return null;
  }

  const { logo, logoFull, logoIcon, links, menuBackground, language: languageOptions, buttonLabel } = header.node;
  const initialLogo = logo?.asset?.url;
  const fullColoredLogo = logoFull?.asset?.url;
  const otherLogo = logoIcon?.asset?.url;
  const [hasScrolled, setHasScrolled] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [getStartedVisible, setGetStartedVisible] = useState(false);
  const { isTabletWidth, isMobileWidth } = useCheckScreenWidth();
  const [isWhite, setIsWhite] = useState(true);

  useEffect(() => {
    
    const script = document.createElement('script');

    script.src = isIE? "https://js.hsforms.net/forms/v2-legacy.js":"https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    if (template === "privacyPolicy" || pageResources?.page?.path === "/404.html" || hideHero === true) {
      setIsWhite(false);
    } else {
      setIsWhite(true);
    }
  }, [template, pageResources?.page?.path]);

  useEffect(() => {
    globalHistory.listen(({ action }) => {
      if (action === "PUSH") {
        const hashValue = window.location.hash;
        if (hashValue === "#get-started") {
          setGetStartedVisible(true);
        }
      }
    });

    const handleScroll = () => {
      const scrollDistance =
        document.body.scrollTop + document.documentElement.scrollTop;

      if (scrollDistance > 10) {
        setHasScrolled(true);
      } else {
        setHasScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  //////////////////////////////
  //Region and Language
  //////////////////////////////
  const availableRegions = (languageOptions && languageOptions.filter(region => !region?.isHidden)) ?? regionNodes.filter(node => !node.isHidden);
  const cleanedPath = pageRegion !== DEFAULT_REGION ? path?.split(`/${pageRegion}`)[1] : path;
  if (language == null) setRegion(region.iban, region.hrefLang, globalLanguage);
  useRegionChanged(cleanedPath, globalLanguage);

  useEffect(() => {
    getCountryCode().then(code => {
      const regionFound = availableRegions.find(region => region?.iban == code);

      if (regionFound) {
        const { hrefLang, iban } = regionFound;
        setRegion(iban, hrefLang, globalLanguage);
        if (iban === region.iban) {
          return;
        }
        navigate(`${region.slug}`);
      }
    });
  }, []);
  //////////////////////////////
  //Region and Language END
  //////////////////////////////

  const GetStartedButton = ({ margin }: { margin: string }) => (
    <Button
      theme="green"
      text={buttonLabel ?? "Get Started"}
      margin={margin}
      linkTo="#get-started"
    />
  );

  const homeUrl = setSlug(`/`);

  return (
    <HeadWrapper hasScrolled={hasScrolled}>
      <HeaderContainer hasScrolled={hasScrolled}>
        {fullColoredLogo &&
          <Image
            staticImage={hasScrolled ? (otherLogo ?? fullColoredLogo) : isWhite ? (initialLogo ?? fullColoredLogo) : fullColoredLogo}
            width={(hasScrolled && otherLogo) ? "45px" : "155px"}
            height="auto"
            altText={hasScrolled ? logoIcon?.altText : logo?.altText}
            onClick={() => navigate(homeUrl)}
          />
        }

        {hasScrolled ? (
          <Container alignItems="center">
            <GetStartedButton margin="0 20px 0 0" />
            <IconButton
              icon="hamburger"
              onClick={() => setDrawerVisible(true)}
            />
          </Container>
        ) : (
          <Container alignItems="center">
            {!isTabletWidth && !isMobileWidth &&
              <>
                <LinkContainer>
                  {links?.map((link) => {
                    if (link == null) {
                      return null;
                    }
                    return (
                      <Link
                        {...link}
                        key={link._key}
                        color={isWhite ? "white" : "grey"}
                        padding="10px 0 10px 40px"
                      />
                    );
                  })}
                </LinkContainer>
                <Container margin="0 0 0 35px">
                  <RegionSelector isWhite={isWhite} languages={availableRegions} currentRegion={region} globalLanguage={globalLanguage} />
                </Container>
              </>
            }
            <GetStartedButton margin="0 0 0 40px" />
            <MobileContainer margin="0 0 0 20px">
              <IconButton
                icon={isWhite ? "hamburgerWhite" : "hamburger"}
                onClick={() => setDrawerVisible(true)}
              />
            </MobileContainer>
          </Container>
        )}
      </HeaderContainer>
      <MenuDrawer
        visible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        placement="right"
        backgroundColor="black"
      >
        <Menu
          links={links}
          logo={otherLogo}
          menuBackground={menuBackground?.asset?.gatsbyImageData}
          handleDrawerClose={() => setDrawerVisible(false)}
          availableRegions={availableRegions}
          region={region}
          globalLanguage={globalLanguage}
          buttonLabel={buttonLabel}
        />
      </MenuDrawer>
      <GetStarted
        visible={getStartedVisible}
        setVisible={setGetStartedVisible}
      />
    </HeadWrapper>
  );
};

export default Header;
