import React, { useRef } from "react";
import styled from "styled-components";
import ReactSlick from "react-slick";

import { Maybe, SanityImageContentCarouselBlock } from "@graphql-types";
import { Container } from "@util/standard";
import {
  Carousel,
  Image,
  SwipeIndicator,
  BlocksContent,
  LinksWithIcon,
} from "@global";
import { TABLET_BREAKPOINT } from "@util/constants";
import { handleNextSlide } from "@util/helper";
import { useStore } from "@state/store";
import { Color } from "@util/types";
import { ImagesContentBlockMainContainer, ImagesContentBlockContentContainer, ImagesContentBlockImageContainer } from "@shared/shared.styles";
import { useCheckScreenWidth } from "@util/hooks";

const Wrapper = styled(Container)`
  position: relative;
  .slick-dots{
    position: absolute;
    bottom: 35px;
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    .slick-dots{
      left: 10%;
    }
  }
`;

const SwipeIndicatorStyle = styled.div`
  > div{
    bottom: 70px;
    left: 50%;
    transform: translate(-50%, 0);
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    > div{
      left: auto;
      right: 10%;
      bottom: 35px;
      align-items: flex-end;
    }
  }
`;

const CarouselContainer = styled(Container)`
  width: 50%;
  margin: auto;
  position: relative;
  padding-left: 15%;

  .slick-dots{
    position: absolute;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 80%;
    padding-left: 0;
    margin-bottom: 35px;
  }
`;

interface Props {
  data: Maybe<SanityImageContentCarouselBlock> | undefined;
}

const ImagesContentBlock = ({ data }: Props) => {
  const { language } = useStore();
  const sliderRef = useRef<ReactSlick>(null);
  const { isTabletWidth, isMobileWidth } = useCheckScreenWidth();

  if (data == null) {
    return null;
  }
  const { imageContents, anchorId } = data;

  return (
    <Wrapper id={anchorId ?? undefined}>
      <Carousel hideArrow sliderRef={sliderRef} alignDotsMobile="left" adaptiveHeight>
        {imageContents && imageContents.map(content => {
          if (content == null) return null;

          const { _key, blockCtaContents, images, imagePositionLeft, background } = content;

          return (
            <React.Fragment key={_key}>
              <ImagesContentBlockMainContainer reverse={!imagePositionLeft} background={background?.backgroundColor as Color ?? 'white'}>
                <CarouselContainer>
                  {images?.asset?.gatsbyImageData &&
                    <ImagesContentBlockImageContainer>
                      <Image
                        objectFit="contain"
                        imageData={image.asset?.gatsbyImageData}
                        width={(!isTabletWidth && !isMobileWidth) ? "350px" : "auto"}
                        height={(!isTabletWidth && !isMobileWidth) ? "auto" : "400px"}
                      />
                    </ImagesContentBlockImageContainer>
                  }
                </CarouselContainer>

                <Container width="50%" tabletWidth="100%" margin="auto">
                  <ImagesContentBlockContentContainer>
                    {blockCtaContents &&
                      <>
                        <BlocksContent data={blockCtaContents.blockContent} />
                        {blockCtaContents.links && (
                          <Container margin="30px 0 0 0">
                            {blockCtaContents.links.map((link) => {
                              if (link == null) return null;
                              return <LinksWithIcon key={link?._key} data={link} />;
                            })}
                          </Container>
                        )}
                      </>
                    }
                  </ImagesContentBlockContentContainer>
                </Container>
              </ImagesContentBlockMainContainer>
            </React.Fragment>
          );
        })}
      </Carousel>
      {imageContents && imageContents.length > 1 &&
        <SwipeIndicatorStyle>
          <SwipeIndicator
            centreAlign={true}
            onClick={() => handleNextSlide(sliderRef)}
            text={language?.sliderSwipe ?? "swipe"}
          />
        </SwipeIndicatorStyle>
      }
    </Wrapper>
  );
};

export default ImagesContentBlock;
