import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { Container, P } from "@util/standard";
import { Button, Carousel } from "@global";
import Progress, { CalculatedValue, CalculatedValueKey } from "./progress";
import { colors, fontWeights, MOBILE_BREAKPOINT } from "@util/constants";
import { bigNumberFormatter } from "@util/helper";
import { useCalculatorLanguage } from "@query";
import { useStore } from "@state/store";

const ResultContainer = styled(Container)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Number = styled(P)`
  color: ${colors.green};
  font-size: 70px;
  font-weight: ${fontWeights.extrabold};
  margin: 0;
  text-align: center;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 52px;
  }
`;

const Text = styled(P)`
  font-size: 19px;
  font-weight: ${fontWeights.extrabold};
  margin: 0 0 25px 0;
  text-align: center;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 19px;
  }
`;

const initialState: CalculatedValue = {
  livesChanged: 0,
  programsSold: 0,
  hoursSaved: 0,
};

function calculateResults(result: number) {
  if (result > 0 && result <= 5000) {
    return {
      livesChanged: 302,
      programsSold: 91609,
      hoursSaved: 4526,
    };
  }

  if (result > 5000 && result <= 10000) {
    return {
      livesChanged: 604,
      programsSold: 183217,
      hoursSaved: 9052,
    };
  }

  if (result > 10000 && result <= 20000) {
    return {
      livesChanged: 1207,
      programsSold: 366434,
      hoursSaved: 18105,
    };
  }

  if (result > 20000 && result <= 50000) {
    return {
      livesChanged: 1353,
      programsSold: 388821,
      hoursSaved: 20295,
    };
  }

  if (result > 50000 && result <= 100000) {
    return {
      livesChanged: 2706,
      programsSold: 777642,
      hoursSaved: 40590,
    };
  }

  if (result > 100000 && result <= 250000) {
    return {
      livesChanged: 5412,
      programsSold: 1555284,
      hoursSaved: 81180,
    };
  }

  if (result > 250000 && result <= 500000) {
    return {
      livesChanged: 10824,
      programsSold: 3110569,
      hoursSaved: 162360,
    };
  }

  if (result > 500000 && result <= 1000000) {
    return {
      livesChanged: 19365,
      programsSold: 3408488,
      hoursSaved: 290475,
    };
  }

  if (result > 1000000 && result <= 2000000) {
    return {
      livesChanged: 38730,
      programsSold: 6816975,
      hoursSaved: 580950,
    };
  }

  if (result > 2000000 && result <= 5000000) {
    return {
      livesChanged: 77460,
      programsSold: 13633950,
      hoursSaved: 1161900,
    };
  }

  return {
    livesChanged: 154920,
    programsSold: 27267901,
    hoursSaved: 2323800,
  };
}

const InfoCarousel = ({
  infoArray,
  state,
}: {
  infoArray: [string, number][];
  state: CalculatedValue;
}) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const { region } = useStore();
  const calculatorLanguage = useCalculatorLanguage(region.iban);

  function infoText(valueKey: CalculatedValueKey) {
    switch (valueKey) {
      case "livesChanged":
        return calculatorLanguage?.resultLivesChanged ?? "Number of lives changed";

      case "hoursSaved":
        return calculatorLanguage?.resultHoursSaved ?? "Hours of time saved";

      case "programsSold":
        return calculatorLanguage?.resultProgramsSold ?? "Programs sold";
    }
  }

  return (
    <Progress
      values={state}
      currentValueName={infoArray[slideIndex][0] as CalculatedValueKey}
    >
      <Carousel
        hideArrow
        dotMargins="35px 0 0 0"
        selectedIndex={slideIndex}
        setSelectedIndex={setSlideIndex}
      >
        {infoArray?.map((info) => {
          const valueKey = info[0];
          const value = info[1];

          const formattedValue =
            valueKey === "programsSold"
              ? bigNumberFormatter(value, "", "$")
              : bigNumberFormatter(value);

          return (
            <Container
              key={valueKey}
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Text>{infoText(valueKey as CalculatedValueKey)}</Text>
              <Number>{formattedValue}</Number>
            </Container>
          );
        })}
      </Carousel>
    </Progress>
  );
};

interface Props {
  result: number | undefined;
  resultVisible: boolean;
  onBackClick: () => void;
}

const CalculatorResult = ({ result, resultVisible, onBackClick }: Props) => {
  const [state, setState] = useState<CalculatedValue>(initialState);
  const infoArray = Object.entries(state);
  const { region } = useStore();
  const calculatorLanguage = useCalculatorLanguage(region.iban);

  useEffect(() => {
    if (resultVisible && result) {
      const calculated = calculateResults(result);
      setState(calculated);
    }
  }, [resultVisible, result]);

  return (
    <ResultContainer>
      <InfoCarousel infoArray={infoArray} state={state} />
      <Button
        theme="green"
        text={calculatorLanguage?.resultButton ?? "Get Started"}
        margin="0 0 15px 0"
        linkTo="#get-started"
      />
      <Button theme="transparentGreen" text={calculatorLanguage?.resultButtonBack ?? "Back"} onClick={onBackClick} />
    </ResultContainer>
  );
};

export default CalculatorResult;
