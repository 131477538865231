import styled from "styled-components";
import { CSSProperties } from "react";

import {
  colors,
  colorsRGB,
  fontSizes,
  fontWeights,
  PRIMARY_COLOR,
} from "./constants";
import { FontWeights, Color, Dimensions } from "./types";
import { TABLET_BREAKPOINT, MOBILE_BREAKPOINT, LAPTOP_BREAKPOINT } from "@util/constants";

export const Overlay = styled.div<{ zIndex?: number }>`
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: ${(props) => props.zIndex ?? 1};
  left: 0;
  top: 0;
  background-color: ${colorsRGB.black(0.3)};
  overflow: hidden;
  transition: 0.5s;
`;

export const P = styled.p<{
  color?: Color;
  fontSize?: number;
  noMargin?: boolean;
  marginTop?: number;
  marginBottom?: number;
  width?: number;
  lineHeight?: number;
  underlined?: boolean;
  fontWeight?: FontWeights;
  opacity?: number;
  isMobileStyling?: boolean;
  display?: CSSProperties["display"];
  whiteSpace?: CSSProperties["whiteSpace"];
  cursor?: CSSProperties["cursor"];
  padding?: string;
  textAlign?: string;
  letterSpacing?: string;
  margin?: string;
  userSelect?: string;
}>`
  text-decoration-line: ${(props) => (props.underlined ? "underline" : "none")};
  background-color: transparent;
  font-weight: ${(props) =>
    props.fontWeight ? fontWeights[props.fontWeight] : fontWeights.normal};
  ${({ letterSpacing }) => letterSpacing && `letter-spacing: ${letterSpacing};`}
  ${({ cursor }) => cursor && `cursor: ${cursor};`}
  ${({ userSelect }) => userSelect && `user-select: ${userSelect};`}
  ${({ color }) => color && `color: ${colors[color]};`}
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}px;`}
  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight}px;`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ noMargin }) => noMargin && `margin: 0px;`}
  ${({ width }) => width && `width: ${width}px;`}
  ${({ padding }) => padding && `padding: ${padding};`}
  ${({ display }) => display && `display: ${display};`}
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
  ${({ whiteSpace }) => whiteSpace && `white-space: ${whiteSpace};`}
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: ${(props) => props.fontSize ?? fontSizes.p.mobile}px;
  }

  ${({ isMobileStyling }) =>
    isMobileStyling &&
    `@media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 80%;
    text-align: center;
    margin: auto;
  }`}
`;

export const Span = styled.span<{
  color?: Color;
}>`
  color: ${(props) => (props.color ? colors[props.color] : PRIMARY_COLOR)};
`;

export const A = styled.a<{
  color?: Color;
  underlined?: boolean;
  bold?: boolean;
  hoverColor?: Color;
  opacity?: number;
  margin?: string;
}>`
  color: ${(props) => (props.color ? colors[props.color] : PRIMARY_COLOR)};
  ${({ underlined }) =>
    underlined ? "text-decoration: underline;" : "text-decoration: none;"}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ bold }) => bold && "font-weight: bold;"}
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
  ${({ hoverColor }) =>
    hoverColor &&
    `&:hover {
    color: ${colors[hoverColor]};
  }`}
`;

export const H1 = styled.h1<{
  noMargin?: boolean;
  fontWeight?: string;
  fontSize?: number;
  color?: Color;
}>`
  color: ${(props) => (props.color ? colors[props.color] : PRIMARY_COLOR)};
  font-size: ${(props) => props.fontSize ?? fontSizes.h1.default}px;
  ${({ noMargin }) => noMargin && `margin: 0px;`}
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}px;`}
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight};`}
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: ${fontSizes.h1.mobile}px;
    line-height: ${fontSizes.h1.mobile * 1.2}px;
  }
`;
export const H2 = styled.h2<{
  color?: Color;
  margin?: string;
  fontWeight?: FontWeights;
  fontSize?: number;
  mobileFontSize?: number;
}>`
  font-size: ${(props) => props.fontSize ?? fontSizes.h2.default}px;
  white-space: initial;
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ color }) => color && `color: ${colors[color]};`}
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight};`}
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: ${fontSizes.h2.mobile}px;

    ${({ mobileFontSize }) =>
    mobileFontSize && `font-size: ${mobileFontSize}px;`};
    line-height: ${fontSizes.h2.mobile * 1.2}px;
  }
`;

export const H3 = styled.h3<{
  color?: Color;
  margin?: string;
  fontWeight?: FontWeights;
  fontSize?: number;
  width?: string;
  tabletWidth?: string;
}>`
  font-size: ${fontSizes.h3.default}px;
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}px`};
  ${({ width }) => width && `width: ${width};`};
  white-space: initial;
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ color }) => color && `color: ${colors[color]};`}
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight};`}

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    ${({ tabletWidth }) => tabletWidth && `width: ${tabletWidth};`};
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: ${fontSizes.h3.mobile}px;
    line-height: ${fontSizes.h3.mobile * 1.2}px;
  }
`;

export const VerticalSeparator = styled.div<{
  color?: Color;
  width?: string;
  height?: string;
  opacity?: number;
  shouldShowMobile?: boolean;
}>`
  border-left: 1.5px solid
    ${(props) => (props.color ? colors[props.color] : PRIMARY_COLOR)};
  height: ${(props) => (props.height ? props.height : `auto`)};
  width: ${(props) => (props.width ? props.width : `20px`)};
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
`;

export const Separator = styled.div<{
  color?: Color;
  height?: string;
  opacity?: number;
  thickness?: number;
  shouldHideDesktop?: boolean;
  marginBottom?: number;
  width?: string;
}>`
  ${({ marginBottom }) =>
    marginBottom ? `margin-bottom: ${marginBottom}px;` : `margin-bottom: 10px`};
  border-bottom: ${(props) => props.thickness ?? 1}px solid
    ${(props) => (props.color ? colors[props.color] : PRIMARY_COLOR)};
  ${({ width }) => width && `width: ${width ?? "100%"};`}
  height: ${(props) => props.height ?? `20px`};
  ${({ opacity }) => opacity && `opacity: ${opacity};`}

  ${({ width }) => width && `width: ${width};`}

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    ${({ shouldHideDesktop }) => shouldHideDesktop && `display:block`}
  }
  @media only screen and (min-width: ${TABLET_BREAKPOINT}px) {
    ${({ shouldHideDesktop }) => shouldHideDesktop && `display:none`}
  }
`;

export const Wrapper = styled.div`
  max-width: 1500px;
  width: 100%;
  padding: 85px 35px;
  margin: 0 auto;
  @media only screen and (max-width: ${LAPTOP_BREAKPOINT}px) {
    max-width: 1240px;
  }
`;

export const WrapperHeader = styled(Wrapper)`
  padding-top: 200px;
  overflow: hidden;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    padding-top: 140px;
  }
`;

export const Container = styled.div<{
  backgroundColor?: Color;
  backgroundRGBColor?: string;
  flexDirection?: CSSProperties["flexDirection"];
  flex?: number | string;
  flexGrow?: number;
  flexWrap?: CSSProperties["flexWrap"];
  justifyContent?: CSSProperties["justifyContent"];
  alignItems?: CSSProperties["alignItems"];
  alignSelf?: CSSProperties["alignSelf"];
  mobileAlignSelf?: CSSProperties["alignSelf"];
  borderRadius?: number;
  overflow?: CSSProperties["overflow"];
  paddingVertical?: number;
  paddingHorizontal?: number;
  img?: string;
  isMobileColumn?: boolean;
  mobilePadding?: string;
  tabletWidth?: string;
  mobileWidth?: string;
  mobileJustifyContent?: CSSProperties["justifyContent"];
  mobileDimensions?: Dimensions;
  tabletDimensions?: Dimensions;
  width?: string;
  height?: string;
  opacity?: number;
  margin?: string;
  padding?: string;
  cursor?: CSSProperties["cursor"];
  hideOnMobile?: boolean;
  hideOnTablet?: boolean;
  showOnTablet?: boolean;
  zIndex?: number;
  display?: string;
  maxWidth?: string;
  position?: CSSProperties["position"];
}>`
  display: ${(props) => props.display ?? "flex"};
  flex-direction: ${(props) => props.flexDirection ?? "initial"};
  justify-content: ${(props) => props.justifyContent ?? "initial"};
  align-items: ${(props) => props.alignItems ?? "initial"};
  align-self: ${(props) => props.alignSelf ?? "initial"};
  overflow: ${(props) => props.overflow ?? "initial"};
  border-radius: ${(props) => props.borderRadius ?? 0}px;
  background-size: cover;
  ${({ zIndex }) => zIndex && `z-index: ${zIndex};`}
  ${({ flex }) => flex && `flex: ${flex};`}
  ${({ position }) => position && `position: ${position};`}
  ${({ flexGrow }) => flexGrow && `flex-grow: ${flexGrow};`}
  ${({ flexWrap }) => flexWrap && `flex-wrap: ${flexWrap};`}
  ${({ cursor }) => cursor && `cursor: ${cursor};`}
  ${({ paddingVertical }) =>
    paddingVertical &&
    `padding: 0px ${paddingVertical}px 0px ${paddingVertical}px;`}
    ${({ paddingHorizontal }) =>
    paddingHorizontal &&
    `padding: ${paddingHorizontal}px 0px ${paddingHorizontal}px 0px;`}
  ${({ img }) => img && `background-image: url(${img});`}
  ${({ backgroundColor }) =>
    backgroundColor && `background-color: ${colors[backgroundColor]};`}
  ${({ backgroundRGBColor }) =>
    backgroundRGBColor && `background-color: ${backgroundRGBColor};`}
  ${({ width }) => width && `width: ${width};`}
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
  ${({ height }) => height && `height: ${height};`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ padding }) => padding && `padding: ${padding};`}
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
  ${({ showOnTablet }) => showOnTablet && `display: none;`}

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    ${({ isMobileColumn }) =>
    isMobileColumn &&
    `
      flex-direction: column;
      height: auto;
      align-items: center;
      width: 100%;
    `}

    ${({ mobilePadding }) => mobilePadding && `padding: ${mobilePadding}`}

    ${({ tabletDimensions }) =>
    tabletDimensions &&
    tabletDimensions.height &&
    `height: ${tabletDimensions.height};`}
  ${({ tabletDimensions }) =>
    tabletDimensions &&
    tabletDimensions.width &&
    `width: ${tabletDimensions.width};`}

      ${({ hideOnTablet }) => hideOnTablet && `display: none;`}
      ${({ showOnTablet }) => showOnTablet && `display: flex !important;`}
      ${({ tabletWidth }) => tabletWidth && `width: ${tabletWidth};`};
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    ${({ mobileDimensions }) =>
    mobileDimensions &&
    mobileDimensions.height &&
    `height: ${mobileDimensions.height};`}
    ${({ mobileDimensions }) =>
    mobileDimensions &&
    mobileDimensions.width &&
    `width: ${mobileDimensions.width};`}

    ${({ hideOnMobile }) => hideOnMobile && `display: none;`}
    ${({ mobileAlignSelf }) =>
    mobileAlignSelf && `align-self: ${mobileAlignSelf}`}
      ${({ mobileWidth }) => mobileWidth && `width: ${mobileWidth};`};
  }
`;

export const GridContainer = styled.div<{
  gridTemplateColumns?: string;
  width?: string;
  margin?: string;
  repeat?: number;
  mobileRepeat?: number;
  tabletRepeat?: number;
  columnGap?: number;
  rowGap?: number;
  mobileColumnGap?: number;
  mobileRowGap?: number;
  rtl?: boolean;
}>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.repeat ?? 4}, 1fr);
  ${({ columnGap }) => columnGap && `grid-column-gap: ${columnGap}px;`}
  ${({ rowGap }) => rowGap && `grid-row-gap: ${rowGap}px;`}
  ${({ width }) => width && `width: ${width};`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ rtl }) => rtl && `direction: rtl;`}

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    grid-template-columns: repeat(${(props) => props.tabletRepeat ?? 2}, 1fr);
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: repeat(${(props) => props.mobileRepeat ?? 1}, 1fr);
    ${({ mobileColumnGap }) =>
    mobileColumnGap && `grid-column-gap: ${mobileColumnGap}px;`}
    ${({ mobileRowGap }) => mobileRowGap && `grid-row-gap: ${mobileRowGap}px;`}
  }
`;

export const TextButton = styled(P) <{
  color?: Color;
  hoverColor?: Color;
  underlined?: boolean;
  lineHeight?: number;
  margin?: string;
  bold?: boolean;
}>`
  line-height: ${(props) => props.lineHeight ?? 18}px;
  text-decoration-line: ${(props) => (props.underlined ? "underline" : "none")};
  text-underline-offset: 3px;
  color: ${(props) => (props.color ? colors[props.color] : PRIMARY_COLOR)};
  cursor: pointer;
  margin: ${(props) => props.margin ?? "0px"};
  user-select: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: ${(props) =>
    props.bold ? fontWeights.extrabold : fontWeights.normal};
  transition: color 0.3s ease;
  display: flex;
  align-items: center;
  &:focus {
    outline: 0;
  }
  &:hover {
    color: ${(props) =>
    props.hoverColor ? colors[props.hoverColor] : colors.green};
  }
`;

export const GiveMeSomeSpace = styled.div<{ space?: number }>`
  padding: ${(props) => props.space ?? 10}px;
`;

export const LinearGradient = styled.div<{ zIndex?: number }>`
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: ${(props) => props.zIndex ?? 1};
  left: 0;
  top: 0;
  background: linear-gradient(
    1.11deg,
    rgba(0, 0, 0, 0.7) 0.95%,
    rgba(0, 0, 0, 0) 111.82%
  );
  overflow: hidden;
  transition: 0.5s;
`;

export const FlexGrid = styled(Container) <{
  gridMargin?: string;
  laptopGridMargin?: string;
  tabletGridMargin?: string;
  mobileGridMargin?: string;
  isCollectionPreview?: boolean;
}>`
  box-sizing: border-box;
  flex-flow: row wrap;
  ${(props) =>
    props.gridMargin
      ? `
    width: calc(100% + ${props.gridMargin});
    margin-right: -${props.gridMargin};
  `
      : `
    width: calc(100% + 35px);
    margin-right: -35px;
  `}
  > div {
    box-sizing: border-box;
    ${(props) =>
    props.gridMargin
      ? `
      padding-right: ${props.gridMargin};
    `
      : `
      padding-right: 35px;
    `}
  }
  ${(props) =>
    props.laptopGridMargin &&
    `
    @media only screen and (max-width: ${LAPTOP_BREAKPOINT}px) {
      width: calc(100% + ${props.laptopGridMargin});
      margin-right: -${props.laptopGridMargin};
      > div{
        padding-right: ${props.laptopGridMargin};
      }
    }
  `}

  ${(props) =>
    props.tabletGridMargin &&
    `
    @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
      width: calc(100% + ${props.tabletGridMargin});
      margin-right: -${props.tabletGridMargin};
      > div{
        padding-right: ${props.tabletGridMargin};
      }
    }
  `}

  ${(props) =>
    props.mobileGridMargin &&
    `
    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      width: calc(100% + ${props.mobileGridMargin});
      margin-right: -${props.mobileGridMargin};
      > div{
        padding-right: ${props.mobileGridMargin};
      }
    }
  `}
`;
