import React, { useState, useEffect, ReactNode, RefObject, Dispatch, SetStateAction } from "react";
import styled from "styled-components";

import { SanityVideoSliderContent, SanityImageTitleDesc, SanityImageWithMetaOrVideoOrVideoWithPlaceholder, Maybe, SanityVideoInner, SanityVideoWithPlaceholder } from "@graphql-types";
import { VideoPopUp, IconButton, BlogCategories } from "@global";
import { TABLET_BREAKPOINT, colorsRGB, colors } from "@util/constants";
import { truncateWords, useDebounce } from "@util/helper";
import { isSanityImageTitleDesc, isSanityVideo, isSanityVideoInner, isSanityVideoSliderContent, isSanityVideoWithPlaceholder } from "@util/types";
import MediaContent from "@shared/mediaContent";
import MediaContentVideo from "@shared/mediaContentVideo";
import ReactSlick from "react-slick";
import { PictureInPictureIcon } from "@util/svg";
import { Container } from "@util/standard";
import { useStore } from "@state/store";
import { InnerPreviewContainer, IndexContainer } from "@shared/shared.styles";

interface Props {
  data: SanityVideoSliderContent | SanityImageTitleDesc | SanityVideoInner;
  width?: string;
  tabletWidth?: string;
  videoVariedHeight?: boolean;
  videoTitleLarge?: boolean;
  isTopViewed?: boolean;
  postIndex?: number;
}

interface VideoMediaContentProps {
  videoSize?: {
    height?: string;
    width?: string;
    minWidth?: string;
    minHeight?: string;
  };
  children?: ReactNode;
  media: Maybe<Maybe<SanityImageWithMetaOrVideoOrVideoWithPlaceholder>[]> | Maybe<Maybe<SanityVideoWithPlaceholder>[]> | undefined;
  playTime?: number;
  setPlayTime?: Dispatch<SetStateAction<number>>;
  autoplay?: Maybe<boolean> | undefined;
  pictureInPicture?: Maybe<boolean> | undefined;
  onCarouselDisplay?: boolean;
  sliderRef?: RefObject<ReactSlick>;
  playNext?: boolean;
  setPlayNext?: Dispatch<SetStateAction<boolean>>;
  isPopup?: boolean;
  isPlayCircle?: boolean;
  hasGradient?: boolean;
}

const VideoPreviewContainer = styled.div`
  width: 280px;
  padding-right: 20px;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 240px;
    padding-right: 9px;
  }
`;

const MediaContainer = styled.div<{
  isImageCarousel?: boolean;
}>`
  position: relative;
  height: ${({ isImageCarousel }) => isImageCarousel ? '293px' : '560px'};
  .gatsby-image-wrapper{
    cursor: pointer;
    img{
      z-index: 1;
      transition: transform 0.6s ease;
    }
  }
  &:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: ${colorsRGB.white(0)};
    transition: background 0.6s ease;
  }
  &:hover{
    img{
      transform: scale(1.05);
    }
    &:after{
      background: ${colorsRGB.white(0.2)};
    }
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    height: ${({ isImageCarousel }) => isImageCarousel ? '253px' : '483px'};
  }
`;

const MediaContainerVideoPosts = styled(MediaContainer) <{
  videoVariedHeight: boolean;
  videoTitleLarge: boolean;
}>`
  height: 0;
  padding-bottom: 190%;
  ${({ videoVariedHeight, videoTitleLarge }) => `
    ${videoVariedHeight === true && `height: 100%;`}
    ${videoTitleLarge === true && `padding-bottom: 0;`}
  `}
`;

const IconContainer = styled.div`
  cursor: pointer;
  position: absolute;
  z-index: 3;
  padding: 9px 17px 7px;
  right: 10px;
  bottom: 10px;
  background: ${colorsRGB.black(0.5)};
  transition: 0.3s background ease;
  border-radius: 3px;
  svg{
    width: 28px;
  }
  &:hover{
    background: ${colorsRGB.black(0.8)};
  }
`;

const VideoPlayIconContainer = styled(Container) <{
  hasGradient?: boolean;
}>`
  position: absolute;
  cursor: pointer;
  z-index: 4;

  ${({ hasGradient }) => hasGradient ? `
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  ` : `
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  `};
`;

const OverlayContainer = styled(Container)`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  pointer-events: none;
  a, button{
    pointer-events: initial;
  }
`;

const VideoMetaContent = styled(Container)`
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  padding: 25px 30px;
  color: ${colors.white};
  &:before{
    content: '';
    display: block;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.3) 70%, rgba(0, 0, 0, 0.7) 100%);
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  > *{
    position: relative;
    z-index: 2;
  }
  h4, h5, p{
    max-width: 400px;
  }
  p{
    line-height: 1.4;
  }
`;

export const VideoMediaContent = ({ videoSize, media, children, autoplay, pictureInPicture, onCarouselDisplay, sliderRef, playNext, setPlayNext, isPopup = false, isPlayCircle = false, hasGradient }: VideoMediaContentProps) => {
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [playInframe, setPlayInframe] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [playTime, setPlayTime] = useState(0);

  if (onCarouselDisplay != null) {
    const debouncedOnCarouselDisplay = useDebounce(onCarouselDisplay, 1000);

    useEffect(() => {
      if (debouncedOnCarouselDisplay === false) {
        setVideoPlaying(false);
        setPlayInframe(false);
        setModalVisible(false);
        return;
      }
      if (debouncedOnCarouselDisplay === true && playNext === true) {
        setVideoPlaying(true);
        setPlayInframe(true);
        if (setPlayNext) {
          setPlayNext(false);
        }
        return;
      }
    }, [debouncedOnCarouselDisplay]);
  }

  useEffect(() => {
    if (!isPopup) {
      if (videoPlaying === false) {
        setPlayInframe(false)
      } else {
        setPlayInframe(!modalVisible);
      }
    }
  }, [modalVisible, videoPlaying]);

  if (media == null || media.length < 1 || !media[0]) return null;
  const isVideo = (isSanityVideo(media[0]) || isSanityVideoWithPlaceholder(media[0]));

  const handlePlayClick = () => {
    if (isPopup) {
      setModalVisible(true);
    } else {
      setVideoPlaying(true);
      setPlayInframe(true);
    }
  };

  return (
    <>
      {/* When Video or Video with Placeholder */}
      {isVideo ?
        <MediaContentVideo
          data={media[0]}
          videoPlaying={playInframe}
          playTime={playTime}
          setPlayTime={setPlayTime}
          setVideoPlaying={setVideoPlaying}
          sliderRef={autoplay === true ? sliderRef : undefined}
          setPlayNext={setPlayNext && setPlayNext}
          videoSize={videoSize}
        />
        : media[0] &&
        <MediaContent mediaBlock data={media[0]} />
      }
      {playInframe === false && children &&
        <OverlayContainer>
          {children}
        </OverlayContainer>
      }
      {(isPopup ? isPopup : playInframe === false) && isVideo &&
        <VideoPlayIconContainer
          onClick={handlePlayClick}
          hasGradient={hasGradient ?? false}
        >
          <IconButton
            icon={isPlayCircle ? "playCircle" : "playSolid"}
            width={isPlayCircle ? "98px" : "68px"}
            height={isPlayCircle ? "98px" : "60px"}
          />
        </VideoPlayIconContainer>
      }
      {pictureInPicture && playInframe === true &&
        <IconContainer onClick={() => { setPlayInframe(false); setModalVisible(true) }} >
          <PictureInPictureIcon
            color="white"
          />
        </IconContainer>
      }
      {(pictureInPicture || isPopup) && isVideo &&
        <VideoPopUp
          videoUrl={media[0].url as string}
          visible={modalVisible}
          setVisible={setModalVisible}
          playTime={playTime}
          setPlayTime={setPlayTime}
          isPictureInPicture={!isPopup}
          setVideoPlaying={!isPopup ? setVideoPlaying : undefined}
          sliderRef={autoplay === true ? sliderRef : undefined}
          setPlayNext={setPlayNext && setPlayNext}
        />
      }
    </>
  );
};

const VideoPreview = ({ data, width, tabletWidth, videoVariedHeight, videoTitleLarge, isTopViewed, postIndex }: Props) => {
  const { language } = useStore();

  if (data == null) return null;

  if (isSanityVideoSliderContent(data)) {
    const { author, description, media, title } = data;

    return (
      <VideoPreviewContainer>
        <MediaContainer>
          <VideoMediaContent media={media} isPopup>
            <VideoMetaContent className="rte">
              {title && <h5>{title}</h5>}
              {description && <p>{truncateWords(description ?? '', 40)}</p>}
              {author && <p>{language?.blogAuthor ?? "By"} {author}</p>}
            </VideoMetaContent>
          </VideoMediaContent>
        </MediaContainer>
      </VideoPreviewContainer>
    );
  }

  if (isSanityImageTitleDesc(data)) {
    const { description, media, title } = data;

    const videoSize = {
      width: "600px",
      height: "300px",
      minHeight: "1px",
      minWidth: "1px"
    };

    return (
      <VideoPreviewContainer>
        <MediaContainer isImageCarousel>
          <VideoMediaContent media={media} isPopup videoSize={videoSize}>
          </VideoMediaContent>
        </MediaContainer>
        <Container flexDirection="column" margin="20px 0 0" className="rte">
          {title && <h5>{title}</h5>}
          {description && <p>{description}</p>}
        </Container>
      </VideoPreviewContainer>
    );
  }

  if (isSanityVideoInner(data)) {
    const { author, excerpt, categories, video, title } = data;

    if (video == null) return null;

    const videoSize = {
      width: "1400px",
      height: "700px",
      minHeight: "1px",
      minWidth: "1px"
    };

    return (
      <InnerPreviewContainer width={width} tabletWidth={tabletWidth}>
        {isTopViewed === true && postIndex != null &&
          <IndexContainer>{postIndex + 1}</IndexContainer>
        }
        <MediaContainerVideoPosts videoVariedHeight={videoVariedHeight ?? false} videoTitleLarge={videoTitleLarge ?? false}>
          <VideoMediaContent
            media={[video]}
            isPopup
            videoSize={videoSize}>
            {categories &&
              <BlogCategories categories={categories} />
            }
            <VideoMetaContent className="rte">
              {title && videoTitleLarge ?
                <h4>{title}</h4>
                :
                <h5>{title}</h5>
              }
              {excerpt && <p>{excerpt}</p>}
              {author && <p>{language?.blogAuthor ?? "By"} {author}</p>}
            </VideoMetaContent>
          </VideoMediaContent>
        </MediaContainerVideoPosts>
      </InnerPreviewContainer>
    );
  }

  return null;

};

export default VideoPreview;
