import { useStaticQuery, graphql } from "gatsby";

import { Query } from "@graphql-types";

const AllPagePathsQuery = () => {
  const {
    allSitePage: { nodes },
  }: Query = useStaticQuery(graphql`
    {
      allSitePage {
        nodes {
          path
        }
      }
    }
  `);

  const paths = nodes.map(node => node.path);

  return paths;
};

export default AllPagePathsQuery;
