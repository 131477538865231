import React from "react";
import styled from "styled-components";

import { useFooterQuery } from "@query";
import { colors, colorsRGB, MOBILE_BREAKPOINT, SMALL_LAPTOP_BREAKPOINT, TABLET_BREAKPOINT } from "@util/constants";
import { Container, P, FlexGrid } from "@util/standard";
import { Image, BlocksContent, Link, LinksWithIcon } from "@global";
import { ButtonContainer } from "./shared.styles";
import { useStore } from "@state/store";
import { useCheckScreenWidth } from "@util/hooks";
import { isSanityPlainLink } from "@util/types";
import { Maybe, SanityPlainLinkOrTextObject } from "@graphql-types";

interface Props {
  template?: string;
}
interface MenuListProps {
  data: Maybe<Maybe<SanityPlainLinkOrTextObject>[]> | undefined;
}

const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  overflow: hidden;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-flow: column nowrap;
  }
`;

const ContentWrapper = styled.div<{
  hidePodcast: boolean;
}>`
  width: ${({ hidePodcast }) => hidePodcast === true ? `100%` : `55%`};
  padding: 60px 50px 60px 130px;

  @media only screen and (max-width: ${SMALL_LAPTOP_BREAKPOINT}px) {
    width: ${({ hidePodcast }) => hidePodcast === true ? `100%` : `60%`};
    padding: 60px 30px 60px 30px;
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    padding: 60px 30px 90px 30px;
  }
`;

const PodcastWrapper = styled.div`
  width: 45%;
  background: ${colors.light};
  display: flex;
  align-items: center;
  @media only screen and (max-width: ${SMALL_LAPTOP_BREAKPOINT}px) {
    width: 40%;
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    min-height: 609px;
    align-items: flex-end;
    position: relative;
  }
`;

const PodcastImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  &:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: linear-gradient(180deg, ${colorsRGB.black(0)} 0%, ${colorsRGB.black(0.56)} 99.2%);
  }
`;

const PodcastContent = styled.div<{ mobileWhite?: boolean; }>`
  padding: 60px 120px;

  @media only screen and (max-width: ${SMALL_LAPTOP_BREAKPOINT}px) {
    padding: 60px 60px;
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    position: relative;
    z-index: 2;
    padding: 30px 35px;
    ${({ mobileWhite }) => mobileWhite && `color: ${colors.white};`}
  }
`;

const MenuColumn = styled.div`
  max-width: 195px;
  width: 50%;

  display: flex;
  flex-flow: column nowrap;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: auto;
  }
`;

const MenuText = styled.span`
  color: ${colorsRGB.black(0.4)};
  display: inline-block;
  padding: 6px 0;
`;

const PolicyContainer = styled(Container)`
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-flow: column-reverse nowrap;
    align-items: flex-start;
    p{
      font-size: 12px;
    }
  }
`;

const PolicyRowContainer = styled(Container)`
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

const LinkContainer = styled(Container)`
  margin-left: 24px; 
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin: 0 0 30px;
  }
`;

const TextLinkContainer = styled(LinkContainer)`
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin: 20px 0 0;
  }
`;

const SocialLink = styled.a`
  background: ${colors.light};
  width: 36px;
  height: 36px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  &:last-of-type{
    margin-right: 0;
  }
`;

const MenuList = ({ data }: MenuListProps) => {
  if (data == null) return null;

  return (
    <MenuColumn>
      {data.map(link => {
        if (link == null) return null;
        if (isSanityPlainLink(link)) {
          return (
            <Link
              {...link}
              key={link._key}
              color="grey"
              padding="6px 0"
            />
          );
        }
        return (
          <MenuText key={link._key}>{link.text}</MenuText>
        );
      })}
    </MenuColumn>
  );
};

const Footer = ({ template }: Props) => {
  const { region } = useStore();
  const sanityFooter = useFooterQuery(region.iban)?.node;
  const { isTabletWidth, isMobileWidth } = useCheckScreenWidth();

  if (sanityFooter == null) {
    return null;
  }

  const { logo, footerLinks1, footerLinks2, companyAddresses, copyright, policyLinks, socialLinks, backgroundImage, podcastTitle, mobileWhite, podcastLinks } = sanityFooter;

  const hidePodcast = template === 'product';

  return (
    <FooterWrapper>
      {!hidePodcast && (isTabletWidth || isMobileWidth) &&
        <PodcastWrapper>
          {backgroundImage &&
            <PodcastImage>
              <Image
                isBackground
                imageData={backgroundImage.asset?.gatsbyImageData}
                altText={backgroundImage.asset?.altText ?? "mediaImage"}
                objectFit="cover"
              />
            </PodcastImage>
          }
          <PodcastContent mobileWhite={mobileWhite ?? true}>
            <BlocksContent data={podcastTitle} />
            {podcastLinks &&
              <ButtonContainer>
                {podcastLinks.map(link => {
                  if (link == null) return null;
                  return <LinksWithIcon key={link?._key} data={link} />;
                })}
              </ButtonContainer>
            }
          </PodcastContent>
        </PodcastWrapper>
      }
      <ContentWrapper hidePodcast={hidePodcast}>
        {!isTabletWidth && !isMobileWidth && logo?.asset?.url &&
          <Image
            staticImage={logo?.asset?.url}
            altText={logo?.altText ?? "macroActive"}
            margin="0 0 35px"
            width="156px"
            height="auto"
          />
        }
        <FlexGrid>
          <MenuList data={footerLinks1} />
          <MenuList data={footerLinks2} />
          <MenuList data={companyAddresses} />
        </FlexGrid>
        <PolicyContainer>
          <PolicyRowContainer>
            <P noMargin color="grey" opacity={0.6}>
              {copyright}
            </P>
            {policyLinks &&
              <TextLinkContainer>
                {policyLinks.map((link) => {
                  if (link == null) return null;

                  return (
                    <Link
                      {...link}
                      key={link._key}
                      color="grey"
                      padding="0 20px 0 0"
                    />
                  );
                })}
              </TextLinkContainer>
            }
          </PolicyRowContainer>
          {socialLinks &&
            <div>
              <LinkContainer>
                {socialLinks.map((socialLink) => {
                  if (socialLink == null) return null;

                  return (
                    <SocialLink
                      key={socialLink.socialIcon?.asset?._id}
                      href={socialLink.linkTo as string}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {socialLink.socialIcon?.asset?.url &&
                        <Image
                          staticImage={socialLink.socialIcon.asset.url}
                          altText={socialLink.socialIcon?.altText}
                          width="18px"
                          height="18px"
                          objectFit="contain"
                        />
                      }
                    </SocialLink>
                  );
                })}
              </LinkContainer>
            </div>
          }
          {(isTabletWidth || isMobileWidth) && logo?.asset?.url &&
            <Image
              staticImage={logo?.asset?.url}
              altText={logo?.altText ?? "macroActive"}
              margin="70px 0 30px"
              width="140px"
              height="auto"
            />
          }
        </PolicyContainer>
      </ContentWrapper>
      {!hidePodcast && !isTabletWidth && !isMobileWidth &&
        <PodcastWrapper>
          <PodcastContent>
            <BlocksContent data={podcastTitle} />
            {podcastLinks &&
              <ButtonContainer>
                {podcastLinks.map(link => {
                  if (link == null) return null;
                  return <LinksWithIcon key={link?._key} data={link} />;
                })}
              </ButtonContainer>
            }
          </PodcastContent>
        </PodcastWrapper>
      }

    </FooterWrapper>
  );
};

export default Footer;
