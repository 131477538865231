import React, { RefObject, useEffect, useRef, useState, Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import Vimeo from "@u-wave/react-vimeo";
import { Player } from "@vimeo/player";

import { colors } from "@util/constants";
import { handleNextSlide } from "@util/helper";
import ReactSlick from "react-slick";
import { useCheckScreenWidth } from "@util/hooks";

const VideoWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background-color: ${colors.lightGrey};
`;

const StyledVimeo = styled(Vimeo) <{
  foregroundOpen?: boolean;
  minHeight?: string;
  minWidth?: string;
}>`
  iframe {
    width: ${(props) => props.width ?? "100vw"};
    height: ${(props) => props.height ?? "56.25vw"};
    min-height: ${(props) => props.minHeight ?? "100vh"};
    min-width: ${(props) => props.minWidth ?? `250vh;`};
    border: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    ${({ foregroundOpen }) => foregroundOpen && `min-width: 0px`}
  }
`;

interface Props {
  url: string;
  volume?: boolean;
  height?: string;
  width?: string;
  minWidth?: string;
  minHeight?: string;
  foregroundOpen?: boolean;
  autoPlayOnLoad?: boolean;
  isVisible?: boolean;
  background?: boolean;
  controls?: boolean;
  noAutoplay?: boolean;
  playTime?: number;
  setPlayTime?: Dispatch<SetStateAction<number>>;
  setVideoPlaying?: Dispatch<SetStateAction<boolean>>;
  sliderRef?: RefObject<ReactSlick> | undefined;
  setPlayNext?: Dispatch<SetStateAction<boolean>>;
}

function Video(props: Props) {
  const {
    url,
    volume,
    height,
    width,
    minHeight,
    minWidth,
    foregroundOpen,
    autoPlayOnLoad,
    isVisible,
    background,
    controls,
    noAutoplay = false,
    playTime,
    setPlayTime,
    setVideoPlaying,
    sliderRef,
    setPlayNext,
  } = props;

  const [autoPlay, setAutoplay] = useState(autoPlayOnLoad);
  const playerRef = useRef<Player>();
  const { isMobileWidth } = useCheckScreenWidth();

  function onLoad() {
    if (noAutoplay === true) {
      if (playerRef.current) {
        playerRef.current.setCurrentTime(3);
        playerRef.current.pause();
      }
      setAutoplay(false);
      return;
    }

    if (autoPlay && isVisible) {
      if (playTime) {
        playerRef.current && playerRef.current.setCurrentTime(playTime).then(function () {
          playerRef.current && playerRef.current.play();
        });
      } else {
        playerRef.current && playerRef.current.play();
      }
    }
  }

  function onReady(player: Player) {
    playerRef.current = player;
  }

  const onPlay = () => {
    if (setVideoPlaying != null) {
      setVideoPlaying(true);
    }
    if (setPlayTime != null) {
      playerRef.current && playerRef.current.on('timeupdate', (data) => {
        setPlayTime(data.seconds);
      });
    }
  }

  const onEnd = () => {
    setPlayTime && setPlayTime(0);
    if (setVideoPlaying != null) {
      setVideoPlaying(false);
    }
    if (sliderRef != null && setPlayNext != null) {
      handleNextSlide(sliderRef);
      setPlayNext(true);
    }
  }

  const onPause = () => {
    if (setVideoPlaying != null && !isMobileWidth) {
      setVideoPlaying(false);
    }
  }

  useEffect(() => {
    if (noAutoplay === true) {
      if (playerRef.current) {
        playerRef.current.setCurrentTime(3);
        playerRef.current.pause();
      }
      setAutoplay(false);
      return;
    };

    if (isVisible) {
      if (playerRef.current) {
        if (playTime) {
          playerRef.current.setCurrentTime(playTime).then(function () {
            playerRef.current && playerRef.current.play();
          });
        } else {
          playerRef.current.play();
        }
      } else {
        setAutoplay(true);
      }
      return;
    }

    if (!isVisible && playerRef.current) {
      if (setPlayTime != null) {
        playerRef.current && playerRef.current.pause();

      } else {
        playerRef.current.getPlayed().then((played) => {
          if (played.length == 0) {
            return;
          }

          if (played.length > 0) {
            setTimeout(() => {
              playerRef.current && playerRef.current.pause();
            }, 1000);
          }
        });
      }
    }
  }, [isVisible]);

  useEffect(() => {
    if (playerRef.current && foregroundOpen) {
      playerRef.current.setCurrentTime(0);
    }
  }, [foregroundOpen]);

  if (!isVisible && !playerRef.current && background) return (
    <VideoWrapper>
    </VideoWrapper>
  );

  return (
    <VideoWrapper>
      <StyledVimeo
        loop={sliderRef == null ? true : false}
        video={url}
        autoplay={false}
        autopause={true}
        background={background}
        muted={!volume}
        height={height}
        width={width}
        minHeight={minHeight}
        minWidth={minWidth}
        volume={volume ? 1 : 0}
        controls={controls ? true : false}
        onLoaded={onLoad}
        onReady={onReady}
        onPause={onPause}
        onPlay={onPlay}
        onEnd={onEnd}
        foregroundOpen={foregroundOpen}
        showByline={false}
      />
    </VideoWrapper>
  );
}

export default Video;
