import { Dispatch, SetStateAction } from "react";
import {
  assets,
  buttonStyle,
  colors,
  colorsRGB,
  fontWeights,
} from "./constants";

import {
  SanityImage,
  SanityImageWithMeta,
  SanityImageWithMetaOrVideo,
  SanityImageWithMetaOrVideoOrVideoWithPlaceholder,
  SanityVideo,
  SanityVideoWithPlaceholder,
  SanityAccordionBlock,
  SanityCalculatorBlock,
  SanityFeaturedBlogsBlock,
  SanityFullScreenContentBlock,
  SanityImageContentCarouselBlock,
  SanityImagesContentBlock,
  SanityImageSliderBlock,
  SanityInfoBlock,
  SanityMapBlock,
  SanityMediaContentBlock,
  SanityNewsletterBlock,
  SanityPartnersBlock,
  SanityStatsBlock,
  SanityStepsBlock,
  SanityVideoSliderBlock,
  SanityLink,
  SanityLinkWithIcon,
  SanityPlainLink,
  SanityVideoInner,
  SanityImageTitleDesc,
  SanityVideoSliderContent,
  SanityBlogInner,
  SanityGetStartedBlock,
} from "@graphql-types";

export type Dimensions = {
  width?: string;
  height?: string;
};

export type Asset = keyof typeof assets;
export type Color = keyof typeof colors;
export type ColorRGB = keyof typeof colorsRGB;
export type ButtonTheme = keyof typeof buttonStyle;
export type ButtonType = typeof buttonStyle;
export type FontWeights = keyof typeof fontWeights;
export type AssetType = typeof assets;

export type SetRegion = Dispatch<
  SetStateAction<{
    iban: string;
    langCode: string;
  }>
>;

export type AnimationDirection = {
  to: {
    transform?: string;
    opacity?: number;
  };
  from: {
    transform?: string;
    opacity?: number;
  };
};

export function isSanityPlainLink(data: any): data is SanityPlainLink {
  return data._type === "plainLink";
}
export function isSanityLink(data: any): data is SanityLink {
  return data._type === "link";
}
export function isSanityLinkWithIcon(data: any): data is SanityLinkWithIcon {
  return data._type === "linkWithIcon";
}

export function isSanityImage(data: any): data is SanityImage {
  return data._type === "image";
}

export function isSanityVideo(data: any): data is SanityVideo {
  return data._type === "video";
}

export function isSanityVideoWithPlaceholder(
  data: any
): data is SanityVideoWithPlaceholder {
  return data._type === "videoWithPlaceholder";
}

export function isSanityImageWithMeta(
  data:
    | SanityImageWithMetaOrVideo
    | SanityImageWithMetaOrVideoOrVideoWithPlaceholder
): data is SanityImageWithMeta {
  return data._type === "imageWithMeta";
}

// Component Blocks
export function isSanityInfoBlock(data: any): data is SanityInfoBlock {
  return data._type === "infoBlock";
}
export function isSanityStatsBlock(data: any): data is SanityStatsBlock {
  return data._type === "statsBlock";
}
export function isSanityFullScreenContentBlock(
  data: any
): data is SanityFullScreenContentBlock {
  return data._type === "fullScreenContentBlock";
}
export function isSanityImagesContentBlock(
  data: any
): data is SanityImagesContentBlock {
  return data._type === "imagesContentBlock";
}
export function isSanityMediaContentBlock(
  data: any
): data is SanityMediaContentBlock {
  return data._type === "mediaContentBlock";
}
export function isSanityStepsBlock(data: any): data is SanityStepsBlock {
  return data._type === "stepsBlock";
}
export function isSanityCalculatorBlock(
  data: any
): data is SanityCalculatorBlock {
  return data._type === "calculatorBlock";
}
export function isSanityFeaturedBlogsBlock(
  data: any
): data is SanityFeaturedBlogsBlock {
  return data._type === "featuredBlogsBlock";
}
export function isSanityMapBlock(data: any): data is SanityMapBlock {
  return data._type === "mapBlock";
}
export function isSanityImageContentCarouselBlock(
  data: any
): data is SanityImageContentCarouselBlock {
  return data._type === "imageContentCarouselBlock";
}
export function isSanityAccordionBlock(
  data: any
): data is SanityAccordionBlock {
  return data._type === "accordionBlock";
}
export function isSanityNewsletterBlock(
  data: any
): data is SanityNewsletterBlock {
  return data._type === "newsletterBlock";
}
export function isSanityVideoSliderBlock(
  data: any
): data is SanityVideoSliderBlock {
  return data._type === "videoSliderBlock";
}
export function isSanityImageSliderBlock(
  data: any
): data is SanityImageSliderBlock {
  return data._type === "imageSliderBlock";
}
export function isSanityPartnersBlock(data: any): data is SanityPartnersBlock {
  return data._type === "partnersBlock";
}
export function isSanityGetStartedBlock(data: any): data is SanityGetStartedBlock {
  return data._type === "getStartedBlock";
}
// Component Blocks END

// VideoPreview Types
export function isSanityVideoSliderContent(
  data: any
): data is SanityVideoSliderContent {
  return data._type === "videoSliderContent";
}
export function isSanityImageTitleDesc(
  data: any
): data is SanityImageTitleDesc {
  return data._type === "imageTitleDesc";
}
// VideoPreview Types END

// Posts Types
export function isSanityBlogInner(data: any): data is SanityBlogInner {
  return data._type === "blogInner";
}
export function isSanityVideoInner(data: any): data is SanityVideoInner {
  return data._type === "videoInner";
}
// Posts Types END
