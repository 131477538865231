import React from "react";

import { SanityLink, SanityLinkOrLinkWithIcon, SanityLinkWithIcon, Maybe } from "@graphql-types";
import { Button } from "@global";
import { ButtonTheme, isSanityLinkWithIcon } from "@util/types";

interface Props {
  data: Maybe<SanityLinkOrLinkWithIcon> | Maybe<SanityLinkWithIcon> | Maybe<SanityLink> | undefined;
}

const LinksWithIcon = ({ data }: Props) => {

  if (data == null) return null;
  if (data.url == null) return null;

  if (isSanityLinkWithIcon(data) && data.icon) {
    return (
      <Button
        theme={data.buttonTheme as ButtonTheme}
        text={data.linktext ?? 'Button'}
        linkTo={data.internallink ? data.url ?? undefined : undefined}
        linkToNewWindow={data.internallink === false ? data.url ?? undefined : undefined}
        margin="0 15px 10px 0"
        icon={data.icon}
      />
    );
  }

  return (
    <Button
      theme={data.buttonTheme as ButtonTheme}
      text={data.linktext ?? 'Button'}
      linkTo={data.internallink ? data.url ?? undefined : undefined}
      linkToNewWindow={data.internallink === false ? data.url ?? undefined : undefined}
      margin="0 15px 10px 0"
    />
  );

};

export default LinksWithIcon;