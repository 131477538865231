import { useStaticQuery, graphql } from "gatsby";

import { Query } from "@graphql-types";
import { DEFAULT_REGION } from "@util/constants";

const GetStartedQuery = (iban?: string) => {
  const {
    allSanityGetStarted: { nodes },
  }: Query = useStaticQuery(graphql`
    {
      allSanityGetStarted {
        nodes {
          region {
            ...sanityRegion
          }
          phoneLabel
          nameLabel
          instagramHandleLabel
          emailLabel
          buttonLabel
          errorSubmission
          successUrl
          blockContent {
            _rawContent
          }
        }
      }
    }
  `);

  const getStartedData =
    nodes.find((node) => node.region?.iban === iban) ??
    nodes.find((node) => node.region?.iban === DEFAULT_REGION);

  return getStartedData;
};

export default GetStartedQuery;
