import { useRef, useEffect, RefObject, useState } from "react";
import ReactSlick from "react-slick";
import { useStore } from "@state/store";
import { useHasMounted } from "./hooks";
import { Maybe, SanityBlockContent, SanityGlobalLanguage } from "@graphql-types";
import { DEFAULT_REGION } from "./constants";

export const isBrowser = () => typeof window !== "undefined";

export function usePrevious<T>(value: T) {
  const ref = useRef<T>(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export function handleNextSlide(
  sliderRef: RefObject<ReactSlick>,
  back?: boolean
) {
  if (sliderRef.current) {
    if (back) {
      sliderRef.current.slickPrev();
    } else {
      sliderRef.current.slickNext();
    }
  }
}

export function useDebounce<T>(value: T, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

export function bigNumberFormatter(
  value: number,
  suffix?: string,
  prefix?: string
) {
  if (prefix) {
    return `${prefix}${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  }

  if (suffix) {
    return `${value
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${suffix}`;
  }

  return `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
}

export function setSlug(url: string | undefined | null) {
  const hasMounted = useHasMounted();

  if (!hasMounted) {
    return `${url}`;
  }

  const slug = useStore.getState().region.slug;

  if (url == null) {
    return "";
  }

  if (url === "/") {
    return `/${slug}`;
  }

  if (slug !== "") {
    return `/${slug}${url}`;
  }

  return `${url}`;
}

export function setBaseUrl(slug?: string, iban?: string) {
  if (slug == null && iban === DEFAULT_REGION) {
    return process.env.GATSBY_BASE_URL;
  }

  if (slug == null) {
    return `${process.env.GATSBY_BASE_URL}/${iban}`;
  }

  if (iban === DEFAULT_REGION) {
    return `${process.env.GATSBY_BASE_URL}/${slug}`;
  }

  if (iban == null) {
    return `${process.env.GATSBY_BASE_URL}/${slug}`;
  }

  return `${process.env.GATSBY_BASE_URL}/${iban}/${slug}`;
}

export const setRegion = (
  iban: Maybe<string> | undefined,
  hrefLang: Maybe<string> | undefined,
  globalLanguage: SanityGlobalLanguage[]
) => {
  if (!iban) return;

  const languageData =
    globalLanguage.find((node) => node.region?.iban === iban) ??
    globalLanguage.find((node) => node.region?.iban === DEFAULT_REGION);

  useStore.setState({
    region: {
      hrefLang: hrefLang,
      iban: iban,
      slug: iban !== DEFAULT_REGION ? iban : "",
    },
    language: languageData,
  });
};

export const getCountryCode = async () => {
  const result = await (await fetch("https://ipapi.co/json/")).json();
  return (result.country_code as string).toLowerCase();
};

export async function getIp() {
  const result = await (await fetch("https://ipapi.co/json/")).json();

  return (result.ip as string).toLowerCase();
}

export const getPosition = (
  string: string,
  subString: string,
  index: number
) => {
  return string.split(subString, index).join(subString).length;
};

export const truncateWords = (text: string, index: number) => {
  const array = text.split(" ");

  if (array.length < index) return text;

  return array.slice(0, 15).join(" ") + "...";
};

export const scrollToTop = (duration: number) => {
  // cancel if already on top
  if (document?.scrollingElement?.scrollTop == null || document.scrollingElement.scrollTop === 0) return;

  const totalScrollDistance = document.scrollingElement.scrollTop;
  let scrollY = totalScrollDistance,
    oldTimestamp: number | null = null;

  const step = (newTimestamp: number) => {
    if (document?.scrollingElement?.scrollTop == null) return;

    if (oldTimestamp !== null) {
      // if duration is 0 scrollY will be -Infinity
      scrollY -= totalScrollDistance * (newTimestamp - oldTimestamp) / duration;
      if (scrollY <= 0) return document.scrollingElement.scrollTop = 0;
      document.scrollingElement.scrollTop = scrollY;
    }
    oldTimestamp = newTimestamp;
    window.requestAnimationFrame(step);

    return;
  }

  window.requestAnimationFrame(step);
};

export const toPlainText = (
  blocks: Maybe<SanityBlockContent> | undefined
): string => {
  if (blocks?._rawContent == null) return "";
  return (
    blocks._rawContent
      // loop through each block
      .map(block => {
        // if it's not a text block with children,
        // return nothing
        if (block._type !== "block" || !block.children) {
          return "";
        }
        // loop through the children spans, and join the
        // text strings
        return block.children.map(child => child.text).join("");
      })
      // join the paragraphs leaving split by two linebreaks
      .join("\n\n")
  );
};

export const setCookie = (cname: string, cvalue: any, exdays: number) => {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export const getCookie = (cname: string) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export const emailValidator = (str: string) => {
  const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
  return emailRegex.test(str);
};

export const recaptchaValidate = (recaptchaToken: string) => {
  const { user } = useStore.getState();

  if (recaptchaToken) {
    useStore.setState({
      user: {
        ...user,
        recaptchaVerified: true,
      }
    })
  }
};