import React, { useRef, useEffect, useState } from "react";
import ReactSlick from "react-slick";

import { SanityImageSliderBlock } from "@graphql-types";
import { Container, Wrapper } from "@util/standard";
import { Carousel, SwipeIndicator } from "@global";
import { Color } from "@util/types";
import { TitleButtonContainer, BlockWrapper } from "@shared/shared.styles";
import { useStore } from "@state/store";
import { handleNextSlide } from "@util/helper";
import VideoPreview from "@shared/videoPreview"
import { useCheckScreenWidth } from "@util/hooks";

interface Props {
  data: SanityImageSliderBlock;
}

const ImageSliderBlock = ({ data }: Props) => {
  const { language } = useStore();
  const sliderRef = useRef<ReactSlick>(null);
  const carouselContainer = useRef<HTMLDivElement>(null);
  const [contentToFit, setContentToFit] = useState(4);
  const { isTabletWidth, isMobileWidth } = useCheckScreenWidth();

  if (data == null) {
    return null;
  }

  useEffect(() => {
    if (carouselContainer != null && carouselContainer.current?.offsetWidth != null) {
      const fit = (isTabletWidth || isMobileWidth) ? carouselContainer.current.offsetWidth / 240 : carouselContainer.current.offsetWidth / 280;
      setContentToFit(Math.floor(fit));
    }
  }, [carouselContainer, isTabletWidth, isMobileWidth]);

  const { title, description, content, background, anchorId } = data;

  return (
    <BlockWrapper id={anchorId ?? undefined} background={background?.backgroundColor as Color ?? 'white'}>
      <Wrapper>
        <TitleButtonContainer>
          <div>
            <h3>{title}</h3>
            <p>{description}</p>
          </div>
        </TitleButtonContainer>

        {content && content.length > 0 &&
          <Container ref={carouselContainer} position="relative">
            <Carousel
              hideArrow
              sliderRef={sliderRef}
              alignDots="left"
              overflow={true}
              variableWidth={true}
              infinite={false}
              slidesToShow={content.length > contentToFit ? contentToFit : content.length}
              slidesToScroll={contentToFit}
              background={background?.backgroundColor as Color}
            >
              {content.map((contentObject) => {
                if (contentObject == null) return null;
                return (
                  <VideoPreview data={contentObject} key={contentObject._key} />
                );
              })}
            </Carousel>
            {content.length > contentToFit &&
              <SwipeIndicator text={language?.sliderSwipe ?? "swipe"} onClick={() => handleNextSlide(sliderRef)} />
            }
          </Container>
        }
      </Wrapper>
    </BlockWrapper>
  );
};

export default ImageSliderBlock;