import React, { createElement } from "react";
import styled from "styled-components";
import PortableText from "@sanity/block-content-to-react";

import { Maybe, SanityBlockContent } from "@graphql-types";
import { colors } from "@util/constants";
import { P } from "@util/standard";
import Button from "./button";
import { urlForImageRef } from "@lib/sanityClient";
interface Props {
  data: Maybe<SanityBlockContent> | undefined;
}

const Img = styled.img`
  height: auto;
  width: auto;
  max-width: 100%;
  margin: 0 auto 35px;
`;

const BlocksContent = ({ data }: Props) => {
  if (data == null) {
    return null;
  }

  const { _rawContent } = data;

  if (_rawContent == null) {
    return null;
  }

  const color = (props: any) => {
    return <span style={{ color: `${colors.green}` }}>{props.children}</span>;
  };

  const textLink = ({ mark, children }) => {
    const { blank, href } = mark;
    return blank ?
      <a href={href} target="_blank" rel="noopener">{children}</a>
      : <a href={href}>{children}</a>
  }

  const link = (props: any) => {
    const { linktext, url, _key } = props.node;

    return (
      <Button
        key={_key}
        theme="green"
        text={linktext}
        linkTo={url}
        margin="25px 0 0 0"
      />
    );
  };

  const block = (props: any) => {
    const { style = "normal" } = props.node;
    const tag = style;
    if (/^h\d/.test(tag)) {
      return createElement(tag, {}, props.children);
    }
    if (style === 'blockquote') {
      return <blockquote>{props.children}</blockquote>
    }
    return <P>{props.children}</P>;
  };

  const imageWithMeta = (props: any) => {
    return (
      <Img src={urlForImageRef(props.node.asset).url() as string} />
    );
  };

  return (
    <div className="rte">
      <PortableText
        blocks={_rawContent}
        serializers={{
          marks: { color, link: textLink },
          types: { block, link, imageWithMeta },
        }}
        imageOptions={{ auto: "format", fit: "fill" }}
        projectId={process.env.SANITY_PROJECT_ID}
        dataset={process.env.GATSBY_NODE_ENV ?? "development"}
      />
    </div>
  );
};

export default BlocksContent;
