import React, { ReactNode, useEffect } from "react";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { navigate } from "gatsby";
import { globalHistory } from "@reach/router";
import styled from "styled-components";

import { Container } from "@util/standard";
import { Maybe, SanityGlobalLanguage, SanityLink, SanityRegion } from "@graphql-types";
import { IconButton, Image, Link, RegionSelector, Button } from "@global";
import { isBrowser, setSlug } from "@util/helper";
import { MobileContainer, MenuTopContainer } from "./shared.styles";
import { TABLET_BREAKPOINT, colors } from "@util/constants";
import { Region } from "@state/types";

interface Props {
  links: Maybe<Maybe<SanityLink>[]> | undefined;
  logo: Maybe<string> | undefined;
  menuBackground: IGatsbyImageData;
  handleDrawerClose: () => void;
  availableRegions: Maybe<SanityRegion>[];
  region: Region;
  globalLanguage: SanityGlobalLanguage[];
  buttonLabel: Maybe<string> | undefined;
}

const LinksContainer = styled(Container)`
  padding: 20vh 0 10vh;
  height: auto;
  a{
    font-size: 35px;
    margin-bottom: 60px;
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px){
    &&{
      a{
        margin-bottom: 30px;
      }
      p{
        font-size: 30px;
      }
    }
  }
`;

const DrawerRegionContainer = styled(Container)`
  position: absolute;
  background: ${colors.white};
  left: 35px;
  top: 21px;
  border-radius: 100px;
  padding: 12px 20px;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    top: auto;
    left: 0;
    bottom: 5vh;
    padding: 15px 25px 15px 12px;
    border-radius: 0px 100px 100px 0px;
  }
`;

const GetStartedContainer = styled(Container)`
  position: absolute;
  top: 21px;
  right: 100px;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    top: 27px;
    right: 80px;
  }
`;

const Menu = ({ links, logo, menuBackground, handleDrawerClose, availableRegions, region, globalLanguage, buttonLabel }: Props) => {
  if (!isBrowser()) {
    return null;
  }
  const homeUrl = setSlug(`/`);

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") {
        handleDrawerClose();
      }
    });
  }, []);

  return (
    <>
      <MenuTopContainer>
        <MobileContainer>
          {logo &&
            <Image
              staticImage={logo}
              width="45px"
              height="auto"
              altText="macro-active"
              onClick={() => navigate(homeUrl)}
            />
          }
        </MobileContainer>
        <IconButton icon="close" onClick={handleDrawerClose} />
      </MenuTopContainer>
      <Image
        isBackground
        imageData={menuBackground}
        altText="background-image"
        minHeight="100vh"
        overflow="auto"
      >
        <LinksContainer
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          {links?.map((link) => {
            if (link == null) {
              return null;
            }

            const currentPath = link?.url === "/" ? link?.url : `/${link?.url}`;
            const isCurrentPage = window.location.pathname === currentPath;

            return (
              <Link
                {...link}
                isBold
                key={link._key}
                underlineThickness={5}
                isUnderlined={isCurrentPage}
                color={isCurrentPage ? "green" : "white"}
              />
            );
          })}
        </LinksContainer>
      </Image>
      {availableRegions.length > 1 &&
        <DrawerRegionContainer>
          <RegionSelector languages={availableRegions} currentRegion={region} globalLanguage={globalLanguage} />
        </DrawerRegionContainer>
      }
      <GetStartedContainer>
        <Button
          theme="green"
          text={buttonLabel ?? "Get Started"}
          linkTo="#get-started"
        />
      </GetStartedContainer>
    </>
  );
};

export default Menu;
