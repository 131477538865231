import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { ToastContainer } from "react-toastify";
import { ReCaptcha } from "react-recaptcha-v3";
import { PageProps } from "gatsby";

import "react-toastify/dist/ReactToastify.css";
import "@styles/globalStyles.css";

import Header from "./header";
import Footer from "./footer";
import { GlobalStyle, Main } from "@styles/globalStyles";
import { PolicyBar } from "@global";
import { recaptchaValidate } from "@util/helper";
import { useRecaptcha } from "@util/hooks";
interface Props extends PageProps {
  pageContext: {
    pagePath: string;
    region: string;
    template: string;
    hideHero?: boolean;
    hideHeader?: boolean;
  };
}

function Layout(props: Props) {
  const { children, pageContext } = props;
  const recaptchaLoaded = useRecaptcha();

  useEffect(() => {
    if (location.hash && location.hash != '#get-started') {
      setTimeout(() => {
        window.location.href = location.hash
      }, 1000);
    }
  }, []);

  return (
    <>
      <GlobalStyle />
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Mulish:wght@400;800;900&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <PolicyBar />
      <Header {...props} path={pageContext.pagePath} pageRegion={pageContext.region} template={pageContext.template} />
      <Main>{children}</Main>
      <ToastContainer position="bottom-right" />
      <Footer template={pageContext.template} />
      {recaptchaLoaded && (
        <ReCaptcha
          sitekey={process.env.GATSBY_RECAPTCHA_KEY}
          action="check_user"
          verifyCallback={recaptchaValidate}
        />
      )}
    </>
  );
}

export default Layout;
