import { useStaticQuery, graphql } from "gatsby";

import { Query } from "@graphql-types";
import { DEFAULT_REGION } from "@util/constants";

const NewsletterLanguage = (iban?: string) => {
  const {
    allSanityNewsletterLanguage: { nodes },
  }: Query = useStaticQuery(graphql`
    {
      allSanityNewsletterLanguage {
        nodes {
          region {
            ...sanityRegion
          }
          buttonLabel
          emailLabel
          handleLabel
          nameLabel
        }
      }
    }
  `);

  const languageData =
    nodes.find((node) => node.region?.iban === iban) ??
    nodes.find((node) => node.region?.iban === DEFAULT_REGION);

  return languageData;
};

export default NewsletterLanguage;
