import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { navigate } from "gatsby";
import styled from "styled-components";
import axios from "axios";
import { toast } from "react-toastify";

import { BlocksContent, Button, Modal, TextInput } from "@global";
import { Container, GridContainer } from "@util/standard";
import { TABLET_BREAKPOINT } from "@util/constants";
import { useGetStarted } from "@query";
import { useStore } from "@state/store";
import { getIp, emailValidator } from "@util/helper";
import { SanityGetStartedBlock } from "@graphql-types";

const MainContainer = styled(Container)`
  flex-direction: column;
  width: 70%;
  margin: auto;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 90%;
    padding-bottom: 50px;
  }
`;

const FormContainer = styled(Container)`
  flex-direction: column;
  width: 100%;
  margin-top: 15px;
`;

const ButtonContainer = styled(Container)`
  width: 230px;
  justify-content: flex-end;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
  }
`;
interface State {
  name: string;
  email: string;
  phone: string;
  instagram: string;
}
interface Props {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}

interface GetStartedProps {
  setVisible?: Dispatch<SetStateAction<boolean>>;
}

export const GetStartedContent = ({ setVisible }: GetStartedProps) => {
  const { region, user } = useStore();
  const getStarted = useGetStarted(region.iban);
  const initialState: State = {
    name: "",
    email: user?.email ?? "",
    phone: "",
    instagram: user?.instagramHandle ?? "",
  };
  const [state, setState] = useState<State>(initialState);
  const [clickedSubmit, setClickedSubmit] = useState(false);
  const [submitted, setSubmitted] = useState(false);


  useEffect(() => {
    
    const script = document.createElement('script');

    script.innerHTML = 'hbspt.forms.create({region: "na1",portalId: "20690823",formId: "26420170-65a3-4cfd-ac43-c1f4a3fec2ce"})'
    document.getElementById('hs-form-div')?.appendChild(script)

  }, []);

  function formValidated() {
    const values = Object.values(state);
    if (emailValidator(state.email) === false) {
      return false;
    };

    if (values.includes("")) {
      return false;
    }
    return true;
  }

  function handleInputChange(event: any) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const handleClickedSubmit = () => {
    if (process.env.GATSBY_GETFORM_URL_GETSTARTED && formValidated()) {
      setClickedSubmit(true);
      axios
        .post(process.env.GATSBY_GETFORM_URL_GETSTARTED, state)
        .then((_response) => {
          successfullConversion();
          setSubmitted(true);
        })
        .catch((_error) => {
          setClickedSubmit(false);
          toast.error(getStarted?.errorSubmission ?? `Error submitting form. Please check your fields or try again later.`);
        });
    } else {
      toast.error(getStarted?.errorSubmission ?? `Error submitting form. Please check your fields or try again later.`)
    }
  };

  const successfullConversion = () => {
    const payload = {
      fname: state.name,
      email: state.email,
      client_user_agent: navigator.userAgent,
    };

    axios
      .post("/.netlify/functions/formSubmit", payload)
      .then((res) => {
        // console.log({ res });
      })
      .catch((err) => {
        // console.log({ err });
      });
  };

  return (
    <MainContainer>
      {getStarted?.blockContent &&
        <BlocksContent data={getStarted.blockContent} />
      }
      <div id='hs-form-div'></div>
    </MainContainer>
  );
};

const GetStarted = ({ visible, setVisible }: Props) => {

  useEffect(() => {
    if (visible) {
      onOpenModalConversion();
    }
  }, [visible]);

  const onOpenModalConversion = async () => {
    const ip = await getIp();
    const payload = {
      client_user_agent: navigator.userAgent,
      client_ip: ip,
    };

    axios
      .post("/.netlify/functions/trackLead", payload)
      .then((res) => {
        // console.log({ res });
      })
      .catch((err) => {
        // console.log({ err });
      });
  };

  return (
    <Modal visible={visible} setVisible={setVisible} width="80%">
      <GetStartedContent setVisible={setVisible} />
    </Modal>
  );
};

export default GetStarted;
