import React, { useRef } from "react";
import styled from "styled-components";
import ReactSlick from "react-slick";

import {
  BlocksContent,
  Carousel,
  Image,
  Button,
  SwipeIndicator,
} from "@global";
import { SanityInfoBlock, Maybe, SanityInfo } from "@graphql-types";
import { TABLET_BREAKPOINT } from "@util/constants";
import { Container, GridContainer, P } from "@util/standard";
import { DesktopContainer } from "@shared/shared.styles";
import { handleNextSlide } from "@util/helper";
import { ButtonTheme } from "@util/types";
import { useStore } from "@state/store";

const MainContainer = styled(Container)`
  flex-direction: column;
  width: 80%;
  margin: 150px auto;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin: 80px auto;
  }
`;

const MobileContainer = styled(Container)`
  width: 100%;
  display: none;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    display: flex;
    position: relative;
    margin-top: 40px;
  }
`;

const TitleContainer = styled(Container)`
  flex-direction: column;
  max-width: 60%;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    max-width: 100%;
  }
`;

interface Props {
  content: Maybe<SanityInfoBlock> | undefined;
}

function InfoGrid({ content }: Props) {
  if (content == null) {
    return null;
  }

  const { language } = useStore();
  const { blockTitle, description, infos, anchorId } = content;
  const sliderRef = useRef<ReactSlick>(null);

  const Info = ({
    info,
    margin,
  }: {
    info: Maybe<SanityInfo>;
    margin?: string;
  }) => (
    <Container flexDirection="column" margin={margin}>
      <Image
        imageData={info?.icon?.asset?.gatsbyImageData}
        margin="0 0 20px 0"
      />
      <BlocksContent data={info?.infoTitle} />
      <P>{info?.description}</P>
      {info?.cta?.linktext && (
        <Button
          theme={info?.cta?.buttonTheme as ButtonTheme}
          text={info?.cta?.linktext as string}
          linkTo={info?.cta?.url as string}
          margin="30px 0 0 0"
        />
      )}
    </Container>
  );

  return (
    <MainContainer id={anchorId ?? undefined}>
      <TitleContainer>
        <BlocksContent data={blockTitle} />
        <P>{description}</P>
      </TitleContainer>
      {infos &&
        <>
          <DesktopContainer>
            <GridContainer
              repeat={infos.length > 3 ? 4 : 3}
              columnGap={infos.length > 3 ? 55 : 120}
              rowGap={65}
              margin="50px 0px 0px 0px "
            >
              {infos?.map((info) => {
                if (info == null) return null;
                return (
                  <Info info={info} key={info._key} />
                );
              })}
            </GridContainer>
          </DesktopContainer>
          <MobileContainer>
            <Carousel hideArrow sliderRef={sliderRef} alignDotsMobile="left">
              {infos.map((info) => {
                if (info == null) return null;
                return (
                  <Info info={info} key={info._key} />
                );
              })}
            </Carousel>
            <SwipeIndicator text={language?.sliderSwipe ?? "swipe"} onClick={() => handleNextSlide(sliderRef)} />
          </MobileContainer>
        </>
      }

    </MainContainer>
  );
}

export default InfoGrid;
