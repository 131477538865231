import React, { ReactNode } from "react";
import styled from "styled-components";

import { Maybe, SanityFullScreenContentBlock } from "@graphql-types";
import { Container, LinearGradient } from "@util/standard";
import MediaContent from "@shared/mediaContent";
import { BlocksContent, LinksWithIcon } from "@global";
import { MOBILE_BREAKPOINT, colors, TABLET_BREAKPOINT } from "@util/constants";
import { useCheckScreenWidth } from "@util/hooks";
import { ParallaxContainer, ButtonContainer } from "@shared/shared.styles";
import { Parallax } from 'react-scroll-parallax';

const ComponentContainer = styled(Container) <{
  minHeight: string;
  mobileMinHeight: string;
}>`
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 70vh;
  min-height: ${({ minHeight }) => minHeight};

  > div{
    height: 100%;
  }

  h1,
  h2,
  h3,
  h4 {
    color: ${colors.white};
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    min-height: ${({ mobileMinHeight }) => mobileMinHeight};
  }

`;

const ContentContainer = styled(Container) <{
  contentPos: string;
}>`
  position: absolute;
  flex-direction: column;
  bottom: 0;
  left: 0;
  right: 0;
  width: 80%;
  max-width: 410px;
  z-index: 2;
  margin: 0 auto 0 10%;
  padding: 10vh 0;
  height: 100%;
  justify-content: ${({ contentPos }) => contentPos === 'top' ? `flex-start` : contentPos === 'middle' ? `center` : `flex-end`};
  p {
    color: ${colors.white};
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: 50px 0;
  }
`;

interface Props {
  data: Maybe<SanityFullScreenContentBlock> | undefined;
  childContent?: ReactNode;
}

const FullScreenContentBlock = ({ data, childContent }: Props) => {
  const { isTabletWidth, isMobileWidth } = useCheckScreenWidth();

  if (data == null) {
    return null;
  }

  const { blockTitle, media, links, contentPosition, minHeight, mediaMobile, minHeightMobile, anchorId } = data;
  const heroMedia = ((isTabletWidth || isMobileWidth) && (mediaMobile && mediaMobile.length > 0)) ? mediaMobile : media;

  return (
    <ComponentContainer id={anchorId ?? undefined} minHeight={minHeight ?? '750px'} mobileMinHeight={minHeightMobile ?? '550px'}>
      <LinearGradient />
      {heroMedia &&
        <ParallaxContainer>
          <Parallax y={["-300px", "300px"]} disabled={isMobileWidth}>
            {heroMedia.map((selectedMedia) => {
              if (selectedMedia == null) {
                return null;
              }

              return <MediaContent key={selectedMedia._key} data={selectedMedia} />;
              // return <MediaContent key={selectedMedia._key} data={selectedMedia} onLoad={() => { return <ParallaxCache runUpdate /> }} />;
            })}
          </Parallax>
        </ParallaxContainer>
      }

      <ContentContainer contentPos={contentPosition ?? ''}>
        <BlocksContent data={blockTitle} />
        {links && (
          <ButtonContainer>
            {links.map((link) => {
              if (link == null) return null;
              return <LinksWithIcon key={link?._key} data={link} />;
            })}
          </ButtonContainer>
        )}
        {childContent}
      </ContentContainer>
    </ComponentContainer>
  );
};

export default FullScreenContentBlock;
