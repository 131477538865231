import React, { CSSProperties, ReactNode } from "react";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { convertToBgImage, BgImage } from "gbimage-bridge";
import styled from "styled-components";

import { Container } from "@util/standard";
import { Maybe } from "@graphql-types";

const BackgroundGatsbyImage = styled(GatsbyImage) <{}>`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const BackgroundImageWrapper = styled(Container) <{ minHeight?: string; overflow?: string; }>`
  width: 100%;
  height: 100%;
  flex-direction: column;
  margin: 0;
  position: relative;
  overflow: ${({ overflow }) => overflow ?? "hidden"};
  ${({ minHeight }) => minHeight && `min-height: ${minHeight};`}
`;

const GatsbyImageWrapper = styled(GatsbyImage) <{
  fluidfixed?: string;
}>`
  ${({ fluidfixed }) => fluidfixed === "true" && `
    [data-main-image]{
      position: relative;
    }
  `}
`;

interface Props {
  imageData?: IGatsbyImageData;
  staticImage?: string;
  minHeight?: string;
  isBackground?: boolean;
  width?: string;
  height?: string;
  altText?: Maybe<string> | undefined;
  children?: ReactNode;
  onClick?: () => void;
  objectFit?: CSSProperties["objectFit"];
  margin?: string;
  isStretched?: boolean;
  onLoad?: () => void;
  fluidFixed?: boolean;
  overflow?: string;
}

const StyledBgImage = styled.div<{ isStretched?: boolean }>`
  height: 100%;
  ${({ isStretched }) => isStretched && `
    width: 100%;
    position: absolute;
  `}
  > div{
    height: 100%;
  }
`;

const Image = (props: Props) => {
  const { minHeight, isStretched, onLoad, fluidFixed, overflow } = props;

  if (props.isBackground) {
    const bgImage = convertToBgImage(props.imageData);
    return (
      <StyledBgImage isStretched={isStretched}>
        <BgImage preserveStackingContext {...bgImage}>
          <BackgroundImageWrapper minHeight={props.minHeight}>
            <BackgroundGatsbyImage
              image={props.imageData as IGatsbyImageData}
              alt="MacroActive"
              objectFit="cover"
              onLoad={onLoad}
            />
            <BackgroundImageWrapper overflow={overflow}>{props.children}</BackgroundImageWrapper>
          </BackgroundImageWrapper>
        </BgImage>
      </StyledBgImage>
    );
  }

  if (props.staticImage) {
    return (
      <img
        src={props.staticImage}
        width={props.width}
        height={props.height}
        style={{
          minHeight,
          cursor: props.onClick ? 'pointer' : 'inherit',
          margin: props.margin,
        }}
        itemProp="image"
        alt={props.altText ?? "MacroActive"}
        onClick={props.onClick}
        onLoad={onLoad}
      />
    );
  }

  if (props.imageData) {
    return (
      <GatsbyImageWrapper
        image={props.imageData}
        style={{
          minHeight,
          margin: props.margin,
          height: props.height ?? `${props.imageData.height}px`,
          width: props.width ?? `${props.imageData.width}px`,
          maxWidth: "100%",
          cursor: props.onClick ? 'pointer' : 'inherit',
        }}
        fluidfixed={fluidFixed === true ? "true" : "false"}
        itemProp="image"
        alt={props.altText ?? "MacroActive"}
        onClick={props.onClick}
        objectFit={props.objectFit ?? "contain"}
        onLoad={onLoad}
      />
    );
  }

  return null;
};

export default Image;
