import React from "react";

import { SanityGetStartedBlock } from "@graphql-types";
import { BlockWrapper } from "@shared/shared.styles";
import { GetStartedContent } from "@shared/getStarted";
import { Wrapper } from "@util/standard";
import { Color } from "@util/types";

interface Props {
  data: SanityGetStartedBlock;
}

const GetStartedBlock = ({ data }: Props) => {

  const { background, anchorId } = data;

  return (
    <BlockWrapper id={anchorId ?? undefined} background={background?.backgroundColor as Color ?? 'white'}>
      <Wrapper>
        <GetStartedContent />
      </Wrapper>
    </BlockWrapper>
  )
};

export default GetStartedBlock;