import { useStaticQuery, graphql } from "gatsby";

import { Query } from "@graphql-types";

const BlogInnerQuery = (iban?: string) => {
  const {
    allSanityBlogInner: { nodes },
  }: Query = useStaticQuery(graphql`
    {
      allSanityBlogInner(sort: { fields: publishedDate, order: DESC }) {
        nodes {
          ...sanityBlogInnerPreview
        }
      }
    }
  `);

  const blogInnerData = nodes.filter((node) => {
    return node.region?.iban === iban && !node.hidden;
  });

  return blogInnerData;
};

export default BlogInnerQuery;
