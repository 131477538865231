import React, { ReactNode, useEffect } from "react";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";

import { usePrevious } from "@util/helper";
import { useCheckScreenWidth } from "@util/hooks";

interface Props {
  width?: string;
  children: ReactNode;
  placement: "right" | "left" | "top" | "bottom";
  duration?: number;
  visible: boolean;
  onClose: () => void;
  backgroundColor?: string;
}

const MenuDrawer = (props: Props) => {
  const prevVisible = usePrevious(props.visible);
  const { isTabletWidth, isMobileWidth } = useCheckScreenWidth();

  useEffect(() => {
    if (prevVisible !== props.visible) {
      if (props.visible) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "initial";
      }
    }
  }, [props.visible]);

  return (
    <Drawer
      open={props.visible}
      onClose={props.onClose}
      direction={props.placement}
      style={{
        background: props.backgroundColor,
        width: (isTabletWidth || isMobileWidth) ? "100%" : "550px",
        height: "100%",
      }}
      duration={props.duration}
      zIndex={21}
    >
      {props.children}
    </Drawer>
  );
};

export default MenuDrawer;
