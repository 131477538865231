import React, { useRef, useState, RefObject, Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import ReactSlick from "react-slick";

import {
  Maybe,
  SanityMediaContentBlock,
  SanityVideoWithContent,
} from "@graphql-types";
import { Container, LinearGradient, P } from "@util/standard";
import {
  Carousel,
  SwipeIndicator,
  BlocksContent,
  LinksWithIcon,
} from "@global";
import { colors, colorsRGB, TABLET_BREAKPOINT } from "@util/constants";
import { FullHeightContainer } from "@shared/shared.styles";
import { Color } from "@util/types";
import { handleNextSlide } from "@util/helper";
import { VideoMediaContent } from "@shared/videoPreview"

const MainContainer = styled(Container) <{
  imagePositionLeft: boolean;
  background: Color;
}>`
  flex-direction: ${({ imagePositionLeft }) => imagePositionLeft ? 'row-reverse' : 'row'};
  ${({ background }) => `background: ${colors[background]};`}
  min-height: 100vh;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: ${({ imagePositionLeft }) => imagePositionLeft ? 'column-reverse' : 'column'};
    min-height: 70vh;
  }
`;

const CarouselContainer = styled(Container) <{
  changeWidth: boolean | number | undefined;
}>`
  width: ${(props) => (props.changeWidth ? "50%" : "100%")};
  margin: auto;
  position: relative;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    margin: 80px auto;
  }
`;

const ContentContainer = styled(Container)`
  flex-direction: column;
  width: 60%;
  margin: auto;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 80%;
  }
`;

const BlockContainer = styled(Container)`
  width: 70%;
  margin: auto;
  align-items: center;
  position: relative;
`;

const ButtonContainer = styled(Container)`
  position: absolute;
  width: 100%;
  justify-content: center;
  bottom: 10%;
  left: 0;
`;

const MediaCarouselContainer = styled(Container)`
  position: relative;
  width: 50%;
  .slick-dots{
    position: absolute;
    z-index: 2;
    bottom: 15px;
    left: 50%;
    transform: translate(-50%, 0);
    width: auto;
    li{
      &.slick-active button:before{
        color: ${colorsRGB.white(1)};
      }
      button:before{
        color: ${colorsRGB.white(0.6)};
      }
    }
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
  }
`;

const MediaContainer = styled(FullHeightContainer)`
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    min-height: 70vh;
  }
`;

interface Props {
  data: Maybe<SanityMediaContentBlock> | undefined;
}

interface MediaContentProps {
  content: SanityVideoWithContent;
  onCarouselDisplay: boolean;
  sliderRef: RefObject<ReactSlick>;
  playNext: boolean;
  setPlayNext: Dispatch<SetStateAction<boolean>>;
}

const MediaCarouselContent = ({ content, onCarouselDisplay, sliderRef, playNext, setPlayNext }: MediaContentProps) => {

  const { autoplay, cta, media: contentMedia, pictureInPicture, title } = content;

  return (
    <MediaContainer>
      <VideoMediaContent
        media={contentMedia}
        autoplay={autoplay}
        pictureInPicture={pictureInPicture}
        playNext={playNext}
        setPlayNext={setPlayNext}
        onCarouselDisplay={onCarouselDisplay}
        sliderRef={sliderRef}
        isPlayCircle
        hasGradient
      >
        <LinearGradient />
        <Container
          zIndex={3}
          flexDirection="column"
          width="100%"
          height="100%"
          margin="auto"
          alignItems="center"
          justifyContent="center"
          position="relative"
        >
          <P color="white" margin="165px 0 0 0">
            {title}
          </P>
          {cta?.linktext && (
            <ButtonContainer>
              <LinksWithIcon data={cta} />
            </ButtonContainer>
          )}
        </Container>

      </VideoMediaContent>

    </MediaContainer >
  );
};

const MediaContentBlock = ({ data }: Props) => {
  if (data == null) {
    return null;
  }

  const { blocks, media, background, imagePositionLeft, linkTextVideo, anchorId } = data;
  const blockRef = useRef<ReactSlick>(null);
  const sliderRef = useRef<ReactSlick>(null);
  const blocksLength = blocks?.length;
  const [slideIndex, setSlideIndex] = useState(0);
  const [playNext, setPlayNext] = useState(false);

  return (
    <>
      <MainContainer id={anchorId ?? undefined} imagePositionLeft={imagePositionLeft ?? false} background={background?.backgroundColor as Color ?? 'white'}>
        <CarouselContainer changeWidth={blocksLength && blocksLength > 0}>
          <BlockContainer>
            <Carousel asNavFor={linkTextVideo === true ? sliderRef : undefined} hideArrow sliderRef={blockRef} alignDots="left">
              {blocks?.map((block) => {
                if (block == null) {
                  return null;
                }

                return (
                  <ContentContainer key={block._key}>
                    <BlocksContent data={block} />
                  </ContentContainer>
                );
              })}
            </Carousel>
            {/* SwipeIndicator will control the media content unless block content length is more than 1 and media length is 1 */}
            {((media && media.length > 1) || (blocksLength && blocksLength > 1)) && (
              <SwipeIndicator onClick={() => handleNextSlide((media && media.length < 2 && blocks && blocks.length > 1) ? blockRef : sliderRef)} />
            )}
          </BlockContainer>
        </CarouselContainer>

        <MediaCarouselContainer>
          <Carousel asNavFor={linkTextVideo === true ? blockRef : undefined} setSelectedIndex={setSlideIndex} hideArrow sliderRef={sliderRef} alignDots="center">
            {media && media.map((content, index) => {
              if (content == null) return null;

              return <MediaCarouselContent
                playNext={playNext}
                setPlayNext={setPlayNext}
                onCarouselDisplay={slideIndex === index}
                key={content._key}
                content={content}
                sliderRef={sliderRef} />;
            })}
          </Carousel>
        </MediaCarouselContainer>
      </MainContainer>
    </>

  );
};

export default MediaContentBlock;
