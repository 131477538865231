import { useStaticQuery, graphql } from "gatsby";

import { Query } from "@graphql-types";
import { DEFAULT_REGION } from "@util/constants";

const DefaultSeoQuery = (iban: string) => {
  const {
    allSanitySiteConfig: { nodes },
  }: Query = useStaticQuery(graphql`
    {
      allSanitySiteConfig {
        nodes {
          defaultSEO {
            ...sanitySeo
          }
        }
      }
    }
  `);

  const seoData =
    nodes.find((node) => node.defaultSEO?.region?.iban === iban) ??
    nodes.find((node) => node.defaultSEO?.region?.iban === DEFAULT_REGION);

  return seoData;
};

export default DefaultSeoQuery;
