import styled, { createGlobalStyle } from "styled-components";

import {
  colors,
  MOBILE_BREAKPOINT,
  SMALL_LAPTOP_BREAKPOINT,
  TABLET_BREAKPOINT,
} from "@util/constants";
import { fontSizes, fontWeights } from "@util/constants";
import { createTheme } from "@mui/material/styles";

export const GlobalStyle = createGlobalStyle`
  *{
    box-sizing: border-box;
  }
  body {
    margin: 0px;
    font-family: 'Mulish', sans-serif;
    font-size: ${fontSizes.p.default}px;
    font-weight: ${fontWeights.normal};
    color: ${colors.grey};
    -webkit-tap-highlight-color: transparent;
  }
  h1, .h1 {
    font-size: ${fontSizes.h1.default}px;
    font-weight: ${fontWeights.black};
  }
  h2, .h2 {
    font-size: ${fontSizes.h2.default}px;
    font-weight: ${fontWeights.black};
    margin: 20px 0;
  }
  h3, .h3 {
    font-size: ${fontSizes.h3.default}px;
    font-weight: ${fontWeights.extrabold};
    margin: 20px 0;
  }
  h4, .h4 {
    font-size: ${fontSizes.h4.default}px;
    font-weight: ${fontWeights.extrabold};
    margin: 20px 0;
  }
  h5, .h5 {
    font-size: 1em;
    font-weight: ${fontWeights.extrabold};
  }

  a {
    user-select: none;
    text-decoration: none;
    transition: color 0.3s ease;
    cursor: pointer;
    opacity: 1;
    color: ${colors.grey};

    &:not([disabled]):hover,
    &:not([disabled]):active,
    &:not([disabled]):focus {
      color: ${colors.black};
    }
  }

  input, textarea{
    background: ${colors.transparent};
  }

  .rte{
    h1, h2, h3, h4, h5, h6, p{
      margin: 0 0 20px;
    }
    a{
      text-decoration: underline;
      text-underline-offset: 3px;
    }
    p, li{
      line-height: 1.6;
    }
    ul, ol{
      margin-bottom: 2rem;
    }
    img{
      margin: 2rem auto;
      display: block;
    }
    blockquote{
      font-size: 24px;
      margin: 2rem auto;
    }
    img + blockquote{
      margin-top: 0;
    }
    blockquote + img{
      margin-top: 0;
    }
    h1, h2, h3, h4, h5, h6, p, img, ul, ol, blockquote{
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
  .modal-overlay{
    position: fixed;
    inset: 0px;
  }
  .videoModal-overlay{
    position: fixed;
    inset: 0px;

    &:before, &:after {
      position: absolute;
      right: 30px;
      top: 10px;
      content: '';
      height: 33px;
      width: 1px;
      background-color: ${colors.white};
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
    @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
      &:before, &:after {
        height: 20px;
        right: 20px;
      }
    }
  }

  @media only screen and (max-width: ${SMALL_LAPTOP_BREAKPOINT}px) {
    h1, .h1 {
      font-size: ${fontSizes.h1.smallLaptop}px;
    }
    h2, .h2 {
      font-size: ${fontSizes.h2.smallLaptop}px;
    }
    h3, .h3 {
      font-size: ${fontSizes.h3.smallLaptop}px;
    }
    h4, .h4 {
      font-size: ${fontSizes.h4.smallLaptop}px;
    }
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    body {
      font-size: ${fontSizes.p.mobile}px;
    }
    h1, .h1 {
      font-size: ${fontSizes.h1.mobile}px;
    }
    h2, .h2 {
      font-size: ${fontSizes.h2.mobile}px;
    }
    h3, .h3 {
      font-size: ${fontSizes.h3.mobile}px;
    }
    h4, .h4 {
      font-size: ${fontSizes.h4.mobile}px;
    }
  }

`;

export const Main = styled.main<{}>``;

export const materialTheme = createTheme({
  typography: {
    fontFamily: "Mulish, sans-serif",
    htmlFontSize: 18,
  },
  palette: {
    primary: {
      main: colors.green,
    },
  },
});
