import React from "react";
import styled from "styled-components";
import { Link as GatsbyLink } from "gatsby";

import { ButtonTheme, Dimensions } from "@util/types";
import { buttonStyle, MOBILE_BREAKPOINT } from "@util/constants";
import Loading from "./loading";
import { Container, P } from "@util/standard";
import { SanityImageWithMeta } from "@graphql-types";
import { Image } from "@global";

const ButtonContainer = styled(Container) <{
  margin: string | undefined;
  mobileMargin: string | undefined;
}>`
  border-radius: 100px;
  overflow: hidden;
  ${({ margin }) => margin && `
    margin: ${margin};
  `}

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    ${({ mobileMargin }) => mobileMargin && `
      margin: ${mobileMargin};
    `}
  }
`;

const StyledButton = styled.button<{
  theme: ButtonTheme;
  dimensions?: Dimensions;
  mobileDimensions?: Dimensions;
  disableHover?: boolean;
  minHeight?: number;
  width?: string;
  padding?: string;
  fontSize?: number;
}>`
  text-align: center;
  cursor: pointer;
  user-select: none;
  border-radius: 50px;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}px;`}
  padding: ${(props) => (props.padding ? props.padding : `12px 25px`)};
  ${({ width }) => width && `width: ${width};`}
  height: ${(props) => props.dimensions?.height ?? `auto`};
  color: ${(props) => props.theme.text};
  border: 2px solid ${(props) => props.theme.border};
  background-color: transparent;
  position: relative;

  &:after {
		content: '';
		position: absolute;
    bottom: -1px;
    left: -1px;
    right: -1px;
    top: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
		background-color: ${(props) => props.theme.bg};
		border-radius: 10rem;
		z-index: 0;
	}
	&:before {
		content: '';
		position: absolute;
    bottom: -2px;
    left: -2px;
    right: -2px;
    top: -2px;
		width: 0%;
    height: calc(100% + 4px);
		background-color: ${(props) => props.theme.hoverBg};
		transition: all .3s;
		border-radius: 10rem;
		z-index: 1;
	}
  div{
    position: relative;
    z-index: 2;
    justify-content: center;
  }

  &:focus {
    outline: 0;
  }
  ${({ disableHover, theme }) =>
    !disableHover &&
    `&:hover {
      color: ${theme.hoverText};
      border: 2px solid ${theme.hoverBorder};
      &:before {
        width: calc(100% + 4px);
      }
  }`}

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    ${({ mobileDimensions }) => mobileDimensions && `width:${mobileDimensions}`}
  }

  ${({ minHeight }) => minHeight && `min-height: ${minHeight}px;`}
`;

const LoadingContainer = styled(Container)`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const CenterAlign = styled.div`
  display: flex;
  align-items: center;
`;

interface Props {
  theme?: ButtonTheme;
  disabled?: boolean;
  className?: string;
  dimensions?: Dimensions;
  mobileDimensions?: Dimensions;
  minHeight?: number;
  text: string;
  margin?: string;
  padding?: string;
  disableHoverEffect?: boolean;
  mobileMargin?: string;
  onClick?: (args?: any) => void;
  loading?: boolean;
  type?: "button" | "submit" | "reset";
  linkTo?: string | undefined;
  linkToNewWindow?: string | undefined;
  width?: string;
  icon?: SanityImageWithMeta;
}

const Button = ({
  theme = "green",
  disabled,
  dimensions,
  width,
  mobileDimensions,
  text,
  margin,
  disableHoverEffect,
  onClick,
  mobileMargin,
  loading,
  minHeight,
  padding,
  type,
  linkTo,
  linkToNewWindow,
  icon
}: Props) => {
  const Loader = () => (
    <LoadingContainer>
      <Loading />
      <P margin="0 0 0 5px" color="white">
        Loading..
      </P>
    </LoadingContainer>
  );

  const handleOnClick = () => {
    if (loading) {
      return;
    }
    if (onClick) {
      onClick();
    }
  };

  const RenderedButton = ({
    externalLink,
  }: {
    externalLink?: string | undefined;
  }) => (
    <ButtonContainer
      margin={margin}
      mobileMargin={mobileMargin}
    >
      <StyledButton
        theme={buttonStyle[theme]}
        dimensions={dimensions}
        width={width}
        disabled={disabled}
        onClick={handleOnClick}
        padding={padding}
        mobileDimensions={mobileDimensions}
        disableHover={loading || disableHoverEffect}
        minHeight={minHeight}
        type={type}
      >
        {loading ? (
          <Loader />
        ) : externalLink ? (
          <a
            style={{ color: "inherit", textDecoration: "none" }}
            href={externalLink}
            target="_blank"
            rel="noreferrer"
          >
            <CenterAlign>
              {icon &&
                <Image
                  imageData={icon?.asset?.gatsbyImageData}
                  altText={icon?.altText ?? text}
                  margin="0 10px 0 0"
                  width="25px"
                  height="25px"
                />
              }{text}
            </CenterAlign>
          </a>
        ) : (
          <CenterAlign>
            {icon &&
              <Image
                imageData={icon?.asset?.gatsbyImageData}
                altText={icon?.altText ?? text}
                margin="0 10px 0 0"
                width="25px"
                height="25px"
              />
            }
            {text}
          </CenterAlign>
        )}
      </StyledButton>
    </ButtonContainer>
  );

  if (linkTo) {
    return (
      <GatsbyLink to={linkTo} style={{ textDecoration: "none" }}>
        <RenderedButton />
      </GatsbyLink>
    );
  }
  return <RenderedButton externalLink={linkToNewWindow} />;
};

export default Button;
