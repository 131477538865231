import React, { RefObject, Dispatch, SetStateAction } from "react";

import { SanityImageWithMetaOrVideoOrVideoWithPlaceholder } from "@graphql-types";
import { Video, Image } from "@global";
import {
  isSanityVideo,
  isSanityVideoWithPlaceholder,
} from "@util/types";
import ReactSlick from "react-slick";

interface Props {
  videoSize?: {
    height?: string;
    width?: string;
    minWidth?: string;
    minHeight?: string;
  };
  data: SanityImageWithMetaOrVideoOrVideoWithPlaceholder;
  videoPlaying?: boolean;
  playTime?: number;
  setPlayTime?: Dispatch<SetStateAction<number>>;
  setVideoPlaying?: Dispatch<SetStateAction<boolean>>;
  sliderRef?: RefObject<ReactSlick> | undefined;
  setPlayNext?: Dispatch<SetStateAction<boolean>>;
}

const MediaContentVideo = ({ videoSize, data, videoPlaying, playTime, setPlayTime, setVideoPlaying, sliderRef, setPlayNext }: Props) => {
  if (data == null) return null;

  if (isSanityVideo(data)) {
    return (
      <Video
        width={videoSize?.width}
        height={videoSize?.height}
        minWidth={videoSize?.minWidth}
        minHeight={videoSize?.minHeight}
        controls={true}
        url={data.url as string}
        volume={true}
        autoPlayOnLoad={true}
        isVisible={videoPlaying}
        background={false}
        playTime={playTime}
        setPlayTime={setPlayTime}
        setVideoPlaying={setVideoPlaying}
        sliderRef={sliderRef}
        setPlayNext={setPlayNext}
      />
    );
  }

  if (isSanityVideoWithPlaceholder(data)) {
    // todo - Add placeholder image

    if (videoPlaying === false && data.previewImage?.asset) {
      return (
        <Image
          isBackground
          imageData={data.previewImage?.asset?.gatsbyImageData}
          altText={data.previewImage?.asset?.altText ?? "mediaImage"}
          objectFit="cover"
          isStretched={true}
        />
      );
    }
    return (
      <Video
        controls={true}
        url={data.url as string}
        volume={true}
        autoPlayOnLoad={true}
        isVisible={videoPlaying}
        background={false}
        playTime={playTime}
        setPlayTime={setPlayTime}
        setVideoPlaying={setVideoPlaying}
        sliderRef={sliderRef}
        setPlayNext={setPlayNext}
      />
    );
  }

  return null;
};

export default MediaContentVideo;
