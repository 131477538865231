import { useStaticQuery, graphql } from "gatsby";

import { Query } from "@graphql-types";
import { DEFAULT_REGION } from "@util/constants";

const CategoriesQuery = (iban?: string) => {
  const {
    allSanityBlogCategories: { nodes },
  }: Query = useStaticQuery(graphql`
    {
      allSanityBlogCategories {
        nodes {
          region {
            ...sanityRegion
          }
          slug {
            current
          }
          title
        }
      }
    }
  `);

  const categoriesData =
    nodes.filter((node) => node.region?.iban === iban) ??
    nodes.filter((node) => node.region?.iban === DEFAULT_REGION);

  return categoriesData;
};

export default CategoriesQuery;
