import React, { useRef } from "react";
import styled from "styled-components";
import ReactSlick from "react-slick";

import { Maybe, SanityStat, SanityStatsBlock } from "@graphql-types";
import {
  DesktopContainer,
  FullHeightContainer,
  MobileContainer,
} from "@shared/shared.styles";
import { Container, LinearGradient, P, H3 } from "@util/standard";
import MediaContent from "@shared/mediaContent";
import { BlocksContent, Carousel, SwipeIndicator } from "@global";
import {
  colors,
  fontSizes,
  MOBILE_BREAKPOINT,
  LAPTOP_BREAKPOINT,
  TABLET_BREAKPOINT,
} from "@util/constants";
import { handleNextSlide } from "@util/helper";

const StatValue = styled(P)`
  font-size: 100px;
  font-weight: 900;
  margin: 30px 0;

  @media only screen and (max-width: ${LAPTOP_BREAKPOINT}px) {
    font-size: 70px;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 40px;
  }
`;

const MobileContentContainer = styled(Container)`
  flex-direction: column;
  width: 80%;
  margin: auto;

  h2 {
    z-index: 1;
  }
`;

const DesktopContainerStyled = styled(DesktopContainer)`
  align-items: stretch;
  > div:first-of-type{
    height: auto;
  }
`;

const StatContainer = styled(Container)`
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px){
    justify-content: flex-end;
    align-items: flex-start;
  }
`;

const TextContentContainer = styled(Container)`
  max-width: 314px;
  margin: 0 auto;
  padding: 0 20px;
  text-align: center;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px){
    text-align: left;
    color: ${colors.white};
    padding: 0;
    margin: 0;
  }
`;

interface StatProps {
  stat: Maybe<SanityStat>;
  height?: string;
  whiteText?: boolean;
}

interface Props {
  data: Maybe<SanityStatsBlock> | undefined;
}

const Stat = ({ stat, height, whiteText }: StatProps) => (
  <StatContainer
    height={height ?? "90vh"}
    className="rte"
  >
    <StatValue color="green">{stat?.statValue}</StatValue>
    <H3 className="h4" color={whiteText ? "white" : "grey"}>{stat?.statTitle}</H3>
    {stat?.description &&
      <TextContentContainer>
        <BlocksContent data={stat?.description} />
      </TextContentContainer>
    }
  </StatContainer>
);

const StatsBlock = ({ data }: Props) => {
  if (data == null) {
    return null;
  }

  const { blockTitle, media, stats, anchorId } = data;
  const sliderRef = useRef<ReactSlick>(null);

  return (
    <div id={anchorId ?? undefined}>
      <DesktopContainerStyled>
        <FullHeightContainer>
          {media?.map((selectedMedia) => {
            if (selectedMedia == null) {
              return null;
            }

            return (
              <MediaContent key={selectedMedia._key} data={selectedMedia} />
            );
          })}
          <LinearGradient />
          <Container zIndex={2} width="80%" margin="auto auto 40px 40px">
            <BlocksContent data={blockTitle} />
          </Container>
        </FullHeightContainer>

        <Container padding="0 0 20px" width="50%" backgroundColor="lightGrey" position="relative">
          <Carousel hideArrow sliderRef={sliderRef}>
            {stats?.map((stat) => (
              <Stat key={stat?._key} stat={stat} />
            ))}
          </Carousel>
          <SwipeIndicator
            centreAlign
            onClick={() => handleNextSlide(sliderRef)}
          />
        </Container>
      </DesktopContainerStyled>

      <MobileContainer>
        <FullHeightContainer>
          {media?.map((selectedMedia) => {
            if (selectedMedia == null) {
              return null;
            }

            return (
              <MediaContent key={selectedMedia._key} data={selectedMedia} />
            );
          })}
          <MobileContentContainer>
            <BlocksContent data={blockTitle} />
            <Carousel hideArrow whiteDots alignDotsMobile="left">
              {stats?.map((stat) => (
                <Stat whiteText key={stat?._key} stat={stat} height="60vh" />
              ))}
            </Carousel>
          </MobileContentContainer>
        </FullHeightContainer>
      </MobileContainer>
    </div>
  );
};

export default StatsBlock;
