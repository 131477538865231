import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ReCaptcha } from "react-recaptcha-v3";

import { SanityNewsletterBlock } from "@graphql-types";
import { Container, Wrapper } from "@util/standard";
import { BlocksContent, TextInput, Button } from "@global";
import { Color } from "@util/types";
import { BlockWrapper } from "@shared/shared.styles";
import { TABLET_BREAKPOINT } from "@util/constants";
import { useNewsletterLanguage } from "@query";
import { useStore } from "@state/store";

interface Props {
  data: SanityNewsletterBlock;
}

interface State {
  name: string;
  email: string;
  instagram: string;
}

const NewsletterWrapper = styled(Wrapper)`
  max-width: 685px;
  margin: auto;
  padding: 85px 35px;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: 60px 35px;
  }
`;

const Form = styled.form`
  margin-top: 15px;
  input{
    margin-top: 25px;
  }
`;

const NewsletterBlock = ({ data }: Props) => {
  const { region, user } = useStore();
  const newsletterLanguage = useNewsletterLanguage(region.iban);
  const initialState: State = {
    name: "",
    email: user?.email ?? "",
    instagram: user?.instagramHandle ?? "",
  };
  const [state, setState] = useState<State>(initialState);
  const [clickedSubmit, setClickedSubmit] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (submitted) {
      setState(initialState);
      setSubmitted(false);
      setClickedSubmit(false);
    }
  }, [submitted]);

  function formValidated() {
    const values = Object.values(state);
    if (values.includes("")) {
      return false;
    }
    return true;
  }

  function handleInputChange(event: any) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const handleClickedSubmit = () => {
    return;
    if (process.env.GATSBY_GETFORM_URL && formValidated()) {
    }
  };

  if (data == null) {
    return null;
  }

  const { blockTitle, background, anchorId } = data;

  return (
    <BlockWrapper id={anchorId ?? undefined} background={background?.backgroundColor as Color ?? 'white'}>
      <NewsletterWrapper>
        <BlocksContent data={blockTitle} />
        <Form id="get-started">
          <Container flexDirection="column">
            <TextInput
              required
              value={state.name}
              type="text"
              name="name"
              placeholder={newsletterLanguage?.nameLabel ?? "Name"}
              onChange={handleInputChange}
            />
            <TextInput
              required
              value={state.instagram}
              type="text"
              name="instagram"
              placeholder={newsletterLanguage?.handleLabel ?? "Instagram Handle"}
              onChange={handleInputChange}
            />
            <TextInput
              required
              value={state.email}
              type="email"
              name="email"
              placeholder={newsletterLanguage?.emailLabel ?? "Email"}
              onChange={handleInputChange}
            />
            <Container margin="40px 0 0 0">
              <Button
                type="submit"
                theme="green"
                text={newsletterLanguage?.buttonLabel ?? "Submit"}
                onClick={handleClickedSubmit}
                loading={clickedSubmit}
                disabled={!user.recaptchaVerified}
              />
            </Container>
          </Container>
        </Form>

      </NewsletterWrapper>
    </BlockWrapper >
  );
};

export default NewsletterBlock;