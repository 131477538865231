import { SanityGlobalLanguage } from "@graphql-types";
import { DEFAULT_HREFLANG, DEFAULT_REGION } from "@util/constants";
import create from "zustand";
import { persist, devtools } from "zustand/middleware";

import { Region, User } from "./types";

export interface State {
  region: Region;
  language?: SanityGlobalLanguage | undefined;
  user: User;
}

const defaultRegion = {
  iban: DEFAULT_REGION,
  hrefLang: DEFAULT_HREFLANG,
  slug: "",
};

const defaultUser = {
  email: "",
  instagramHandle: "",
  isAdmin: false,
  cookieAcceptance: undefined,
  recaptchaVerified: false,
};

export const defaultScreenWidth = {
  isTabletWidth: false,
  isMobileWidth: false,
  isSmallLaptopWidth: false,
  isLaptopWidth: false,
};

export const useStore = create(
  devtools(
    persist<State>(
      () => ({
        region: defaultRegion,
        user: defaultUser,
      }),
      {
        name: "ma-store", // unique name
        getStorage: () => localStorage, // (optional) by default the 'localStorage' is used
      }
    )
  )
);
