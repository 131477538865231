// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-about-template-tsx": () => import("./../../../src/templates/aboutTemplate.tsx" /* webpackChunkName: "component---src-templates-about-template-tsx" */),
  "component---src-templates-blog-inner-template-tsx": () => import("./../../../src/templates/blogInnerTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-inner-template-tsx" */),
  "component---src-templates-campaign-template-tsx": () => import("./../../../src/templates/campaignTemplate.tsx" /* webpackChunkName: "component---src-templates-campaign-template-tsx" */),
  "component---src-templates-home-template-tsx": () => import("./../../../src/templates/homeTemplate.tsx" /* webpackChunkName: "component---src-templates-home-template-tsx" */),
  "component---src-templates-policy-template-tsx": () => import("./../../../src/templates/policyTemplate.tsx" /* webpackChunkName: "component---src-templates-policy-template-tsx" */),
  "component---src-templates-product-template-tsx": () => import("./../../../src/templates/productTemplate.tsx" /* webpackChunkName: "component---src-templates-product-template-tsx" */),
  "component---src-templates-thank-you-template-tsx": () => import("./../../../src/templates/thankYouTemplate.tsx" /* webpackChunkName: "component---src-templates-thank-you-template-tsx" */),
  "component---src-templates-trainer-stories-template-tsx": () => import("./../../../src/templates/trainerStoriesTemplate.tsx" /* webpackChunkName: "component---src-templates-trainer-stories-template-tsx" */)
}

