import { useStaticQuery, graphql } from "gatsby";

import { Query } from "@graphql-types";
import { DEFAULT_REGION } from "@util/constants";

const FooterQuery = (iban?: string) => {
  const {
    allSanityFooter: { edges },
  }: Query = useStaticQuery(graphql`
    {
      allSanityFooter {
        edges {
          node {
            logo {
              asset {
                _id
                url
              }
            }
            footerLinks1 {
              ... on SanityPlainLink {
                ...sanityPlainLink
              }
              ... on SanityTextObject {
                _type
                _key
                text
              }
            }
            footerLinks2 {
              ... on SanityPlainLink {
                ...sanityPlainLink
              }
              ... on SanityTextObject {
                _type
                _key
                text
              }
            }
            companyAddresses {
              ... on SanityTextObject {
                _type
                _key
                text
              }
            }
            copyright
            policyLinks {
              ...sanityPlainLink
            }
            socialLinks {
              linkTo
              socialIcon {
                _type
                altText
                asset {
                  _id
                  url
                }
              }
            }
            backgroundImage {
              ...sanityImageFullWidth
            }
            podcastTitle {
              _rawContent
            }
            mobileWhite
            podcastLinks {
              ... on SanityLink {
                ...sanityLink
              }
              ... on SanityLinkWithIcon {
                ...sanityLinkWithIcon
              }
            }
            region {
              ...sanityRegion
            }
          }
        }
      }
    }
  `);

  const footerData =
    edges.find((edge) => edge.node.region?.iban === iban) ??
    edges.find((edge) => edge.node.region?.iban === DEFAULT_REGION);

  return footerData;
};

export default FooterQuery;
