import React, { ReactNode } from "react";

import { ParallaxProvider } from 'react-scroll-parallax';
import { ParallaxCache, Password } from "@global";
import { ThemeProvider } from '@mui/material/styles';
import { materialTheme } from "@styles/globalStyles";

interface Props {
  children: ReactNode;
  password?: boolean | null;
  isAdmin?: boolean;
}

const ProviderContainer = ({ children, password, isAdmin }: Props) => {
  return (
    <>
      <ParallaxProvider>
        <ThemeProvider theme={materialTheme}>
          <ParallaxCache />
          {password === true && isAdmin !== true ?
            <Password />
            :
            <>{children}</>
          }
        </ThemeProvider>
      </ParallaxProvider>
    </>
  );
};

export default ProviderContainer;

