import styled from "styled-components";

import {
  colors,
  colorsRGB,
  TABLET_BREAKPOINT,
  MOBILE_BREAKPOINT,
} from "@util/constants";
import { Container } from "@util/standard";
import { Color } from "@util/types";

export const MobileContainer = styled(Container)`
  display: none;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    display: flex;
  }
`;

export const DesktopContainer = styled(Container)`
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    display: none;
  }
`;

export const MenuTopContainer = styled(Container)`
  justify-content: flex-end;
  padding: 25px;
  position: absolute;
  width: 100%;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    justify-content: space-between;
  }
`;

export const FullHeightContainer = styled(Container)<{
  height?: string;
  minHeight?: string;
}>`
  flex-direction: column;
  position: relative;
  width: 100%;
  min-height: ${({ minHeight }) => (minHeight ? minHeight : "100vh")};
  overflow: hidden;

  ${({ height }) =>
    height &&
    `
    height: ${height};
  `}

  h1,
  h2,
  h3,
  h4 {
    color: ${colors.white};
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    height: 80vh;
  }
`;

export const AnimatedLine = styled(Container)<{
  strokeColor?: Color;
  backgroundColor?: any;
}>`
  background-color: ${(props) => props.backgroundColor ?? colorsRGB.white()};
  height: 2px;
  width: 100%;
  margin: 0;

  @-webkit-keyframes running-progress {
    0% {
      margin-left: 0px;
      margin-right: 100%;
    }
    50% {
      margin-left: 25%;
      margin-right: 0%;
    }
    100% {
      margin-left: 100%;
      margin-right: 0;
    }
  }
  @keyframes running-progress {
    0% {
      margin-left: 0px;
      margin-right: 100%;
    }
    50% {
      margin-left: 25%;
      margin-right: 0%;
    }
    100% {
      margin-left: 100%;
      margin-right: 0;
    }
  }

  &:before {
    background-color: ${(props) => props.strokeColor ?? colors.white};
    height: 100%;
    width: 100%;
    margin: 0;
    content: "";
    -webkit-animation: running-progress 2s cubic-bezier(0.4, 0, 0.2, 1) reverse
      infinite;
    animation: running-progress 2s cubic-bezier(0.4, 0, 0.2, 1) reverse infinite;
  }
`;

export const AbsoluteContentContainer = styled(Container)`
  position: absolute;
  flex-direction: column;
  bottom: 0;
  left: 0;
  right: 0;
  width: 80%;
  margin: auto auto 10% 10%;
  max-width: 400px;
  z-index: 2;

  p {
    color: ${colors.white};
  }
`;

export const ParallaxContainer = styled(Container)`
  height: 100%;
  overflow: hidden;
  background: ${colors.grey};
  .parallax-outer,
  .parallax-inner {
    height: 100%;
    width: 100%;
  }
`;

export const ButtonContainer = styled.div`
  max-width: 400px;
  margin: 30px 0 -20px;

  [class^="button__ButtonContainer"],
  [class*=" button__ButtonContainer"] {
    margin-bottom: 20px;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin: 20px 0 0;
  }
`;

export const TitleButtonContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 35px;

  h1,
  h2,
  h3,
  h4 {
    margin-top: 0;
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-flow: column nowrap;
    align-items: flex-start;
  }
`;

export const BlockWrapper = styled.div<{
  background: Color;
}>`
  overflow: hidden;
  ${({ background }) => `background: ${colors[background]};`}
`;

export const InnerPreviewContainer = styled.div<{
  width?: string; //Default width value is for homepage featured blog block
  tabletWidth?: string;
}>`
  width: ${({ width }) => (width ? width : "33.333%")};
  position: relative;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: ${({ tabletWidth }) => (tabletWidth ? tabletWidth : "100%")};
  }
`;

export const IndexContainer = styled(Container)`
  position: absolute;
  left: 15px;
  top: 15px;
  width: 25px;
  height: 25px;
  background: ${colorsRGB.white(0.4)};
  z-index: 2;
  border-radius: 100px;
  color: ${colors.white};
  align-items: center;
  justify-content: center;
`;

export const ImagesContentBlockMainContainer = styled(Container)<{
  reverse?: boolean;
  background: Color;
}>`
  flex-direction: ${({ reverse }) => (reverse ? "row-reverse" : "row")};
  padding: 100px 0;
  ${({ background }) => `background: ${colors[background]};`}

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: 20px 0 37px;
    flex-direction: ${({ reverse }) => (reverse ? "column-reverse" : "column")};
  }
`;

export const ImagesContentBlockContentContainer = styled(Container)`
  flex-direction: column;
  width: 60%;
  margin: auto auto auto 100px;
  position: relative;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 80%;
    margin: auto;
  }
`;

export const ImagesContentBlockImageContainer = styled(Container)`
  width: 100%;
  height: 100%;
  align-items: center;

  .gatsby-image-wrapper {
    width: 40%;
    margin: auto auto 60px auto;

    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      width: 60%;
      margin: auto;
    }
  }
`;
