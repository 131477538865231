import React, { Dispatch, ReactNode, SetStateAction } from "react";
import ReactModal from "react-modal";

import { colorsRGB } from "@util/constants";
import { Container } from "@util/standard";
import { IconButton } from "@global";

interface Props {
  children: ReactNode;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  width?: string;
  height?: string;
  isPictureInPicture?: boolean;
  isPolicy?: boolean;
  isGdpr?: boolean;
  isVideo?: boolean;
}

function Modal(props: Props) {
  const { children, setVisible, visible, width, height, isPictureInPicture, isPolicy, isGdpr, isVideo } = props;

  const modalBaseStyle = {
    content: {
      top: "50%",
      left: "50%",
      width: width ?? "70%",
      height: height ?? "90%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      border: "none",
      borderRadius: "0px",
    },
    overlay: {
      backgroundColor: `${colorsRGB.black(0.6)}`,
      zIndex: 100,
      cursor: "pointer"
    },
  };

  const pictureInPictureStyle = {
    content: {
      top: "10px",
      right: "10px",
      left: "auto",
      bottom: "auto",
      width: width ?? "297px",
      height: height ?? "167px",
    },
    overlay: {
      zIndex: 100,
      pointerEvents: "none",
      background: "none",
    },
  };

  const policyStyle = {
    content: {
      top: "auto",
      left: "0",
      right: "0",
      bottom: "0",
      width: "100%",
      height: "90%",
      borderRadius: "20px 20px 0 0",
      padding: "35px 40px",
    },
    overlay: {
      backgroundColor: `${colorsRGB.black(0.6)}`,
      zIndex: 100,
    },
  }

  const gdprStyle = {
    content: {
      top: "auto",
      left: "0",
      right: "0",
      bottom: "0",
      width: "100%",
      height: "auto",
      padding: "0",
    },
    overlay: {
      zIndex: 100,
      pointerEvents: "none",
      background: "none",
    },
  }

  const handleClose = () => {
    setVisible(false);
  }

  return (
    <ReactModal
      style={isGdpr ? gdprStyle : isPictureInPicture ? pictureInPictureStyle : isPolicy ? policyStyle : modalBaseStyle}
      isOpen={visible}
      ariaHideApp={false}
      onRequestClose={handleClose}
      overlayClassName={(isVideo && !isPictureInPicture) ? "videoModal-overlay" : "modal-overlay"}
    >
      <Container
        height="100%"
        width="100%"
        flexDirection="column"
        alignItems="flex-end"
        style={{ pointerEvents: "all" }}
      >
        {!isGdpr && (isPictureInPicture || !isVideo) &&
          <IconButton
            width="15px"
            height="15px"
            margin="0 0 10px 0"
            icon="closeDark"
            onClick={handleClose}
          />
        }
        {children}

      </Container>
    </ReactModal >
  );
}

export default Modal;
