import React, { useState } from "react";

import { Maybe, SanityGlobalLanguage, SanityRegion } from "@graphql-types";
import { Container, Span } from "@util/standard";
import { Image } from "@global";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Grow from '@mui/material/Grow';
import { Region } from "@state/types";
import { ArrowDownIcon } from "@util/svg";
import { setRegion } from "@util/helper";

interface Props {
  isWhite?: boolean;
  languages: Maybe<SanityRegion>[];
  currentRegion: Region;
  globalLanguage: SanityGlobalLanguage[];
}

const RegionSelector = ({ isWhite = false, languages, currentRegion, globalLanguage }: Props) => {
  const [anchorEl, setAnchorEl] = useState<Maybe<EventTarget & HTMLDivElement>>(null);
  const menuOpen = Boolean(anchorEl);

  const handleClose = (language: SanityRegion) => {
    setAnchorEl(null);
    if (language == null) return;

    setRegion(language.iban, language.hrefLang, globalLanguage);
  }

  if (languages == null) return null;
  if (languages.length < 2) return null;

  const currentLanguage = languages.find(lan => lan?.iban === currentRegion?.iban);

  return (
    <>
      <Container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => { setAnchorEl(e.currentTarget) }}>
        <Container cursor="pointer" alignItems="center">
          <Image
            imageData={currentLanguage?.flag?.asset?.gatsbyImageData}
            altText={currentLanguage?.flag?.altText ?? currentLanguage?.displayTitle}
            margin="0 10px 0 0"
          />
          <Span color={isWhite ? "white" : "black"}>{currentLanguage?.displayTitle}</Span>
          <ArrowDownIcon color={isWhite ? "white" : "black"} margin="0 0 0 12px" />
        </Container>
      </Container>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={menuOpen}
        onClose={handleClose}
        TransitionComponent={Grow}
      >
        {
          languages.map(language => {
            if (language == null) return null;
            return (
              <MenuItem key={language.iban} selected={language.iban === currentLanguage?.iban} onClick={() => handleClose(language)}>
                <Container alignItems="center">
                  <Image
                    imageData={language.flag?.asset?.gatsbyImageData}
                    altText={language.flag?.altText ?? language.displayTitle}
                    margin="0 10px 0 0"
                  />
                  <Span>{language?.displayTitle}</Span>
                </Container>
              </MenuItem>
            );
          })
        }
      </Menu>
    </>
  );
};

export default RegionSelector;