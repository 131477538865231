import React, { useState, useEffect, ReactNode } from "react";
import styled from "styled-components";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import { Container } from "@util/standard";
import { usePrevious } from "@util/helper";
import { colors, colorsRGB, MOBILE_BREAKPOINT } from "@util/constants";

const ProgressContainer = styled(Container)`
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
`;

const ProgressWrapper = styled.div`
  width: 480px;
  height: auto;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 350px;
    height: auto;
  }
`;

export type CalculatedValue = {
  livesChanged: number;
  programsSold: number;
  hoursSaved: number;
};

const maxValues: CalculatedValue = {
  livesChanged: 154920,
  programsSold: 27267901,
  hoursSaved: 2323800,
};

export type CalculatedValueKey = keyof typeof maxValues;

function calculatePercentage(value: number, valueName: CalculatedValueKey) {
  const max = maxValues[valueName];
  return (100 * value) / max;
}

interface Props {
  values: CalculatedValue;
  currentValueName: CalculatedValueKey;
  children: ReactNode;
}

const Progress = ({ values, currentValueName, children }: Props) => {
  const [currentValue, setCurrentValue] = useState(0);
  const prevCurrentValueName = usePrevious(currentValueName);

  useEffect(() => {
    const value = values[currentValueName];
    setCurrentValue(value);
  }, []);

  useEffect(() => {
    if (prevCurrentValueName !== currentValueName || values) {
      const value = values[currentValueName];
      setCurrentValue(value);
    }
  }, [values, currentValueName]);

  return (
    <ProgressContainer>
      <ProgressWrapper>
        <CircularProgressbarWithChildren
          strokeWidth={6}
          value={calculatePercentage(currentValue, currentValueName)}
          styles={buildStyles({
            pathColor: `${colors.green}`,
            trailColor: `${colorsRGB.green()}`,
            backgroundColor: `${colors.green}`,
          })}
        >
          {children}
        </CircularProgressbarWithChildren>
      </ProgressWrapper>
    </ProgressContainer>
  );
};

export default Progress;
