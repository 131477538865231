import React from "react";
import styled from "styled-components";

import { SanityPartnersBlock } from "@graphql-types";
import { Container, Wrapper } from "@util/standard";
import { BlocksContent, Image } from "@global";
import { Color } from "@util/types";
import { BlockWrapper } from "@shared/shared.styles";
import { colors, TABLET_BREAKPOINT } from "@util/constants";

interface Props {
  data: SanityPartnersBlock;
}

const PartnersWrapper = styled(Wrapper)`
  padding: 105px 35px 130px;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: 80px 35px 80px;
  }
`;

const LogosContainer = styled(Container)`
  flex-wrap: wrap;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin-top: 35px;
    width: calc(100% + 30px);
  }
`;

const LogoContainer = styled(Container)`
  align-items: stretch;
  margin-top: 75px;
  a{
    transition: opacity 0.3s ease;
    &:hover{
      opacity: 0.5; 
    }
  }
  &:not(:last-child):after{
    content: '';
    display: block;
    width: 1px;
    background: ${colors.grey};
    margin: 0 50px;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin-top: 15px;
    margin-right: 30px;
    &:not(:last-child):after{
      display: none;
    }
    .gatsby-image-wrapper{
      max-width: 120px !important;
    }
  }
`;

const PartnersBlock = ({ data }: Props) => {

  if (data == null) {
    return null;
  }

  const { blockTitle, logos, background, anchorId } = data;

  return (
    <BlockWrapper id={anchorId ?? undefined} background={background?.backgroundColor as Color ?? 'white'}>
      <PartnersWrapper>
        <BlocksContent data={blockTitle} />

        <LogosContainer>
          {logos && logos.map((logo, index) => {
            if (logo?.image?.asset?.gatsbyImageData == null) return null;
            return (
              <LogoContainer key={index}>
                {logo.url != null ?
                  <a href={logo.url} target="_blank">
                    <Image
                      imageData={logo.image.asset.gatsbyImageData}
                    />
                  </a>
                  :
                  <Image
                    imageData={logo.image.asset.gatsbyImageData}
                  />
                }
              </LogoContainer>
            );
          })}
        </LogosContainer>

      </PartnersWrapper>
    </BlockWrapper >
  );
};

export default PartnersBlock;