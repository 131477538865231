import { useStaticQuery, graphql } from "gatsby";

import { Query } from "@graphql-types";
import { DEFAULT_REGION } from "@util/constants";

const PasswordQuery = (iban?: string) => {
  const {
    allSanityPasswordPage: { nodes },
    sanitySettings: { password },
  }: Query = useStaticQuery(graphql`
    {
      allSanityPasswordPage {
        nodes {
          region {
            ...sanityRegion
          }
          fullScreenContentBlock {
            ...sanityFullScreenContentBlock
          }
          passwordLabel
          passwordEmpty
          passwordError
          passwordTriesExceeded
          buttonLabel
        }
      }
      sanitySettings {
        password
      }
    }
  `);

  const passwordData =
    nodes.find((node) => node.region?.iban === iban) ??
    nodes.find((node) => node.region?.iban === DEFAULT_REGION);

  return { passwordData, password };
};

export default PasswordQuery;
