import React, { useRef, useState } from "react";
import styled from "styled-components";
import ReactSlick from "react-slick";

import { SanityMapBlock, SanityBlockContent, SanityInfluencer, Maybe, SanityImageWithMeta } from "@graphql-types";
import { Container, FlexGrid } from "@util/standard";
import { BlocksContent, Carousel, SwipeIndicator, Image } from "@global";
import {
  TABLET_BREAKPOINT,
  colorsRGB,
  MOBILE_BREAKPOINT,
} from "@util/constants";
import { useCheckScreenWidth } from "@util/hooks";
import { handleNextSlide } from "@util/helper";
import { useStore } from "@state/store";
import Tooltip from '@mui/material/Tooltip';

interface Props {
  data: SanityMapBlock;
}

interface SocialProps {
  title: Maybe<SanityBlockContent> | undefined;
  influencers: Maybe<Maybe<SanityInfluencer>[]> | undefined;
}

interface MapProps {
  title: Maybe<SanityBlockContent> | undefined;
  image: Maybe<SanityImageWithMeta> | undefined;
}

interface SliderBarProps {
  sliderIndex: number;
}

const ImageContainer = styled.div<{ sliderIndex?: number; }>`
  width: 65%;
  position: relative;
  padding: 10vh 0 20vh;
  background: linear-gradient(180deg, rgba(251, 247, 247, 1) 0%, rgba(233, 233, 237, 0) 60%);

  .gatsby-image-wrapper{
    transition: all 0.3s ease;
  }

  ${({ sliderIndex }) => sliderIndex && sliderIndex === 0 ? `
  .gatsby-image-wrapper{
    margin-left: 0;
  }
  ` : sliderIndex === 1 ? `
  .gatsby-image-wrapper{
    margin-left: -15%;
  }
  ` : sliderIndex === 2 && `
  .gatsby-image-wrapper{
    margin-left: -30%;
  }
  `}

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    padding: 10vh 0 0;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    ${({ sliderIndex }) => sliderIndex && sliderIndex === 0 ? `
      .gatsby-image-wrapper{
        margin-left: 0;
      }
      ` : sliderIndex === 1 ? `
      .gatsby-image-wrapper{
        margin-left: -33.333%;
      }
      ` : sliderIndex === 2 && `
      .gatsby-image-wrapper{
        margin-left: -66.666%;
      }
    `}
  }
`;

const SocialContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35%;
  position: relative;
  > div{
    width: 100%;
    max-width: 330px;
    padding: 10px 35px;
    position: relative;
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    padding: 0 0 10vh;
    > div{
      max-width: 600px;
    }
  }
`;

const SwipeIndicatorStyle = styled.div<{
  bottom?: string;
  isLeft?: boolean;
}>`
  > div{
    bottom: ${({ bottom }) => bottom ?? "20px"};
    ${({ isLeft }) => isLeft ? `
      right: auto;
      left: 20px;
    ` : `
      right: 35px;
      left: auto;
    `}
  }
`;

const InfluencerContainer = styled.div`
  width: 90px;
  margin-top: 30px;
  img{
    border-radius: 100px;
    overflow: hidden;
  }
`;

const SliderBarContainer = styled(Container) <{
  sliderIndex: number;
}>`
  flex-flow: column nowrap;
  align-items: flex-end;
  padding: 20px 35px 0;
  > div{
    width: 100%;
    height: 2px;
    background-color: ${colorsRGB.black(0.3)};
    position: relative;
    border-radius: 100px;
    overflow: hidden;
    margin-top: 10px;
    &:after{
      content: '';
      background-color: ${colorsRGB.black(0.8)};
      width: 40%;
      height: 2px;
      position: absolute;
      left: 0;
      transition: all 0.3s ease;
      ${({ sliderIndex }) => sliderIndex === 1 ? `
        margin-left: 30%;
      ` : sliderIndex === 2 && `
        margin-left: 60%;
      `}
    }

  }
`;

const Social = ({ title, influencers }: SocialProps) => {
  const { isTabletWidth, isMobileWidth } = useCheckScreenWidth();

  return (
    <Container flexDirection="column" alignItems="flex-start">
      {!isTabletWidth && !isMobileWidth &&
        <BlocksContent data={title} />
      }
      <FlexGrid>
        {influencers && influencers.map((influencer, index) => {
          if (influencer == null) return null;

          return (
            <InfluencerContainer key={index}>
              <Tooltip title={influencer.socialHandle ?? ""} arrow placement="bottom">
                <div>
                  <Image
                    imageData={influencer.image?.asset?.gatsbyImageData}
                    altText={influencer.image?.altText ?? influencer.socialHandle}
                    width="55px"
                    height="auto"
                  />
                </div>
              </Tooltip>
            </InfluencerContainer>
          );
        })}
      </FlexGrid>
    </Container>
  );
};

const Map = ({ image, title }: MapProps) => {
  const { isTabletWidth, isMobileWidth } = useCheckScreenWidth();

  return (
    <Container flexDirection="column" alignItems="flex-start">
      {(isTabletWidth || isMobileWidth) &&
        <Container padding="0 35px 20px">
          <BlocksContent data={title} />
        </Container>
      }
      <Container width={isMobileWidth ? "300%" : "130%"}>
        <Image
          imageData={image?.asset?.gatsbyImageData}
          altText={image?.altText ?? 'map'}
          width="100%"
          height="auto"
          fluidFixed
        />
      </Container>
    </Container>
  );
};

const SliderBar = ({ sliderIndex }: SliderBarProps) => {
  const { language } = useStore();

  return (
    <SliderBarContainer sliderIndex={sliderIndex}>
      <span>{language?.sliderSwipe ?? "Swipe"}</span>
      <div>
      </div>
    </SliderBarContainer>
  );
}

const MapBlock = ({ data }: Props) => {
  const { language } = useStore();
  const sliderRef = useRef<ReactSlick>(null);
  const imageRef = useRef<ReactSlick>(null);
  const [slideIndex, setSlideIndex] = useState(0);
  const { isTabletWidth, isMobileWidth } = useCheckScreenWidth();

  if (data == null) return null;

  return (
    <Container id={data.anchorId ?? undefined} flexWrap="wrap">
      <ImageContainer sliderIndex={slideIndex}>
        <Carousel autoplaySpeed={5000} setSelectedIndex={setSlideIndex} asNavFor={sliderRef} fade={true} hideArrow hideDots sliderRef={imageRef} slidesToShow={1}>
          <Map image={data.worldMapWest} title={data.blockTitleWest} />
          <Map image={data.worldMapMiddle} title={data.blockTitleMiddle} />
          <Map image={data.worldMapEast} title={data.blockTitleEast} />
        </Carousel>
        {!isTabletWidth && !isMobileWidth &&
          <>
            {/* <SwipeIndicatorStyle bottom="40px" isLeft>
                <SwipeIndicator text={language?.sliderBack ?? "back"} isBack onClick={() => handleNextSlide(imageRef, true)} isLeft />
              </SwipeIndicatorStyle> */}
            <SwipeIndicatorStyle bottom="40px">
              <SwipeIndicator text={language?.sliderSwipe ?? "Swipe"} onClick={() => handleNextSlide(imageRef)} />
            </SwipeIndicatorStyle>
          </>
        }
        {(isTabletWidth || isMobileWidth) &&
          <SliderBar sliderIndex={slideIndex} />
        }
      </ImageContainer>
      <SocialContainer>
        <div>
          <Carousel setSelectedIndex={setSlideIndex} asNavFor={imageRef} hideArrow sliderRef={sliderRef} slidesToShow={1} alignDots="left">
            <Social title={data.blockTitleWest} influencers={data.influencerWest} />
            <Social title={data.blockTitleMiddle} influencers={data.influencerMiddle} />
            <Social title={data.blockTitleEast} influencers={data.influencerEast} />
          </Carousel>
          <SwipeIndicatorStyle bottom="0">
            <SwipeIndicator text={language?.sliderNext ?? "Next"} onClick={() => handleNextSlide(sliderRef)} isNext />
          </SwipeIndicatorStyle>
        </div>
      </SocialContainer>
    </Container>
  );
};

export default MapBlock;