import { useStaticQuery, graphql } from "gatsby";

import { Query } from "@graphql-types";
import { DEFAULT_REGION } from "@util/constants";

const BlogInnerLanguageQuery = (iban?: string) => {
  const {
    allSanityBlogInnerLanguage: { nodes },
  }: Query = useStaticQuery(graphql`
    {
      allSanityBlogInnerLanguage {
        nodes {
          region {
            ...sanityRegion
          }
          fullScreenContentBlock {
            ...sanityFullScreenContentBlock
          }
          newsletterBlock {
            ...sanityNewsletterBlock
          }
          featuredBlogsBlock {
            ...sanityFeaturedBlogsBlock
          }
        }
      }
    }
  `);

  const Language =
    nodes.find((node) => node.region?.iban === iban) ??
    nodes.find((node) => node.region?.iban === DEFAULT_REGION);

  return Language;
};

export default BlogInnerLanguageQuery;
