import React from "react";
import styled from "styled-components";

import { Dimensions } from "@util/types";
import {
  colorsRGB,
  BUTTON_HEIGHT,
  MOBILE_BREAKPOINT,
  PRIMARY_COLOR,
} from "@util/constants";

const StyledInput = styled.input<{
  dimensions?: Dimensions;
  margin?: string;
  fontSize?: number;
  mobileMargin?: string;
  hidden?: boolean;
  minWidth?: string;
}>`
  display: flex;
  flex: 1;
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ mobileMargin }) =>
    mobileMargin &&
    ` @media only screen and (max-width: ${MOBILE_BREAKPOINT}px){
    margin: ${mobileMargin};
  }`}
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}px; `}
  ${({ minWidth }) => minWidth && `min-width: ${minWidth};`}
  ${({ dimensions }) => dimensions && `width: ${dimensions.width};`}
  ${({ hidden }) => hidden && `display: none;`}
  min-height: ${(props) => props.dimensions?.height ?? `${BUTTON_HEIGHT}px`};
  border: 0;
  outline: 0;
  border-bottom: 1px solid ${colorsRGB.black(0.2)};
  color: ${PRIMARY_COLOR};
  &:focus {
    outline: 0;
  }
  &::-webkit-input-placeholder {
  }
`;

const StyledTextArea = styled.textarea<{
  dimensions?: Dimensions;
  hidden?: boolean;
}>`
  display: flex;
  flex: 1;
  padding: 10px;
  ${({ dimensions }) => (dimensions ? `width: ${dimensions.width};` : "100%;")}
  ${({ hidden }) => hidden && `display: none;`}
  height: ${(props) => props.dimensions?.height ?? `${BUTTON_HEIGHT}px`};
  border-radius: 10px;
  border: 1px solid ${colorsRGB.flightBlue()};

  padding-left: 20px;
  padding-right: 20px;
  color: ${PRIMARY_COLOR};
  &:focus {
    outline: 0;
  }
  &::-webkit-input-placeholder {
  }
`;

interface Props {
  dimensions?: Dimensions;
  mobileMargin?: string;
  margin?: string;
  fontSize?: number;
  placeholder?: string;
  name?: string;
  onChange?: (arg?: any) => void;
  value?: string | number | undefined;
  isTextArea?: boolean;
  type?: string;
  required?: boolean;
  hidden?: boolean;
  minWidth?: string;
}

const TextInput = ({ isTextArea, ...rest }: Props) => {
  if (isTextArea) {
    const { name, placeholder, onChange, value, dimensions, hidden } = rest;
    return (
      <StyledTextArea
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        dimensions={dimensions}
        hidden={hidden}
      />
    );
  }

  return <StyledInput {...rest} />;
};

export default TextInput;
