import React, { useRef } from "react";
import styled from "styled-components";
import ReactSlick from "react-slick";

import { Maybe, SanityImagesContentBlock } from "@graphql-types";
import { Container } from "@util/standard";
import {
  Carousel,
  Image,
  SwipeIndicator,
  BlocksContent,
  LinksWithIcon,
} from "@global";
import { TABLET_BREAKPOINT } from "@util/constants";
import { DesktopContainer, ImagesContentBlockMainContainer, ImagesContentBlockContentContainer, ImagesContentBlockImageContainer } from "@shared/shared.styles";
import { handleNextSlide } from "@util/helper";
import { useStore } from "@state/store";
import { Color } from "@util/types";
import { useCheckScreenWidth } from "@util/hooks";

const CarouselContainer = styled(Container)`
  width: 50%;
  margin: auto;
  position: relative;
  padding-left: 15%;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 80%;
    padding-left: 0;
    margin-bottom: 35px;
    .slick-dots{
      margin-top: 20px;
    }
  }
`;

const SwipeDesktopContainer = styled(DesktopContainer)`
  > div{
    left: 65%;
  }
`;

const SwipeIndicatorStyle = styled.div`
  > div{
    bottom: 0;
    right: 0;
    align-items: flex-end;
  }
`;

interface Props {
  data: Maybe<SanityImagesContentBlock> | undefined;
}

const ImagesContentBlock = ({ data }: Props) => {
  const sliderRef = useRef<ReactSlick>(null);
  const contentRef = useRef<ReactSlick>(null);
  const { language } = useStore();
  const { isTabletWidth, isMobileWidth } = useCheckScreenWidth();

  if (data == null) {
    return null;
  }
  const { blockCtaContents, images, imagePositionLeft, background, anchorId } = data;

  return (
    <ImagesContentBlockMainContainer id={anchorId ?? undefined} reverse={!imagePositionLeft} background={background?.backgroundColor as Color ?? 'white'}>
      <CarouselContainer>
        <Carousel hideArrow sliderRef={sliderRef} alignDotsMobile="left">
          {images?.map((image) => {
            if (image == null) return null;

            return (
              <ImagesContentBlockImageContainer key={image._key}>
                <Image
                  objectFit="contain"
                  imageData={image.asset?.gatsbyImageData}
                  width={(!isTabletWidth && !isMobileWidth) ? "350px" : "auto"}
                  height={(!isTabletWidth && !isMobileWidth) ? "auto" : "400px"}
                />
              </ImagesContentBlockImageContainer>
            );
          })}
        </Carousel>
        {images && images.length > 1 &&
          <SwipeDesktopContainer>
            <SwipeIndicator
              centreAlign
              onClick={() => handleNextSlide(sliderRef)}
              text={language?.sliderSwipe ?? "swipe"}
            />
          </SwipeDesktopContainer>
        }
      </CarouselContainer>

      <Container width="50%" tabletWidth="100%" margin="auto">
        <ImagesContentBlockContentContainer>

          <Carousel hideArrow sliderRef={contentRef} alignDots="left" slidesToShow={1}>
            {blockCtaContents?.map((content) => {
              if (content == null) {
                return null;
              }
              return (
                <React.Fragment key={content._key}>
                  <BlocksContent data={content.blockContent} />
                  {content.links && (
                    <Container margin="30px 0 0 0">
                      {content.links.map((link) => {
                        if (link == null) return null;
                        return <LinksWithIcon key={link?._key} data={link} />;
                      })}
                    </Container>
                  )}
                </React.Fragment>
              );
            })}
          </Carousel>
          {blockCtaContents && blockCtaContents.length > 1 &&
            <SwipeIndicatorStyle>
              <SwipeIndicator
                onClick={() => handleNextSlide(contentRef)}
                text={language?.sliderSwipe ?? "swipe"}
              />
            </SwipeIndicatorStyle>
          }
        </ImagesContentBlockContentContainer>
      </Container>
    </ImagesContentBlockMainContainer >
  );
};

export default ImagesContentBlock;
