import React, { useRef, useState } from "react";
import styled from "styled-components";
import ReactSlick from "react-slick";

import { Maybe, SanityStepsBlock } from "@graphql-types";
import { Container, LinearGradient } from "@util/standard";
import { Carousel, SwipeIndicator, BlocksContent } from "@global";
import {
  assets,
  colors,
  colorsRGB,
  LAPTOP_BREAKPOINT,
  TABLET_BREAKPOINT,
} from "@util/constants";
import {
  FullHeightContainer,
  AbsoluteContentContainer,
  MobileContainer,
} from "@shared/shared.styles";
import MediaContent from "@shared/mediaContent";
import { handleNextSlide } from "@util/helper";

const MainContainer = styled(Container)`
  flex-direction: row;
  overflow: hidden;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: column;
    width: 100%;
  }
`;

const CarouselContainer = styled(Container)`
  width: 50%;
  position: relative;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    margin: 30px auto 80px;
    height: 100%;
  }
`;

const ContentContainer = styled(Container)`
  position: relative;
  flex-direction: column;
  width: 60%;
  margin: auto;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 80%;
  }
`;

const BlockContainer = styled(Container)`
  width: 70%;
  margin: 80px auto;
  align-items: flex-end;
  position: relative;

  @media only screen and (max-width: ${LAPTOP_BREAKPOINT}px) {
    width: 90%;
    margin: auto;
  }
`;

const MediaContainer = styled(FullHeightContainer)`
  width: 50%;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    display: none;
  }
`;

const BackgroundNumber = styled.img`
  position: absolute;
  right: 0;
  bottom: 5%;
  width: 50%;
  height: 100%;
`;

const Mobile = styled(MobileContainer)`
  width: 70%;
  margin: 30px auto 0 auto;
  flex-flow: column wrap;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin: 55px auto 0 auto;
    width: 90%;
  }
`;

const SliderBarContainer = styled(Container)`
  flex-flow: column nowrap;
  align-items: flex-end;
  margin: 20px -10% 0 35px;
  position: relative;
`;

const SliderDiv = styled.div <{
  sliderIndex: number;
}>`
  width: 100%;
  height: 2px;
  background-color: ${colorsRGB.black(0.2)};
  position: relative;
  border-radius: 100px;
  overflow: hidden;
  margin-top: 10px;
  &:after{
    content: '';
    background-color: ${colorsRGB.black(0.5)};
    width: 40%;
    height: 2px;
    position: absolute;
    left: 0;
    transition: all 0.3s ease;
    ${({ sliderIndex }) => sliderIndex === 1 ? `
      margin-left: 30%;
    ` : sliderIndex === 2 && `
      margin-left: 60%;
    `}
  }
`;

const NumberContainer = styled(Container)`
  position: absolute;
  background: ${colors.grey};
  left: -34px;
  top: 0;
  width: 22px;
  height: 22px;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  color: ${colors.white};
`;

interface Props {
  data: Maybe<SanityStepsBlock> | undefined;
}

interface SliderBarProps {
  sliderIndex: number;
}

const SliderBar = ({ sliderIndex }: SliderBarProps) => {
  return (
    <SliderBarContainer>
      <NumberContainer>
        {sliderIndex + 1}
      </NumberContainer>
      <SliderDiv sliderIndex={sliderIndex}>
      </SliderDiv>
    </SliderBarContainer>
  );
}

const StepsBlock = ({ data }: Props) => {
  if (data == null) {
    return null;
  }

  const { stepsContent, media, mediaBlockTitle, toggleOverlay, anchorId } = data;
  const sliderRef = useRef<ReactSlick>(null);
  const [slideIndex, setSlideIndex] = useState(0);

  return (
    <MainContainer id={anchorId ?? undefined}>
      <MediaContainer>
        {media?.map((selectedMedia) => {
          if (selectedMedia == null) {
            return null;
          }

          return <MediaContent key={selectedMedia._key} data={selectedMedia} />;
        })}
        <LinearGradient />
        <AbsoluteContentContainer>
          <BlocksContent data={mediaBlockTitle} />
        </AbsoluteContentContainer>
      </MediaContainer>

      <Mobile>
        <BlocksContent data={mediaBlockTitle} />
        {toggleOverlay &&
          <SliderBar sliderIndex={slideIndex} />
        }
      </Mobile>

      <CarouselContainer>
        {/* @ts-ignore */}
        {toggleOverlay &&
          <BackgroundNumber src={assets[slideIndex + 1]} />
        }
        <BlockContainer>
          <Carousel
            hideArrow
            sliderRef={sliderRef}
            alignDots="left"
            selectedIndex={slideIndex}
            setSelectedIndex={setSlideIndex}
          >
            {stepsContent?.map((step) => {
              if (step == null) {
                return null;
              }

              return (
                <ContentContainer key={step._key}>
                  <BlocksContent data={step} />
                </ContentContainer>
              );
            })}
          </Carousel>
          {stepsContent.length > 1 &&
            <SwipeIndicator onClick={() => handleNextSlide(sliderRef)} />
          }
        </BlockContainer>
      </CarouselContainer>
    </MainContainer>
  );
};

export default StepsBlock;
