import React, { FormEvent, useState } from "react";
import styled from "styled-components";

import { usePasswordQuery } from "@query";
import { ProviderContainer } from "@global";
import { useStore } from "@state/store";
import {
  FullScreenContentBlock,
} from "@components";
import { buttonStyle, colors } from "@util/constants";
import { Container } from "@util/standard";
import { ButtonTheme } from "@util/types";
import { toast } from "react-toastify";

const FormContainer = styled(Container)`
  align-items: stretch;
`;

const Input = styled.input`
  background: ${colors.white};
  box-shadow: none;
  appearance: none;
  outline: none;
  border: none;
  padding: 14px 10px;
  flex-grow: 2;
`;

const Button = styled.button<{
  theme: ButtonTheme;
}>`
  ${({ theme }) => `
    background: ${buttonStyle[theme].bg};
    color: ${buttonStyle[theme].text};
    border: 1px solid ${buttonStyle[theme].bg};
    transition: all 0.3s ease;
    padding: 14px 30px;

    &:hover{
      background: ${buttonStyle[theme].hoverBg};
      color: ${buttonStyle[theme].hoverText};
      border: 1px solid ${buttonStyle[theme].hoverBorder};
    }
  `}
`;

const Password = () => {
  const { region, user } = useStore();
  const { passwordData, password: passwordString } = usePasswordQuery(region.iban);
  const [passwordTries, setPasswordTries] = useState(0);

  if (passwordData == null) return null;

  const { fullScreenContentBlock, passwordLabel, passwordEmpty, passwordError, passwordTriesExceeded, buttonLabel } = passwordData;


  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const passwordValue = e.currentTarget.password.value;

    if (!passwordValue) {
      toast.error(passwordEmpty);
      return;
    }

    if (passwordTries > 3) {
      toast.error(passwordTriesExceeded);
      return;
    }

    if (passwordValue !== passwordString) {
      toast.error(passwordError);
      setPasswordTries(passwordTries + 1);
      return;
    }

    useStore.setState({
      user: {
        ...user,
        isAdmin: true
      }
    });
  };

  const Form = () => {
    return (
      <form onSubmit={handleSubmit} >
        <FormContainer>
          <Input
            name="password"
            type="password"
            placeholder={passwordLabel ?? "Password"}
          />
          <Button theme="green">
            {buttonLabel ?? "Submit"}
          </Button>
        </FormContainer>
      </form >
    );
  };

  return (
    <ProviderContainer>
      <FullScreenContentBlock data={fullScreenContentBlock} childContent={<Form />} />
    </ProviderContainer>
  );
};

export default Password;