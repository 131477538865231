import React from "react";
import styled from "styled-components";

import { Container, P } from "@util/standard";
import { BackIcon, NextIcon, SwipeIcon } from "@util/svg";
import { Color } from "@util/types";

const MainContainer = styled(Container) <{
  centered?: boolean;
  left?: boolean;
  isRelative?: boolean;
}>`
  flex-direction: column;
  user-select: none;
  cursor: pointer;

  transition: opacity 0.3s ease;

  ${({ isRelative, left, centered }) => isRelative ? `
  ` : `
    position: absolute;
    bottom: 0;
    right: 0;
    left: auto;
    ${left ? `
      right: auto;
      left: 0;
      align-items: flex-start;
    ` : `
      right: 0;
      left: auto;
      align-items: flex-end;
    `}
    ${centered && `
      left: 50%;
      right: auto;
      bottom: 7%;
      transform: translate(-50%, 0);
      align-items: center;
    `}
  `}

  svg{
    width: 37px;
  }

  && p{
    margin: 10px 0;
  }

  &:hover{
    opacity: 0.5;
  }
`;

interface Props {
  text?: string;
  centreAlign?: boolean;
  onClick?: () => void;
  isBack?: boolean;
  isNext?: boolean;
  isLeft?: boolean;
  isRelative?: boolean;
  color?: Color;
}

const SwipeIndicator = ({ text = "Swipe", centreAlign, onClick, isBack = false, isNext = false, isLeft = false, isRelative = false, color = "grey" }: Props) => {
  return (
    <MainContainer centered={centreAlign} left={isLeft} isRelative={isRelative} onClick={onClick}>
      <P color={color}>{text}</P>
      {isBack ?
        <BackIcon color={color} />
        : isNext ?
          <NextIcon color={color} />
          :
          <SwipeIcon color={color} />
      }
    </MainContainer>
  );
};

export default SwipeIndicator;
