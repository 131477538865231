import React, { useState } from "react";
import styled from "styled-components";

import { Container, Wrapper } from "@util/standard";
import { BlocksContent, Button, Modal } from "@global";
import { setCookie } from "@util/helper";
import { useGdprQuery } from "@query";
import { useStore } from "@state/store";
import { navigate } from "gatsby-link";
import { TABLET_BREAKPOINT } from "@util/constants";

const GdprWrapper = styled(Wrapper)`
  padding: 25px 35px;
`;

const ButtonContainer = styled(Container)`
  margin-top: 20px;
  justify-content: flex-end;
  flex-flow: wrap;
  margin: 30px 0 -10px;
  > div{
    margin-right: 15px;
  }
  [class^="button__ButtonContainer"],
  [class*=" button__ButtonContainer"] {
    margin-bottom: 10px;
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}){
    justify-content: center;
  }
`;

const PolicyBar = () => {
  const { region, user } = useStore();
  const [showModal, setShowModal] = useState(true);
  const tagManagerCookie = "gatsby-gdpr-google-tagmanager";
  const facebookPixelCookie = "gatsby-gdpr-facebook-pixel";
  const hotjarCookie = "gatsby-gdpr-hotjar";
  const gdprQuery = useGdprQuery(region.iban);

  if (gdprQuery == null) return null;

  const { description, acceptLabel, declineLabel, manageLabel, manageUrl } = gdprQuery;

  const handleAcceptClick = () => {
    setShowModal(false);
    setCookie(tagManagerCookie, true, 7);
    setCookie(facebookPixelCookie, true, 7);
    setCookie(hotjarCookie, true, 7);
    useStore.setState({
      user: {
        ...user,
        cookieAcceptance: true,
      }
    });
  };

  const handleDeclineClick = () => {
    setShowModal(false);
    useStore.setState({
      user: {
        ...user,
        cookieAcceptance: false,
      }
    });
  };

  if (user.cookieAcceptance != null) return null;

  return (
    <Modal
      isGdpr
      visible={showModal}
      setVisible={setShowModal}
    >
      <GdprWrapper>
        <BlocksContent data={description} />
        <ButtonContainer>
          <Button text={acceptLabel ?? "Accept"} onClick={handleAcceptClick} />
          <Button theme="transparentGreen" text={declineLabel ?? "Decline"} onClick={handleDeclineClick} />
          <Button theme="transparentGreen" text={manageLabel ?? "Manage Preferences"} onClick={() => navigate(manageUrl)} />
        </ButtonContainer>
      </GdprWrapper>
    </Modal>
  );
};

export default PolicyBar;
