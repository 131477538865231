import { useEffect, useState, useRef } from "react";
import { navigate } from "gatsby";
import { useLocation } from "@reach/router";

import { defaultScreenWidth, useStore } from "@state/store";
import {
  isBrowser,
  setSlug,
  usePrevious,
  setRegion,
  getPosition,
} from "./helper";
import { useAllPagePathsQuery, useHeaderQuery } from "@query";
import {
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
  LAPTOP_BREAKPOINT,
  DEFAULT_REGION,
  SMALL_LAPTOP_BREAKPOINT,
} from "./constants";
import { ScreenWidth } from "@state/types";
import { SanityGlobalLanguage } from "@graphql-types";
import { loadReCaptcha } from "react-recaptcha-v3";

// checks if region has changed then navigate to correct slug
export function useRegionChanged(
  slug: string | null | undefined,
  globalLanguage: SanityGlobalLanguage[]
) {
  const [regionChanged, setRegionChanged] = useState(false);
  const region = useStore.getState().region;
  const previousIban = usePrevious(region.iban);
  const allPagePaths = useAllPagePathsQuery();
  const hasMounted = useHasMounted();
  const { pathname } = useLocation();
  const {
    allSanityRegion: { nodes },
  } = useHeaderQuery();
  const pageSlug = setSlug(slug);

  useEffect(() => {
    if (hasMounted) {
      const newPathName = pathname.endsWith("/") ? pathname : `${pathname}/`;
      let regionInUrl = newPathName.substring(
        getPosition(newPathName, "/", 1) + 1,
        getPosition(newPathName, "/", 2)
      );
      //If regionInUrl doesn't have a iban code in it, eg 'about', then regionInUrl is set to DEFAULT_URL
      if (!nodes.find((node) => node.iban === regionInUrl))
        regionInUrl = DEFAULT_REGION;

      if (regionInUrl !== region.slug) {
        const regionFound = nodes.find((node) => node.iban === regionInUrl);
        if (regionFound) {
          const { hrefLang, iban } = regionFound;
          setRegion(iban, hrefLang, globalLanguage);
        }
      }
    }
  }, [hasMounted]);

  useEffect(() => {
    if (region.iban !== previousIban) {
      setRegionChanged(true);
      if (slug === "/") {
        navigate(`/${region.slug}`);
        return;
      }

      if (allPagePaths.includes(pageSlug)) {
        navigate(pageSlug);
      } else {
        navigate(`/${region.slug}`);
      }
    }
  }, [region]);

  return regionChanged;
}

// check if component has been mounted
export function useHasMounted() {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
    }
  }, []);

  if (!mounted) {
    return false;
  }

  return mounted;
}

// event listener hook
export function useEventListener(
  eventName: string,
  handler: (args: any) => void
) {
  if (!isBrowser()) {
    return;
  }
  const savedHandler = useRef<typeof handler>();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = window && window.addEventListener;
    if (!isSupported) return;

    //@ts-ignore
    const eventListener = (event: any) => savedHandler.current(event);
    window.addEventListener(eventName, eventListener);

    return () => {
      window.removeEventListener(eventName, eventListener);
    };
  }, [eventName, window]);
}

// checks screen width
export function useCheckScreenWidth(): ScreenWidth {
  if (!isBrowser()) {
    return defaultScreenWidth;
  }
  const [screenWidth, setScreenWidth] = useState(defaultScreenWidth);
  const hasMounted = useHasMounted();

  const checkScreenWidth = () => {
    if (window.innerWidth <= MOBILE_BREAKPOINT) {
      setScreenWidth({
        ...defaultScreenWidth,
        isMobileWidth: true,
      });
      return;
    }
    if (window.innerWidth <= TABLET_BREAKPOINT) {
      setScreenWidth({
        ...defaultScreenWidth,
        isTabletWidth: true,
      });
      return;
    }
    if (window.innerWidth <= SMALL_LAPTOP_BREAKPOINT) {
      setScreenWidth({
        ...defaultScreenWidth,
        isSmallLaptopWidth: true,
      });
      return;
    }
    if (window.innerWidth <= LAPTOP_BREAKPOINT) {
      setScreenWidth({
        ...defaultScreenWidth,
        isLaptopWidth: true,
      });
      return;
    }
    if (window.innerWidth > LAPTOP_BREAKPOINT) {
      setScreenWidth(defaultScreenWidth);
      return;
    }
  };

  useEventListener("resize", checkScreenWidth);

  useEffect(() => {
    checkScreenWidth();
  }, []);

  useEffect(() => {
    if (hasMounted) {
      checkScreenWidth();
    }
  }, [hasMounted]);

  return screenWidth;
}

// load reCaptcha
export const useRecaptcha = () => {
  const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);
  const hasMounted = useHasMounted();

  useEffect(() => {
    if (!hasMounted) return;

    if (process.env.GATSBY_RECAPTCHA_KEY) {
      loadReCaptcha(process.env.GATSBY_RECAPTCHA_KEY, () =>
        setRecaptchaLoaded(true)
      );
    }
  }, [hasMounted]);

  return recaptchaLoaded;
};
