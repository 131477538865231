import { useStaticQuery, graphql } from "gatsby";

import { Query } from "@graphql-types";

const LanguageQuery = () => {
  const {
    allSanityGlobalLanguage: { nodes },
  }: Query = useStaticQuery(graphql`
    {
      allSanityGlobalLanguage {
        nodes {
          region {
            ...sanityRegion
          }
          sliderSwipe
          sliderNext
          sliderBack
          heroExplore
          heroWatchVideo
          blogCategory
          blogCategoryAll
          blogSearch
          blogAuthor
          blogLoadMore
          blogNoResults
          tabbedView
          errorPageTitle
          errorPageDescription
        }
      }
    }
  `);

  return nodes;
};

export default LanguageQuery;
