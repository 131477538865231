import { useStaticQuery, graphql } from "gatsby";

import { Query } from "@graphql-types";
import { DEFAULT_REGION } from "@util/constants";

const HeaderQuery = (iban?: string) => {
  const {
    allSanityHeader: { edges },
    allSanityRegion,
  }: Query = useStaticQuery(graphql`
    {
      allSanityHeader {
        edges {
          node {
            _id
            logo {
              _type
              altText
              asset {
                url
              }
            }
            logoFull {
              _type
              altText
              asset {
                url
              }
            }
            logoIcon {
              _type
              altText
              asset {
                url
              }
            }
            menuBackground {
              ...sanityImageFullWidth
            }
            links {
              ...sanityPlainLink
            }
            language {
              ...sanityRegion
            }
            buttonLabel
            region {
              ...sanityRegion
            }
          }
        }
      }
      allSanityRegion {
        nodes {
          ...sanityRegion
        }
      }
    }
  `);

  const headerData = {
    header:
      edges.find((edge) => edge.node.region?.iban === iban) ??
      edges.find((edge) => edge.node.region?.iban === DEFAULT_REGION),
    allSanityRegion,
  };

  return headerData;
};

export default HeaderQuery;
