import React, { useState } from "react";
import styled from "styled-components";

import { Maybe, SanityAccordionBlock } from "@graphql-types";
import { Container } from "@util/standard";
import {
  BlocksContent,
} from "@global";
import { TABLET_BREAKPOINT, colors, fontWeights } from "@util/constants";
import { Color } from "@util/types";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { ArrowDownIcon } from "@util/svg";

const MainContainer = styled(Container) <{
  background: Color;
}>`
  flex-direction: row;
  justify-content: center;
  padding: 100px 0;
  ${({ background }) => `background: ${colors[background]};`}
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: 70px 0;
  }
`;

const Wrapper = styled(Container)`
  flex-flow: column nowrap;
  align-items: flex-start;
  max-width: 750px;
  padding: 0 35px;
  width: 100%;
`;

const AccordionContainer = styled(Container)`
  flex-direction: column;
  margin: 30px 0 0;
  .MuiAccordion-root{
    background: ${colors.transparent};
    box-shadow: none;
    color: ${colors.grey};
    &.Mui-expanded{
      margin: 0;
    }
    &:not(:first-of-type){
      &.Mui-expanded{
        &:before{
          position: absolute;
          left: 0;
          top: -1px;
          right: 0;
          height: 1px;
          content: "";
          display: inline-block !important;
          opacity: 1;
          background-color: ${colors.green};
        }
      }
    }
  }
  .MuiButtonBase-root{
    padding: 0;
  }
  .MuiAccordionSummary-content{
    margin: 25px 0;
    font-weight: ${fontWeights.extrabold};

    &.Mui-expanded{
      color: ${colors.green};
      margin: 25px 0;

      @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
        margin: 25px 0 15px;
      }
    }

  }
  .MuiAccordionDetails-root{
    padding: 0 0 30px;

    p:first-of-type{
      margin-top: 0;
    }

    @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
      padding: 0 0 15px;
    }
  }
  .MuiAccordionSummary-expandIconWrapper{
    color: ${colors.grey};
    svg{
      width: 13px;
    }
  }
  .Mui-expanded{
    .MuiAccordionSummary-expandIconWrapper{
      color: ${colors.green};
    }
  }
`;

interface Props {
  data: Maybe<SanityAccordionBlock> | undefined;
}

const AccordionBlock = ({ data }: Props) => {

  const [expanded, setExpanded] = useState<string | false>('panel1');

  if (data == null) {
    return null;
  }
  const { blockTitle, accordionItems, background, anchorId } = data;

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <MainContainer id={anchorId ?? undefined} background={background?.backgroundColor as Color ?? 'white'}>
      <Wrapper>
        <BlocksContent data={blockTitle} />
        <AccordionContainer>
          {accordionItems && accordionItems.map((accordion, index) => {
            if (accordion == null) return null;

            const { title, description, _key } = accordion;
            return (
              <Accordion key={_key} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
                <AccordionSummary expandIcon={<ArrowDownIcon color="currentColor" />}>
                  <span>
                    {title}
                  </span>
                </AccordionSummary>
                <AccordionDetails>
                  <BlocksContent data={description} />
                </AccordionDetails>
              </Accordion>
            );
          })}
        </AccordionContainer>
      </Wrapper>
    </MainContainer>
  );
};

export default AccordionBlock;
