import React from "react";
import styled from "styled-components";
import { navigate } from "gatsby-link";

import { SanityBlogInner } from "@graphql-types";
import { Image, BlogCategories } from "@global";
import { colorsRGB } from "@util/constants";
import { setSlug, toPlainText, truncateWords } from "@util/helper";
import { InnerPreviewContainer, IndexContainer } from "@shared/shared.styles";
import { useStore } from "@state/store";
import { Container } from "@util/standard";
interface Props {
  data: SanityBlogInner;
  width?: string;
  tabletWidth?: string;
  imageHeightSmall?: boolean;
  isTopViewed?: boolean;
  postIndex?: number;
}

const ImageContainer = styled.div<{
  imageHeightSmall: boolean;
}>`
  position: relative;
  cursor: pointer;
  padding-bottom: ${({ imageHeightSmall }) => imageHeightSmall ? "80%" : "165%"};
  height: 0;
  overflow: hidden;
  &:hover{
    img{
      transform: scale(1.05);
    }
    .gatsby-image-wrapper:after{
      background: ${colorsRGB.white(0.2)};
    }
  }
  .gatsby-image-wrapper{
    cursor: pointer;
    position: initial;
    img{
      z-index: 1;
      transition: transform 0.6s ease;
    }
    &:after{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
      background: ${colorsRGB.white(0)};
      transition: background 0.6s ease;
    }
  }
`;

const BlogPreview = ({ data, width, tabletWidth, imageHeightSmall, isTopViewed, postIndex }: Props) => {
  const { language } = useStore();
  if (data == null) return null;

  const link = setSlug(`/blog/${data.slug?.current}`);

  const handleClick = () => {
    navigate(link, { state: { prevPath: location.pathname } });
  }

  const excerpt = data.excerpt ?? (data.content && toPlainText(data.content)) ?? "";

  return (
    <InnerPreviewContainer width={width} tabletWidth={tabletWidth}>
      {isTopViewed === true && postIndex != null &&
        <IndexContainer>{postIndex + 1}</IndexContainer>
      }
      <ImageContainer onClick={handleClick} imageHeightSmall={imageHeightSmall ?? false}>
        <Image
          imageData={data.image?.asset?.gatsbyImageData}
          altText={data.image?.altText ?? data.title}
          width="100%"
          height="auto"
          objectFit="cover"
        />
        {data.categories &&
          <BlogCategories categories={data.categories} />
        }
      </ImageContainer>
      <Container flexDirection="column" padding={imageHeightSmall ? "0 20px" : "0"} maxWidth="400px">
        <a onClick={handleClick}>
          <h4>{data.title}</h4>
        </a>
        <div className="rte">
          <p>{truncateWords(excerpt, 30)}</p>
          {imageHeightSmall && data.author &&
            <p>{language?.blogAuthor ?? "By"} {data.author}</p>
          }
        </div>
      </Container>
    </InnerPreviewContainer>
  );
};

export default BlogPreview;
