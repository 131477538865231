import React, {
  ReactNode,
  useState,
  RefObject,
  Dispatch,
  SetStateAction,
  CSSProperties,
} from "react";
import ReactSlick, { Settings } from "react-slick";
import styled from "styled-components";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { colors, TABLET_BREAKPOINT } from "@util/constants";
import { Color } from "@util/types";

interface Props {
  children: ReactNode;
  hideArrow?: boolean;
  moreSettings?: any;
  sliderRef?: RefObject<ReactSlick>;
  variableWidth?: boolean;
  adaptiveHeight?: boolean;
  infinite?: boolean;
  slidesToShow?: number;
  hideDots?: boolean;
  selectedIndex?: number;
  setSelectedIndex?: Dispatch<SetStateAction<number>>;
  whiteDots?: boolean;
  margin?: string;
  slideMargin?: string;
  width?: string;
  alignDots?: "left" | "center" | "right";
  dotMargins?: string;
  alignDotsMobile?: CSSProperties["justifyContent"];
  overflow?: boolean;
  fade?: boolean;
  asNavFor?: React.RefObject<ReactSlick>;
  slidesToScroll?: number;
  background?: Color;
  autoplaySpeed?: number;
}

const Arrow = styled.img`
  object-fit: contain;
`;

const CarouselWrapper = styled.div<{
  whiteDots?: boolean;
  width?: string;
  margin?: string;
  alignDots?: string;
  dotMargins?: string;
  alignDotsMobile?: string;
  slideMargin?: string;
  overflow?: 'true' | 'false';
  background?: Color;
}>`
  width: ${(props) => props.width ?? "100%"};
  ${({ margin }) => margin && `margin: ${margin};`};

  ${({ slideMargin, width }) => slideMargin && `
    width: ${width ?? `calc(100% + ${slideMargin})`};
    .slick-slide{
      > div{
        padding-right: ${slideMargin};
      }
    }
  `}

  .slick-dots {
    position: initial;
    margin: ${(props) =>
    props.dotMargins ? props.dotMargins : "50px auto 0px auto"};
    color: ${colors.white};
    ${({ alignDots }) => alignDots && `text-align: ${alignDots};`};
    li {
      width: 8px;
      button::before {
        ${({ alignDots }) => alignDots && `text-align: ${alignDots};`};
      }
    }

    @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
      ${({ alignDotsMobile }) =>
    alignDotsMobile && `text-align: ${alignDotsMobile};`};
    }
  }

  .slick-dots li {
    margin: 0 10px 0 0 !important;
  }

  .slick-dots li button:before {
    font-size: 10px;

    ${({ whiteDots }) =>
    whiteDots &&
    `color: white;
  `}
  }
  
  ${({ overflow, width, background }) => overflow === 'true' && `
    width: 100%;
    position: relative;
    
    .slick-slider{
      width: ${width ?? '100%'};
      position: initial;
      &:before{
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: -100%;
        background: ${colors[background as Color]};
        z-index: 1;
      }
    }
    .slick-list{
      margin-bottom: 74px;
      overflow: visible;
    }
  `}
`;

const Carousel = ({
  children,
  hideArrow,
  sliderRef,
  variableWidth,
  adaptiveHeight,
  infinite,
  slidesToShow,
  hideDots,
  selectedIndex,
  setSelectedIndex,
  whiteDots,
  width,
  margin,
  slideMargin,
  alignDots,
  dotMargins,
  alignDotsMobile,
  overflow,
  fade = false,
  asNavFor,
  slidesToScroll,
  background,
  autoplaySpeed,
}: Props) => {
  const [slideIndex, setSlideIndex] = useState(0);

  const settings: Settings = {
    dots: hideDots ? false : true,
    infinite: infinite ?? true,
    speed: 500,
    autoplay: Boolean(autoplaySpeed),
    autoplaySpeed: autoplaySpeed ?? 5000,
    slidesToShow: slidesToShow ?? 1,
    slidesToScroll: slidesToScroll ?? 1,
    arrows: hideArrow ? false : true,
    pauseOnHover: true,
    fade: fade,
    lazyLoad: overflow ? "progressive" : "ondemand",
    nextArrow: hideArrow ? undefined : (
      <Arrow
        alt={`next-index-${selectedIndex ?? slideIndex + 1}`}
        width="20px"
        height="20px"
      />
    ),
    prevArrow: hideArrow ? undefined : (
      <Arrow
        alt={`next-index-${selectedIndex ?? slideIndex - 1}`}
        width="20px"
        height="20px"
      />
    ),
    beforeChange: (oldIndex, newIndex) => {
      if (setSelectedIndex) {
        setSelectedIndex(newIndex);
      } else {
        setSlideIndex(newIndex);
      }
    },
    variableWidth: variableWidth ? true : false,
    adaptiveHeight: adaptiveHeight ? true : false,
    asNavFor: asNavFor?.current ?? undefined
  };

  return (
    <CarouselWrapper
      whiteDots={whiteDots}
      width={width}
      margin={margin}
      alignDots={alignDots}
      dotMargins={dotMargins}
      alignDotsMobile={alignDotsMobile}
      slideMargin={slideMargin}
      overflow={overflow === true ? 'true' : 'false'}
      background={background ?? "white"}
    >
      <ReactSlick ref={sliderRef} {...settings}>
        {children}
      </ReactSlick>
    </CarouselWrapper>
  );
};

export default Carousel;
