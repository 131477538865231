import React from "react";

import {
  SanityAccordionBlockOrCalculatorBlockOrFeaturedBlogsBlockOrFullScreenContentBlockOrGetStartedBlockOrImageContentCarouselBlockOrImageSliderBlockOrImagesContentBlockOrInfoBlockOrMediaContentBlockOrNewsletterBlockOrPartnersBlockOrStatsBlockOrStepsBlockOrVideoSliderBlock,
  Maybe,
  SanityMapBlock
} from "@graphql-types";
import {
  isSanityAccordionBlock,
  isSanityCalculatorBlock,
  isSanityFeaturedBlogsBlock,
  isSanityFullScreenContentBlock,
  isSanityGetStartedBlock,
  isSanityImageContentCarouselBlock,
  isSanityImagesContentBlock,
  isSanityImageSliderBlock,
  isSanityInfoBlock,
  isSanityMapBlock,
  isSanityMediaContentBlock,
  isSanityNewsletterBlock,
  isSanityPartnersBlock,
  isSanityStatsBlock,
  isSanityStepsBlock,
  isSanityVideoSliderBlock
} from "@util/types";
import { P } from "@util/standard";
import {
  InfoBlock,
  FullScreenContentBlock,
  StatsBlock,
  FeaturedBlogsBlock,
  MapBlock,
  ImagesContentBlock,
  StepsBlock,
  ImageContentCarouselBlock,
  MediaContentBlock,
  AccordionBlock,
  VideoSliderBlock,
  ImageSliderBlock,
  PartnersBlock,
  NewsletterBlock,
  GetStartedBlock,
} from "@components";
import Calculator from "@shared/calculator";

interface Props {
  data: Maybe<SanityAccordionBlockOrCalculatorBlockOrFeaturedBlogsBlockOrFullScreenContentBlockOrGetStartedBlockOrImageContentCarouselBlockOrImageSliderBlockOrImagesContentBlockOrInfoBlockOrMediaContentBlockOrNewsletterBlockOrPartnersBlockOrStatsBlockOrStepsBlockOrVideoSliderBlock | SanityMapBlock>
}

const Components = ({ data }: Props) => {
  if (data == null) return null;

  if (isSanityInfoBlock(data)) {
    return (
      <InfoBlock content={data} />
    )
  };
  if (isSanityStatsBlock(data)) {
    return (
      <StatsBlock data={data} />
    )
  };
  if (isSanityFullScreenContentBlock(data)) {
    return (
      <FullScreenContentBlock data={data} />
    )
  };
  if (isSanityImageContentCarouselBlock(data)) {
    return (
      <ImageContentCarouselBlock data={data} />
    )
  };
  if (isSanityImagesContentBlock(data)) {
    return (
      <ImagesContentBlock data={data} />
    )
  };
  if (isSanityMediaContentBlock(data)) {
    return (
      <MediaContentBlock data={data} />
    )
  };
  if (isSanityStepsBlock(data)) {
    return (
      <StepsBlock data={data} />
    )
  };
  if (isSanityCalculatorBlock(data)) {
    return (
      <Calculator data={data} />
    )
  };
  if (isSanityFeaturedBlogsBlock(data)) {
    return (
      <FeaturedBlogsBlock data={data} />
    )
  };
  if (isSanityMapBlock(data)) {
    return (
      <MapBlock data={data} />
    )
  };
  if (isSanityAccordionBlock(data)) {
    return (
      <AccordionBlock data={data} />
    )
  };
  if (isSanityVideoSliderBlock(data)) {
    return (
      <VideoSliderBlock data={data} />
    )
  };
  if (isSanityImageSliderBlock(data)) {
    return (
      <ImageSliderBlock data={data} />
    )
  };
  if (isSanityPartnersBlock(data)) {
    return (
      <PartnersBlock data={data} />
    )
  };
  if (isSanityNewsletterBlock(data)) {
    return (
      <NewsletterBlock data={data} />
    )
  };
  if (isSanityGetStartedBlock(data)) {
    return (
      <GetStartedBlock data={data} />
    )
  };

  return <P color="errorRed">Error loading content...</P>;
};

export default Components;
