import { useStaticQuery, graphql } from "gatsby";

import { Query } from "@graphql-types";
import { DEFAULT_REGION } from "@util/constants";

const CalculatorLanguageQuery = (iban?: string) => {
  const {
    allSanityCalculatorLanguage: { nodes },
  }: Query = useStaticQuery(graphql`
    {
      allSanityCalculatorLanguage {
        nodes {
          region {
            ...sanityRegion
          }
          sliderUnit
          followersLabel
          instagramHandleLabel
          emailLabel
          buttonLabel
          errorEmptyValue
          errorBelowMinimum
          resultLivesChanged
          resultHoursSaved
          resultProgramsSold
          resultButton
          resultButtonBack
          scroll
        }
      }
    }
  `);

  const languageData =
    nodes.find((node) => node.region?.iban === iban) ??
    nodes.find((node) => node.region?.iban === DEFAULT_REGION);

  return languageData;
};

export default CalculatorLanguageQuery;
