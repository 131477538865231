import React from "react";
import VisibilitySensor from "react-visibility-sensor";
import styled from "styled-components";

import { SanityImageOrImageWithMetaOrVideo, SanityImageWithMetaOrVideoOrVideoWithPlaceholder } from "@graphql-types";
import { Container, P } from "@util/standard";
import { Video, Image } from "@global";
import {
  isSanityImageWithMeta,
  isSanityVideo,
  isSanityImage,
  isSanityVideoWithPlaceholder,
} from "@util/types";
import {
  colors,
  LAPTOP_BREAKPOINT,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
  IPAD_BREAKPOINT,
} from "@util/constants";

const ProductImageContainer = styled(Container)`
  position: relative;
  background-color: ${colors.grey};
  height: 100%;
  overflow: hidden;
  z-index: 2;

  .gatsby-image-wrapper {
    width: 30% !important;
    position: absolute;
    right: 4%;
    bottom: -250px;

    @media only screen and (max-width: ${LAPTOP_BREAKPOINT}px) {
      right: -5%;
      width: 37% !important;
      bottom: -270px;
    }
    @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
      bottom: -20%;
    }
    @media only screen and (max-width: ${IPAD_BREAKPOINT}px) {
      width: 50% !important;
    }
    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      width: 80% !important;
    }
  }
`;

interface Props {
  data: SanityImageOrImageWithMetaOrVideo | SanityImageWithMetaOrVideoOrVideoWithPlaceholder;
  modalVisible?: boolean;
  autoplay?: boolean;
  mediaBlock?: boolean;
  onLoad?: () => void;
}

const MediaContent = ({ data, modalVisible, autoplay, mediaBlock, onLoad }: Props) => {
  if (data == null) {
    return null;
  }

  if (isSanityImageWithMeta(data)) {
    return (
      <Image
        isBackground
        imageData={data.asset?.gatsbyImageData}
        altText={data.asset?.altText ?? "mediaImage"}
        objectFit="cover"
        isStretched={mediaBlock ?? false}
        onLoad={onLoad}
      />
    );
  }

  if (isSanityImage(data)) {
    return (
      <ProductImageContainer>
        <Image
          imageData={data.asset?.gatsbyImageData}
          altText="productImage"
          objectFit="contain"
          width="500px"
          height="auto"
        />
      </ProductImageContainer>
    );
  }

  if (isSanityVideo(data)) {
    return (
      <VisibilitySensor partialVisibility={true}>
        {({ isVisible }) => (
          <Video
            background
            url={data.url as string}
            volume={false}
            isVisible={isVisible ?? modalVisible}
            noAutoplay={autoplay === false}
          />
        )}
      </VisibilitySensor>
    );
  }

  if (isSanityVideoWithPlaceholder(data)) {
    // todo - Add placeholder image
    if (data.previewImage?.asset) {
      return (
        <Image
          isBackground
          imageData={data.previewImage?.asset?.gatsbyImageData}
          altText={data.previewImage?.asset?.altText ?? "mediaImage"}
          objectFit="cover"
          isStretched={mediaBlock ?? false}
          onLoad={onLoad}
        />
      );
    }
    return (
      <VisibilitySensor partialVisibility={true}>
        {({ isVisible }) => (
          <Video
            background
            url={data.url as string}
            volume={false}
            isVisible={isVisible ?? modalVisible}
            noAutoplay={autoplay === false}
          />
        )}
      </VisibilitySensor>
    );
  }

  return null;
};

export default MediaContent;
