import React from "react";
import styled from "styled-components";

import { SanityBlogCategories, Maybe } from "@graphql-types";
import { colors } from "@util/constants";
import { useStore } from "@state/store";

interface Props {
  categories: Maybe<SanityBlogCategories>[];
}

const CategoriesContainer = styled.div`
  position: absolute;
  top: 20px;
  z-index: 3;
  right: 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  margin-bottom: -12px;
`;

const Cat = styled.div`
  background: ${colors.darkGreen};
  border-radius: 100px 0px 0px 100px;
  padding: 10px 20px;
  color: ${colors.white};
  margin-bottom: 12px; 
`;

const BlogCategories = ({ categories }: Props) => {
  const { region } = useStore();
  if (categories.length < 1) return null;
  return (
    <CategoriesContainer>
      {categories.map(cat => {
        if (cat == null) return null;

        if (cat.region?.iban !== region.iban) return null;
        return (
          <Cat key={cat.slug?.current}>
            {cat.title}
          </Cat>
        );
      })}
    </CategoriesContainer>
  );
};

export default BlogCategories;