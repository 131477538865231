import React, { Dispatch, SetStateAction, RefObject } from "react";
import styled from "styled-components";

import { Container } from "@util/standard";
import { Modal, Video } from "@global";
import ReactSlick from "react-slick";
interface Props {
  videoUrl: string;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  playTime?: number;
  setPlayTime?: Dispatch<SetStateAction<number>>;
  isPictureInPicture?: boolean;
  setVideoPlaying?: Dispatch<SetStateAction<boolean>>;
  sliderRef?: RefObject<ReactSlick> | undefined;
  setPlayNext?: Dispatch<SetStateAction<boolean>>;
}

const VideoContainer = styled(Container)`
  width: 100%;
  transition: all 0.5s;
`;

function VideoPopUp(props: Props) {
  const { videoUrl, visible, setVisible, playTime, setPlayTime, isPictureInPicture, setVideoPlaying, sliderRef, setPlayNext } = props;

  return (
    <Modal isPictureInPicture={isPictureInPicture ?? false} visible={visible} setVisible={setVisible} width={isPictureInPicture ? "297px" : "90%"} isVideo >
      <VideoContainer>
        <Video
          controls={true}
          url={videoUrl}
          height="100%"
          width="100%"
          minHeight="0px"
          minWidth="0px"
          volume={true}
          autoPlayOnLoad
          isVisible={visible}
          background={false}
          playTime={playTime}
          setPlayTime={setPlayTime}
          setVideoPlaying={setVideoPlaying}
          sliderRef={sliderRef}
          setPlayNext={setPlayNext}
        />
      </VideoContainer>
    </Modal>
  );
}

export default VideoPopUp;
