import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { IGatsbyImageData } from "gatsby-plugin-image";
import styled from "styled-components";

import { A, P } from "@util/standard";
import { Image } from "@global";
import { colors } from "@util/constants";
import { Color } from "@util/types";

interface Props {
  linktext?: string | undefined | null;
  url?: string | undefined | null;
  internallink?: boolean | undefined | null;
  newwindow?: boolean | undefined | null;
  asset?: IGatsbyImageData | undefined | null;
  className?: string;
  color?: Color;
  isUnderlined?: boolean;
  isBold?: boolean;
  hoverColor?: Color;
  padding?: string;
  opacity?: number;
  download?: boolean;
  fontSize?: number;
  underlineThickness?: number;
}

const StyledGatsbyLink = styled(GatsbyLink) <{
  padding?: string;
  underlined?: number;
  opacity?: number;
}>`
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
  user-select: none;
  ${({ padding }) => padding && `padding: ${padding}`};
  text-decoration: ${(props) =>
    props.underlined === 1 ? `underline` : `none`};
`;

const ImageWrapper = styled.div`
  &:hover {
    opacity: 0.6;
  }
`;

const StyledP = styled(P) <{
  hoverColor?: Color;
  underlineThickness?: number;
  isUnderlined?: boolean;
}>`
  text-decoration-thickness: ${(props) => props.underlineThickness ?? "1.5"}px;
  text-underline-offset: 3px;
  ${({ isUnderlined }) => isUnderlined && `text-decoration: underline`};
  ${({ hoverColor }) =>
    hoverColor &&
    `&:hover {
      color: ${colors[hoverColor]};
    }`}

  margin: 0;
  &:hover {
    text-shadow: 0 0 0.5px
      ${(props) => (props.color ? colors[props.color] : colors.mine)};
    text-decoration: underline;
  }
`;

const Link = ({
  linktext,
  url,
  internallink,
  newwindow,
  asset,
  className = "",
  color,
  hoverColor,
  isUnderlined,
  isBold,
  padding,
  opacity,
  download,
  fontSize,
  underlineThickness,
}: Props) => {
  if (internallink) {
    const to = url === "/" ? "/" : url?.charAt(0) === '/' ? url : `/${url}`;
    return (
      <StyledGatsbyLink
        activeClassName="active"
        className={className}
        to={to}
        padding={padding}
        opacity={opacity}
      >
        {asset ? (
          <ImageWrapper>
            <Image imageData={asset} />
          </ImageWrapper>
        ) : (
          <StyledP
            hoverColor={hoverColor}
            underlineThickness={underlineThickness}
            isUnderlined={isUnderlined}
            color={color}
            fontWeight={isBold ? "extrabold" : "normal"}
            fontSize={fontSize}
          >
            {linktext}
          </StyledP>
        )}
      </StyledGatsbyLink>
    );
  }

  return (
    <A
      href={url ?? "/"}
      className={className}
      target={newwindow ? "_blank" : ""}
      rel={newwindow ? "noreferrer" : ""}
      opacity={opacity}
      underlined={isUnderlined}
      margin={padding}
      download={download}
    >
      {asset ? (
        <ImageWrapper>
          <Image imageData={asset} />
        </ImageWrapper>
      ) : (
        <StyledP
          fontSize={fontSize}
          hoverColor={hoverColor}
          color={color}
          fontWeight={isBold ? "extrabold" : "normal"}
        >
          {linktext}
        </StyledP>
      )}
    </A>
  );
};

export default Link;
