import { useLayoutEffect, useContext } from "react";
import { useController, ParallaxContext } from "react-scroll-parallax";

interface Props {
  variables?: any[];
}

const ParallaxCache = ({ variables }: Props) => {
  const context = useContext(ParallaxContext);

  if (context) {
    const { parallaxController } = useController();

    if (variables == null) {
      window.requestAnimationFrame(() => {
        parallaxController.update()
      })
    } else {
      useLayoutEffect(() => {
        const handler = () => parallaxController.update();
        window.addEventListener("load", handler);

        const resizeObserver = new ResizeObserver(handler);
        resizeObserver.observe(document.body);

        return () => {
          window.removeEventListener("load", handler);
          resizeObserver.unobserve(document.body);
        };
      }, variables);
    }
  }

  return null;
};

export default ParallaxCache;