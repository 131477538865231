import React from "react";
import styled from "styled-components";

import { Container, P } from "@util/standard";
import { AnimatedLine } from "@shared/shared.styles";
import { Color } from "@util/types";

const MainContainer = styled(Container)`
  flex-direction: column;
  transform: rotate(-90deg);
`;

interface Props {
  text: string;
  color?: Color;
  backgroundColor?: any;
}

const Indicator = ({ text, color, backgroundColor }: Props) => {
  return (
    <MainContainer>
      <P margin="0 0 19px 27px" color={color ?? "white"}>
        {text}
      </P>
      <AnimatedLine strokeColor={color} backgroundColor={backgroundColor} />
    </MainContainer>
  );
};

export default Indicator;
