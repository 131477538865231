import React from "react";
import styled from "styled-components";

import { colors } from "@util/constants";
import { Color } from "@util/types";

const Loader = styled.div<{
  baseColor?: Color;
  color?: Color;
  width?: string;
  height?: string;
}>`
  border: 2px solid
    ${(props) => (props.baseColor ? colors[props.baseColor] : colors.white)};
  border-radius: 50%;
  border-top: 3px solid
    ${(props) => (props.color ? colors[props.color] : colors.transparent)};
  width: ${(props) => (props.width ? props.width : "16px")};
  height: ${(props) => (props.height ? props.height : "16px")};
  -webkit-animation: spin 2s linear infinite;
  animation: spin 1s linear infinite;
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

interface Props {
  baseColor?: Color;
  color?: Color;
  width?: string;
  height?: string;
}

const Loading = (props: Props) => {
  return <Loader {...props} />;
};

export default Loading;
