export const BUTTON_HEIGHT = 46;
export const HEADER_HEIGHT = 84;

// screen sizes
export const SMALLER_MOBILE = 400;
export const MOBILE_BREAKPOINT = 600;
export const IPAD_BREAKPOINT = 768;
export const TABLET_BREAKPOINT = 1000;
export const IPAD_PRO_BREAKPOINT = 1025;
export const SMALL_LAPTOP_BREAKPOINT = 1280;
export const LAPTOP_BREAKPOINT = 1441;
export const TWENTY_INCH_SCREEN = 1600;
export const IMAC_BREAKPOINT = 1921;
export const DEFAULT_OFFSET = 120;
export const MOBILE_OFFSET = 60;

export const DEFAULT_REGION = "gb";
export const DEFAULT_HREFLANG = "en-gb";

export const colors = {
  transparent: "transparent",
  white: "#fff",
  black: "#000",
  flightBlue: "#001CA8",
  grey: "#545454",
  lightGrey: "#E9E9ED",
  mine: "#202020",
  green: "#3BE48B",
  darkGreen: "#44BB79",
  errorRed: "red",
  light: "#F1F1F2",
  currentColor: "currentColor",
};

export const fontWeights = {
  normal: 400,
  extrabold: 800,
  black: 900,
};

export const PRIMARY_COLOR = colors.mine;
export const SECONDARY_COLOR = colors.white;

export const fontSizes = {
  h1: { default: 110, mobile: 60, smallLaptop: 75 },
  h2: { default: 60, mobile: 35, smallLaptop: 40 },
  h3: { default: 35, mobile: 30, smallLaptop: 30 },
  h4: { default: 25, mobile: 25, smallLaptop: 25 },
  p: { default: 14, mobile: 14, smallLaptop: 14 },
};

export const colorsRGB = {
  flightBlue: (opacity?: number) => `rgba(0, 28, 168, ${opacity ?? "0.4"})`,
  black: (opacity?: number) => `rgba(0, 0, 0, ${opacity ?? "0.2"})`,
  white: (opacity?: number) => `rgba(255, 255, 255, ${opacity ?? "0.3"})`,
  green: (opacity?: number) => `rgba(59, 228, 139, ${opacity ?? "0.3"})`,
  grey: (opacity?: number) => `rgba(84, 84, 84, ${opacity ?? "0.3"})`,
  light: (opacity?: number) => `rgba(241, 241, 242, ${opacity ?? "0.3"})`,
};

export const pages = {
  home: "/",
  aboutUs: "/about",
  notFound: "/404",
  blog: "/blog",
};

// define paths to asset
export const assets = {
  logo: require("@static/assets/logo.png").default,
  hamburger: require("@static/assets/hamburger.svg").default,
  hamburgerWhite: require("@static/assets/hamburgerWhite.svg").default,
  close: require("@static/assets/close.svg").default,
  play: require("@static/assets/play.svg").default,
  closeDark: require("@static/assets/closeDark.svg").default,
  swipeLine: require("@static/assets/swipeLine.svg").default,
  nextLine: require("@static/assets/nextLine.svg").default,
  backLine: require("@static/assets/backLine.svg").default,
  playCircle: require("@static/assets/playCircle.svg").default,
  playSolid: require("@static/assets/playSolid.svg").default,
  1: require("@static/assets/1.svg").default,
  2: require("@static/assets/2.svg").default,
  3: require("@static/assets/3.svg").default,
};

export const buttonStyle = {
  green: {
    bg: `${colors.green}`,
    text: `${colors.white}`,
    border: `${colors.green}`,
    hoverBg: `${colors.grey}`,
    hoverBorder: `${colors.grey}`,
    hoverText: `${colors.white}`,
  },
  white: {
    bg: `${colors.white}`,
    text: `${colors.mine}`,
    border: `${colors.white}`,
    hoverBg: `${colors.green}`,
    hoverBorder: `${colors.green}`,
    hoverText: `${colors.white}`,
  },
  transparentWhite: {
    bg: `${colors.transparent}`,
    text: `${colors.white}`,
    border: `${colors.white}`,
    hoverBg: `${colors.green}`,
    hoverBorder: `${colors.green}`,
    hoverText: `${colors.white}`,
  },
  transparentGreen: {
    bg: `${colors.transparent}`,
    text: `${colors.green}`,
    border: `${colors.green}`,
    hoverBg: `${colors.grey}`,
    hoverBorder: `${colors.grey}`,
    hoverText: `${colors.white}`,
  },
};
