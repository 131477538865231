import React, { useRef } from "react";
import ReactSlick from "react-slick";

import { SanityFeaturedBlogsBlock } from "@graphql-types";
import { Container, Wrapper, FlexGrid } from "@util/standard";
import { Carousel, LinksWithIcon, SwipeIndicator, BlogPreview } from "@global";
import { Color } from "@util/types";
import { useCheckScreenWidth } from "@util/hooks";
import { TitleButtonContainer, BlockWrapper } from "@shared/shared.styles";
import { useBlogInner } from "@query";
import { useStore } from "@state/store";
import { handleNextSlide } from "@util/helper";

interface Props {
  data: SanityFeaturedBlogsBlock;
}

const FeaturedBlogs = ({ data }: Props) => {
  const { region, language } = useStore();
  const allBlogInner = useBlogInner(region.iban).slice(0, 3);
  const { isTabletWidth, isMobileWidth } = useCheckScreenWidth();
  const sliderRef = useRef<ReactSlick>(null);

  if (data == null) {
    return null;
  }

  const { title, description, cta, featuredBlog, background, anchorId } = data;

  const blogLists = featuredBlog && featuredBlog.length > 0 ? featuredBlog : allBlogInner;

  if (blogLists.length < 1) return null;

  return (
    <BlockWrapper id={anchorId ?? undefined} background={background?.backgroundColor as Color ?? 'white'}>
      <Wrapper>
        <TitleButtonContainer>
          <div>
            <h3>{title}</h3>
            <p>{description}</p>
          </div>
          <LinksWithIcon data={cta} />
        </TitleButtonContainer>
        {(!isTabletWidth && !isMobileWidth) ?
          <FlexGrid
            gridMargin="50px"
          >
            {blogLists?.map((blog) => {
              if (blog == null) return null;
              return (
                <BlogPreview data={blog} key={blog.id} />
              );
            })}
          </FlexGrid>
          :
          <Container position="relative">
            <Carousel hideArrow sliderRef={sliderRef} alignDots="left" slideMargin={isTabletWidth ? "20px" : "9px"} width={isTabletWidth ? "281px" : "255px"} overflow={true} background={background?.backgroundColor as Color}>
              {blogLists.map((blog) => {
                if (blog == null) return null;
                if (blog.hidden === true) return null;
                return (
                  <BlogPreview data={blog} key={blog.id} />
                );
              })}
            </Carousel>
            <SwipeIndicator text={language?.sliderSwipe ?? "swipe"} onClick={() => handleNextSlide(sliderRef)} />
          </Container>
        }
      </Wrapper>
    </BlockWrapper>
  );
};

export default FeaturedBlogs;
