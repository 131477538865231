import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Slider from "@mui/material/Slider";
import { toast } from "react-toastify";
import "rc-slider/assets/index.css";
import { ReCaptcha } from "react-recaptcha-v3";
import axios from "axios";

import { SanityCalculatorBlock } from "@graphql-types";
import {
  TABLET_BREAKPOINT,
  colors,
  fontWeights,
  colorsRGB,
} from "@util/constants";
import { Container, GridContainer } from "@util/standard";
import { BlocksContent, TextInput, Button, Indicator, ParallaxCache } from "@global";
import { bigNumberFormatter, emailValidator } from "@util/helper";
import CalculatorResult from "./calculatorResult";
import { useCalculatorLanguage } from "@query";
import { useStore } from "@state/store";
import { useCheckScreenWidth } from "@util/hooks";

const StyledSlider = styled(Slider)`
  &&{
    color: ${colors.green};
  }
  .MuiSlider-valueLabelOpen{
    background-color: ${colors.green};
    border-radius: 100px;
    padding: 10px 20px;
    font-weight: ${fontWeights.extrabold};
    text-align: center;
  }
  .css-eg0mwd-MuiSlider-thumb:hover, .css-eg0mwd-MuiSlider-thumb.Mui-focusVisible{
    box-shadow: 0px 0px 0px 8px ${colorsRGB.green(0.16)};
  }
`;

const Wrapper = styled(Container)`
  position: relative;
  overflow: hidden;
`;

const MainContainer = styled(Container)`
  flex-direction: column;
  width: 70%;
  margin: 120px auto;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin: 100px auto;
  }
`;

const SliderContainer = styled(Container)`
  align-items: flex-end;
  margin-top: 70px;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

const TextInputContainer = styled(Container)`
  width: 300px;
  margin: 0 0 0 60px;
  display: none;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    margin: 40px 0 0 60px;
  }
`;

const BottomContainer = styled(Container)`
  position: absolute;
  width: 100%;
  margin: auto;
  justify-content: center;
  bottom: 12px;
  left: 0;
  right: 0;
  z-index: 2;
  p{
    width: 35px;
  }
`;

interface Props {
  data: SanityCalculatorBlock;
}

interface State {
  email: string;
  instagram: string;
  followers: string;
}

const Calculator = ({ data }: Props) => {
  const [value, setValue] = useState<number>(0);
  const [resultVisible, setResultVisible] = useState(false);
  const { region, user } = useStore();
  const calculatorLanguage = useCalculatorLanguage(region.iban);
  const initialState: State = {
    email: user?.email ?? "",
    instagram: user?.instagramHandle ?? "",
    followers: "",
  };
  const [state, setState] = useState<State>(initialState);
  const { isMobileWidth } = useCheckScreenWidth();
  const [clickedSubmit, setClickedSubmit] = useState(false);

  const handleInputChange = (event: any) => {
    const target = event.target;
    const newVal = target.value;
    setValue(newVal);
  };

  const handleFindOutMoreClick = (e) => {
    e.preventDefault();
    if (value == 0 || value == null) {
      toast.warn(
        `${calculatorLanguage?.errorEmptyValue ?? "Please enter how many followers you have with the slider or text field!"}`
      );
      return;
    }

    if (value < 1) {
      toast.warn(`${calculatorLanguage?.errorBelowMinimum ?? "Please enter a value greater than 0"}`);
      return;
    }

    if (process.env.GATSBY_GETFORM_URL_CALCULATOR) {
      setClickedSubmit(true);
      axios
        .post(process.env.GATSBY_GETFORM_URL_CALCULATOR, state)
        .then(() => {
          setClickedSubmit(false);
          setResultVisible(true);
        })
        .catch(() => {
          setClickedSubmit(false);
          setResultVisible(true);
        });
    }

    useStore.setState({
      user: {
        ...user,
        email: state.email,
        instagramHandle: state.instagram
      }
    });
  };
  useEffect(() => {
    if (value != null) {
      setState((prevState) => ({
        ...prevState,
        followers: value.toString(),
      }));
    }
  }, [value]);

  function handleTextInputChange(event: any) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  if (resultVisible) {
    const handleBackClick = () => {
      setResultVisible(false);
      setValue(0);
    };

    return (
      <Wrapper>
        <ParallaxCache variables={[resultVisible]} />
        <MainContainer>
          <CalculatorResult
            result={value}
            resultVisible={resultVisible}
            onBackClick={handleBackClick}
          />
        </MainContainer>
        <BottomContainer>
          <Indicator
            text={calculatorLanguage?.scroll ?? "Scroll"}
            color="grey"
            backgroundColor={colorsRGB.grey()}
          />
        </BottomContainer>
      </Wrapper>
    );
  }
  const valueLabelFormat = (value: number) => {
    if (isMobileWidth && value > 5000000) {
      return <>> {bigNumberFormatter(5000000)}<br /> {calculatorLanguage?.sliderUnit ?? "Followers"}</>;
    }
    if (!isMobileWidth && value > 5000000) {
      return `> ${bigNumberFormatter(5000000)} ${calculatorLanguage?.sliderUnit ?? "Followers"}`;
    }
    if (isMobileWidth) {
      return <>{bigNumberFormatter(value)}<br /> {calculatorLanguage?.sliderUnit ?? "Followers"}</>;
    }
    return bigNumberFormatter(value, calculatorLanguage?.sliderUnit ?? "Followers");
  }

  function sliderScale(value: number) {
    return parseInt(((value * 0.25) ** 7).toFixed());
  }

  return (
    <Wrapper id={data.anchorId ?? undefined}>
      <ParallaxCache variables={[resultVisible]} />

      <MainContainer>
        <form onSubmit={handleFindOutMoreClick}>
          <BlocksContent data={data.blockTitle} />
          <SliderContainer>
            <StyledSlider
              max={36.3}
              onChange={(event: Event, newValue: number | number[]) => typeof newValue === 'number' && setValue(sliderScale(newValue))}
              scale={sliderScale}
              getAriaValueText={valueLabelFormat}
              valueLabelFormat={valueLabelFormat}
              step={0.1}
              min={3.6}
              valueLabelDisplay="on"
              aria-labelledby="non-linear-slider"
            />
            <TextInputContainer>
              <TextInput
                placeholder={calculatorLanguage?.followersLabel ?? "Type in followers"}
                required
                type="number"
                name="followers"
                minWidth="100%"
                value={value}
                onChange={handleInputChange}
              />
            </TextInputContainer>
          </SliderContainer>
          <GridContainer
            margin="30px 0 0 0"
            repeat={2}
            tabletRepeat={1}
            columnGap={30}
            rowGap={30}
          >
            <TextInput
              required
              value={state.email}
              type="email"
              name="email"
              placeholder={calculatorLanguage?.emailLabel ?? "Email"}
              onChange={handleTextInputChange}
            />
            <TextInput
              required
              value={state.instagram}
              type="text"
              name="instagram"
              placeholder={calculatorLanguage?.instagramHandleLabel ?? "Instagram handle"}
              onChange={handleTextInputChange}
            />
          </GridContainer>
          <Container margin="40px 0 0 0" justifyContent="flex-end">
            <Button
              type="submit"
              theme="green"
              text={calculatorLanguage?.buttonLabel ?? "Find out now"}
              disabled={!user.recaptchaVerified}
              loading={clickedSubmit}
            />
          </Container>
        </form>
      </MainContainer>
    </Wrapper>
  );
};

export default Calculator;
