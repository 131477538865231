import { useStaticQuery, graphql } from "gatsby";

import { Query } from "@graphql-types";
import { DEFAULT_REGION } from "@util/constants";

const pageSlugs = (iban: string = DEFAULT_REGION) => {
  const {
    allSanityAboutPage,
    allSanityBlogPage,
  }: Query = useStaticQuery(graphql`
    {
      allSanityAboutPage {
        nodes {
          slug {
            current
          }
          region {
            iban
          }
        }
      }
      allSanityBlogPage{
        nodes {
          slug {
            current
          }
          region {
            iban
          }
        }
      }
    }
  `);

  const filteredAbout =allSanityAboutPage.nodes.filter(node => node?.region?.iban === iban);
  const filteredBlog = allSanityBlogPage.nodes.filter(node => node?.region?.iban === iban);

  const data = {
    about: `/${filteredAbout && filteredAbout[0]?.slug?.current}`,
    blog: `/${filteredBlog && filteredBlog[0]?.slug?.current}`,
  };

  return data;
};

export default pageSlugs;
